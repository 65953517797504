import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import { Accordion } from "components/shared/accordion";
import {
  setLayoutProperty,
  setModuleProperty
} from "redux/actions/siteStructureActions";
import ModuleProperty from "components/siteStructure/pageView/pageSidebar/moduleProperties";
import { ISiteItem } from "models/siteItem";
import PageSidebarModuleExtras from "components/siteStructure/pageView/pageSidebar/pageSidebarModuleExtras";
import {
  PageElement,
  PageElementDefinition
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { EmptyPageSidebarContent } from "components/siteStructure/pageView/pageSidebar/emptyPageSidebarContent";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { getIsRichTextModuleDefinition } from "components/richText/utils";
import ModuleTranslations from "components/richText/components/moduleTranslations";

interface IPageDetailSidebarContentProps extends IBaseProps {
  node: ISiteItem;
  element: PageElement;
  definition: PageElementDefinition;
  canEditProperties: boolean;
}

const getAction = (
  element: PageElement,
  commonProperties: {
    pageId: string;
    name: string;
    value: string;
  }
) => {
  switch (element.moduleType) {
    case "Module":
      return setModuleProperty({
        moduleInstanceId: element.instanceId,
        ...commonProperties
      });

    case "Template":
    case "Layout":
      return setLayoutProperty({
        layoutInstanceId: element.instanceId,
        layoutKey: {
          id: element.id,
          namespace: element.namespace
        },
        isTemplate: element.moduleType === "Template",
        ...commonProperties
      });
  }
};

export const PageSidebarContent = (props: IPageDetailSidebarContentProps) => {
  const { node, element, definition, canEditProperties } = props;
  const dispatch = useDispatch();

  const isModule = element.moduleType === "Module";
  const isRichTextModuleDefinition =
    isModule && getIsRichTextModuleDefinition({ id: element.id } as any);

  const pageId = node.nodeId;

  const onChangeFunction = useCallback(
    (name: string, value: string) => {
      if (!definition) return;

      const commonProperties = {
        pageId: pageId,
        name,
        value
      };

      const action = getAction(element, commonProperties);
      return dispatch(action);
    },
    [dispatch, pageId, element, definition]
  );

  const categories = Object.keys(definition.properties);
  const isEmpty =
    categories.length === 0 &&
    !(definition as IModuleDefinition).hasTranslatableProperties;

  const useDefaultCategoryLabel =
    categories.length === 1 && categories[0] === "Misc";

  if (isEmpty) {
    return <EmptyPageSidebarContent element={element} />;
  }

  const shouldRenderModuleExtras =
    isModule && !(definition as IModuleDefinition).hasTranslatableProperties;

  const ItemAccordionComponentMap = [
    {
      condition: isRichTextModuleDefinition,
      component: <ModuleTranslations element={element} />
    },
    {
      condition: true,
      component: (
        <>
          {categories.map((category) => (
            <Accordion
              key={`${category}-${element.instanceId}`}
              label={
                useDefaultCategoryLabel
                  ? translate("sitestructure.properties")
                  : category
              }
            >
              {definition.properties[category].map((property) => (
                <ModuleProperty
                  key={`${element.instanceId}-${property.name}`}
                  propertyDefinition={{ ...property, canUseVariables: true }}
                  value={element.properties[property.name]}
                  disabled={!canEditProperties}
                  onChange={onChangeFunction}
                />
              ))}
            </Accordion>
          ))}
        </>
      )
    }
  ];

  const ItemAccordionComponent = ItemAccordionComponentMap.find(
    (item) => item.condition
  )?.component;

  return (
    <>
      {shouldRenderModuleExtras && (
        <PageSidebarModuleExtras
          element={element}
          pageId={pageId}
          canEditProperties={canEditProperties}
          currentSiteItemPath={node.path}
        />
      )}
      {ItemAccordionComponent}
    </>
  );
};

export default PageSidebarContent;
