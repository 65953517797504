import { useContext } from "react";
import classnames from "classnames";
import { Divider, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IPage, IRow } from "models/pages";
import { RowList } from "./pageElements";
import { ModuleLayoutContext } from "components/contexts/moduleLayoutContext";
import { translate } from "utils/i18n";
import { text } from "utils/textUtils";
import { makeSelectAssetsDefinition } from "redux/selectors/assetCatalogSelectors";
import { useRenderModule } from "components/siteStructure/pageView/useRenderModule";
import { OrphanContext } from "components/siteStructure/pageView/orphanContext";
import { useMemoSelector } from "components/hooks";
import { AssetNotFound } from "components/siteStructure/pageView/pageElements/assetNotFound";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { TemplateActionsBar } from "components/siteStructure/pageView/pageElements/templateActionsBar";
import { AssetTypes } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { BlankTemplateNotice } from "components/siteStructure/pageView/blankTemplateNotice";
import { SCROLLABLE_PAGE_DETAIL_CONTAINER_ID } from "components/shared/constants";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { CopyMode } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext/copyPageElementContext";

interface IProps extends IBaseProps {
  page: IPage;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  content: {
    padding: theme.spacing(0, 3),
    flex: 1
  },
  addGutter: {
    marginBottom: theme.spacing(15)
  },
  addButton: {
    position: "absolute",
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  dividerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3, 2, 0)
  },
  divider: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  notFound: {
    flex: 1
  },
  assetNotFoundAction: {
    color: theme.palette.custom.icons.lightGrey,
    "&:hover": {
      color: theme.palette.custom.secondaryAccent.tiffany
    }
  }
}));

const OrphanHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.dividerContainer}>
      {text(translate("sitestructure.orphanmodules"), {
        variant: "body2"
      })}
      <Divider className={classes.divider} />
    </div>
  );
};

export const PageLayout = (props: IProps) => {
  const { className, page } = props;
  const classes = useStyles();

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);

  const renderOrphan = useRenderModule(page.id, true);

  const templateDefinition = useMemoSelector(
    makeSelectAssetsDefinition,
    AssetTypes.TEMPLATE,
    page.template.key
  );

  const rootClassName = classnames(classes.root, className);

  const contentClass = classnames(classes.content, {
    [classes.addGutter]:
      page.orphans.length === 0 &&
      (dropContext.dropMode !== DropMode.None ||
        copyContext.copyMode !== CopyMode.None)
  });

  const orphansClass = classnames(classes.content, {
    [classes.addGutter]:
      page.orphans.length > 0 &&
      (dropContext.dropMode !== DropMode.None ||
        copyContext.copyMode !== CopyMode.None)
  });

  const isBlankTemplate = page.template.id === "No Template";
  const children = sumArray(page.template.rows, (row) => countRowChildren(row));
  const shouldShowTemplateSelect =
    isBlankTemplate && children === 0 && dropContext.dropMode === DropMode.None;

  return (
    <ModuleLayoutContext.Provider value={[page.template.instanceId]}>
      <div className={rootClassName} id={SCROLLABLE_PAGE_DETAIL_CONTAINER_ID}>
        <TemplateActionsBar template={templateDefinition}></TemplateActionsBar>
        <OrphanContext.Provider value={false}>
          {templateDefinition ? (
            <>
              {shouldShowTemplateSelect ? (
                <BlankTemplateNotice />
              ) : (
                <RowList className={contentClass} rows={page.template.rows} />
              )}
            </>
          ) : (
            <AssetNotFound
              className={classes.notFound}
              assetType={translate("sitestructure.template")}
              assetName={page.template.key}
            />
          )}
        </OrphanContext.Provider>
        {page.orphans.length > 0 && (
          <>
            <OrphanHeader />
            <OrphanContext.Provider value={true}>
              <div className={orphansClass}>
                {page.orphans.map(renderOrphan)}
              </div>
            </OrphanContext.Provider>
          </>
        )}
      </div>
    </ModuleLayoutContext.Provider>
  );
};

function countRowChildren(row: IRow): number {
  return sumArray(row.cells, (cell) => {
    switch (cell.content.contentType) {
      case "FixedSlot":
        return 1;
      case "Slot":
        return cell.content.modules.length;
      case "RowList":
        return sumArray(cell.content.rows, (row) => countRowChildren(row));
      default:
        return 0;
    }
  });
}

function sumArray<T>(array: Array<T>, select: (T) => number): number {
  return array.reduce((total, current) => total + select(current), 0);
}
