import { makeStyles, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import emptyFolder from "images/empty_permissions_tab.svg";
import { useDispatch } from "react-redux";
import {
  closeSidebar,
  setSelectedSiteAssetType
} from "redux/actions/siteStructureActions";
import { D3Button } from "components/shared/d3Components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: theme.spacing(0, 4, 4, 4)
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginBottom: theme.spacing(4),
    border: `${theme.spacing(0.125)}px dashed ${
      theme.palette.custom.ds.fiord.fiord300
    }`,
    borderRadius: "4px"
  },
  image: {
    width: "340px",
    height: "256px",
    marginBottom: theme.spacing(8),
    display: "block",
    background: `url(${emptyFolder})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    mixBlendMode: "multiply",
    lineHeight: "20em",
    textAlign: "center",
    backgroundRepeat: "no-repeat"
  },
  title: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(30),
    color: theme.palette.custom.ds.grayscales.gr800,
    paddingBottom: theme.spacing(3)
  },
  subtitle: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr800,
    width: "337px"
  },
  description: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr600,
    width: theme.spacing(47.5)
  },
  slotLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    color: theme.palette.custom.ds.grayscales.gr800
  },
  button: {
    marginTop: theme.spacing(3.5),
    "& .MuiTypography-body1": {
      color: theme.palette.custom.ds.grayscales.gr800
    }
  }
}));

export function BlankTemplateNotice() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(closeSidebar()); // ensure detail sidebar is closed
    dispatch(setSelectedSiteAssetType("templates")); // open template select sidebar
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.slotLabel}> Page Content</Typography>
      <div className={classes.box}>
        <div className={classes.image}></div>
        <Typography className={classes.subtitle}>
          {translate("sitestructure.blank_template_notice.subtitle")}
        </Typography>
        <Typography className={classes.title}>
          {translate("sitestructure.blank_template_notice.title")}
        </Typography>
        <Typography className={classes.description}>
          {translate("sitestructure.blank_template_notice.description")}
        </Typography>
        <D3Button
          className={classes.button}
          onClick={onClick}
          icon="auto_awesome_mosaic"
          iconPosition="left"
          variant="outlined"
          color="secondary"
        >
          {translate("sitestructure.blank_template_notice.button")}
        </D3Button>
      </div>
    </div>
  );
}
