import { createAction } from "redux-actions";
import { IScrollManagementPayload } from "models/scrollManagement";

export const setScrollManagementPayload = createAction(
  `SET_SCROLL_MANAGEMENT_PAYLOAD`,
  (payload: IScrollManagementPayload) => payload
);

export const unsetScrollManagementPayload = createAction(
  `UNSET_SCROLL_MANAGEMENT_PAYLOAD`
);

export const setIsReadyToScrollIntoElement = createAction(
  `SET_IS_READY_TO_SCROLL_INTO_ELEMENT`,
  (payload: boolean) => payload
);
