import { useContext, useMemo } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ILayout } from "models/pages";
import { RowList } from "./cellContent_rowList";
import { LayoutHeader } from "./layoutHeader";
import { AssetNotFound } from "./assetNotFound";
import { translate } from "utils/i18n";
import { useMemoSelector } from "components/hooks";
import {
  makeIsSelectedPageElement,
  selectHighlightedSiteAssetId
} from "redux/selectors/siteStructureSelectors";
import { makeSelectAssetsDefinition } from "redux/selectors/assetCatalogSelectors";
import { ModuleLayoutContext } from "components/contexts/moduleLayoutContext";
import { ActionButton } from "components/shared/actionButton";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { PermissionCodes } from "catalogs/permissionCodes";
import { AssetTypes } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { useSelector } from "react-redux";
import { useScrollManagement } from "redux/hooks/useScrollManagement";
import { SCROLLABLE_PAGE_DETAIL_CONTAINER_ID } from "components/shared/constants";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { CopyMode } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext/copyPageElementContext";

interface IProps extends IBaseProps {
  layout: ILayout;
  orphan?: boolean;
  isDisabled?: boolean;
  onSelect: (element: ILayout) => void;
  onMove: (element: ILayout) => void;
  onDelete: (element: ILayout) => void;
  onCopy: (element: ILayout) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    transition: theme.transitions.create("box-shadow"),
    "&:active:has( > $header:hover)": {
      "& > $rows": {
        border: `2px solid ${theme.palette.custom.ds.viking.viking300}`,
        borderTop: "none",
        padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5) - 1}px ${
          theme.spacing(1.5) - 1
        }px`
      },
      "& > $header": {
        border: `2px solid ${theme.palette.custom.ds.viking.viking300}`,
        borderBottom: "none",
        padding: `${theme.spacing(0.5) - 1}px ${
          theme.spacing(1) - 1
        }px ${theme.spacing(0.5)}px`
      }
    },
    "&:not($noHover):not(:active):has( > $header:hover)": {
      boxShadow: theme.palette.custom.ds.shadow.s300
    }
  },
  header: {
    cursor: "pointer",
    backgroundColor: theme.palette.custom.ds.bahama.bahama100,
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    border: `1px solid ${theme.palette.custom.ds.bahama.bahama300}`,
    borderBottom: "none",
    padding: theme.spacing(0.5, 1),
    "&$selected": {
      backgroundColor: theme.palette.custom.ds.viking.viking300,
      color: theme.palette.common.white,
      borderColor: theme.palette.custom.ds.viking.viking500,
      "& + $rows": {
        boxShadow: "unset",
        borderColor: theme.palette.custom.ds.viking.viking500,
        borderTop: "0",
        backgroundColor: theme.palette.custom.ds.viking.viking100
      }
    },
    "&:not($noHover):hover": {
      color: theme.palette.custom.ds.grayscales.gr700
    },
    "&$disabled": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr300,
      color: theme.palette.custom.ds.grayscales.gr500,
      "& + $rows": {
        backgroundColor: theme.palette.custom.ds.fiord.fiord100
      }
    },
    "&$highlighted": {
      border: `2px solid ${theme.palette.custom.ds.viking.viking500}`,
      borderBottom: "none",
      "& + $rows": {
        border: `2px solid ${theme.palette.custom.ds.viking.viking500}`,
        borderTop: "none"
      }
    }
  },
  rows: {
    flex: 1,
    border: `1px solid ${theme.palette.custom.ds.bahama.bahama300}`,
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    borderTop: "0",
    padding: theme.spacing(1.5),
    transition: "box-shadow ease 200ms"
  },
  notFound: {
    flex: 1
  },
  orphan: {
    "&:not($selected)": {
      backgroundColor: theme.palette.custom.ds.warning.warning100,
      "& > $rows": {
        border: `1px solid ${theme.palette.custom.ds.warning.warning500}`,
        borderRadius: theme.spacing(0, 0, 0.5, 0.5),
        borderTop: "none",
        backgroundColor: theme.palette.custom.ds.warning.warning100,
        color: theme.palette.custom.ds.warning.warning700
      },
      "& > $header": {
        backgroundColor: theme.palette.custom.ds.warning.warning300,
        color: theme.palette.custom.ds.warning.warning700,
        border: `1px solid ${theme.palette.custom.ds.warning.warning500}`,
        borderRadius: theme.spacing(0.5, 0.5, 0, 0),
        borderBottom: "none"
      },
      "&:hover": {
        "& > $rows": {
          backgroundColor: theme.palette.custom.ds.warning.warning100
        },
        "& > $header": {
          backgroundColor: theme.palette.custom.ds.warning.warning300,
          color: theme.palette.custom.ds.warning.warning700
        }
      }
    }
  },
  selected: {}, // pseudo class for selected state
  noHover: {
    cursor: "default"
  },
  disabled: {}, // pseudo class for disabled state
  highlighted: {} // pseudo class for highlighted state
}));

export const Layout = (props: IProps) => {
  const {
    className,
    layout,
    orphan = false,
    isDisabled = false,
    onSelect,
    onMove,
    onDelete,
    onCopy
  } = props;
  const classes = useStyles();

  const isSelected = useMemoSelector(
    makeIsSelectedPageElement,
    layout.instanceId
  );

  const highlightedSiteAssetId = useSelector(selectHighlightedSiteAssetId);

  const isHighlighted = highlightedSiteAssetId === layout.instanceId;

  const { elementToScrollIntoRef } = useScrollManagement({
    elementId: layout.instanceId,
    scrollableElementId: SCROLLABLE_PAGE_DETAIL_CONTAINER_ID,
    scrollScope: "PAGE_CONTENT"
  });

  const layoutDefinition = useMemoSelector(
    makeSelectAssetsDefinition,
    AssetTypes.LAYOUT,
    layout.key
  );

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);
  const layoutContext = useContext(ModuleLayoutContext);

  const layoutName = layout.key;
  const showDisabledStyle = isDisabled && !isSelected;

  const memoizedModuleLayoutContextValue = useMemo(
    () => [...layoutContext, layout.instanceId],
    [layoutContext, layout.instanceId]
  );

  if (!layoutDefinition) {
    return (
      <AssetNotFound
        className={classes.notFound}
        assetType={translate("sitestructure.layout")}
        assetName={layoutName}
        callToAction={
          <ActionButton
            icon="delete_outline"
            permissions={[PermissionCodes.EditContent]}
            tooltip={translate("sitestructure.removelayout.tooltip")}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              onDelete(layout);
            }}
          />
        }
      />
    );
  }

  const rootClassName = classnames(
    classes.root,
    className,
    {
      [classes.selected]: isSelected,
      [classes.orphan]: orphan && layoutContext.length === 1,
      [classes.disabled]: showDisabledStyle,
      [classes.noHover]:
        dropContext.dropMode === DropMode.Move ||
        dropContext.dropMode === DropMode.Add ||
        copyContext.copyMode === CopyMode.Copy
    },
    "layout"
  );

  const headerClassName = classnames(classes.header, {
    [classes.selected]: isSelected,
    [classes.highlighted]: isHighlighted,
    [classes.disabled]: showDisabledStyle,
    [classes.noHover]:
      dropContext.dropMode === DropMode.Move ||
      dropContext.dropMode === DropMode.Add ||
      copyContext.copyMode === CopyMode.Copy
  });

  const rowsClassName = classnames(classes.rows, {
    [classes.orphan]: orphan && layoutContext.length === 1
  });

  return (
    <div className={rootClassName} ref={elementToScrollIntoRef}>
      <LayoutHeader
        className={headerClassName}
        label={layout.label ?? layout.id}
        onClick={() => onSelect(layout)}
        onRemoveLayout={() => onDelete(layout)}
        isLayoutSelected={isSelected}
        onMoveLayout={() => {
          onSelect(layout);
          onMove(layout);
        }}
        onCopyLayout={() => {
          onSelect(layout);
          onCopy(layout);
        }}
        disableMove={dropContext.dropMode === DropMode.Add}
        disableCopy={
          dropContext.dropMode === DropMode.Add ||
          copyContext.copyMode === CopyMode.Copy
        }
        isLayoutMoving={
          dropContext.dropMode === DropMode.Move &&
          dropContext.pageElementIdentifier?.instanceId === layout.instanceId
        }
        orphan={orphan}
        isDisabled={isDisabled}
        disabledStyle={showDisabledStyle}
      />
      <ModuleLayoutContext.Provider value={memoizedModuleLayoutContextValue}>
        <RowList
          className={rowsClassName}
          rows={layout.rows}
          layout={layoutDefinition}
        />
      </ModuleLayoutContext.Provider>
    </div>
  );
};
