import find from "lodash/find";
import {
  ModuleAddedToSlotNotification,
  ModuleCopiedIntoSlotNotification
} from "@d3-forge/forge-notifications";
import {
  RICH_TEXT_MODULE_ID,
  RICH_TEXT_MODULE_NAMESPACE,
  RICH_TEXT_MODULE_TRANSLATABLE_PROPERTIES_KEY
} from "components/richText/constants";
import { ElementIdentifier } from "components/siteStructure/pageView/pageElements/context";
import { IModule, IPage } from "models/pages";

import { MarkdownHtmlConverter } from "components/converters/markdownHtmlConverter";
import {
  IContextModules,
  IModuleDefinition
} from "models/siteAssets/moduleDefinition";
import { AssetTypes } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { TranslatableProperty } from "@d3-forge/forge-commands";
import { includes, isEmpty, map } from "lodash";
import { IRichTextEntry } from "models/richText";
import {
  IEditorInstance,
  ITinyMceGlobal
} from "components/shared/TinyMCEEditor/types";

export function getIsStaticTextModuleAddedToSlotNotification(
  notification: ModuleAddedToSlotNotification | ModuleCopiedIntoSlotNotification
): boolean {
  const { notification: notificationName, module } = notification as any;

  const moduleKey = module?.id;

  return (
    notificationName === "ModuleAddedToSlotNotification" &&
    moduleKey === RICH_TEXT_MODULE_ID
  );
}

export function getRichTextModuleKey() {
  return `${RICH_TEXT_MODULE_ID}|${RICH_TEXT_MODULE_NAMESPACE}`;
}

export function getIsStaticTextModuleByModuleId(id: string) {
  return id === RICH_TEXT_MODULE_ID;
}

export function getModuleDefinitionByModuleId(
  modules: Record<string, any>,
  currentPage: IPage | null,
  moduleId: string
) {
  if (currentPage === null) {
    return "";
  }

  const currentPageContextKey = currentPage.contextName;
  const currentContextModules = modules[currentPageContextKey];

  const moduleKey =
    find(
      Object.keys(modules[currentPageContextKey]),
      (key) => currentContextModules[key].id === moduleId
    ) ?? "";

  return currentContextModules[moduleKey];
}

export function getModuleIdByModuleKey(moduleKey: string) {
  return moduleKey.split("|")[0];
}

export function getIsRichTextModuleDefinition(
  moduleDefinition: IModuleDefinition
) {
  return moduleDefinition.id === RICH_TEXT_MODULE_ID;
}

export function getIsRichTextModuleSelected(
  pageElementIdentifier: ElementIdentifier
) {
  const { type, key } = pageElementIdentifier;

  const moduleId = getModuleIdByModuleKey(key);

  return type === AssetTypes.MODULE && moduleId === RICH_TEXT_MODULE_ID;
}

export function getStaticTextModuleTranslationForCulture(
  culture: string,
  module: IModule
) {
  const { translatableProperties } = module;

  if (
    translatableProperties[RICH_TEXT_MODULE_TRANSLATABLE_PROPERTIES_KEY] ===
    undefined
  ) {
    return "";
  }

  const staticText =
    translatableProperties[RICH_TEXT_MODULE_TRANSLATABLE_PROPERTIES_KEY];
  const translation = staticText[culture];

  return (translation?.value as string) ?? "";
}

export async function getConvertedHTMLTextFromMarkdown(markdownText: string) {
  try {
    const convertedText = await MarkdownHtmlConverter.convertMarkdownToHtml(
      markdownText
    );

    return convertedText;
  } catch (err) {
    return "";
  }
}

export function getTransformedContextModulesWithRichTextModuleMetadata(
  contextModules: IContextModules
) {
  const contexts = Object.keys(contextModules);

  const richTextModuleKey = getRichTextModuleKey();

  const transformedContexts = contexts.reduce(
    (transformedResult: IContextModules, contextKey) => {
      const moduleCatalog = contextModules[contextKey];
      const richTextModuleDefinition = moduleCatalog[richTextModuleKey];

      if (richTextModuleDefinition === undefined) {
        return { ...transformedResult, [contextKey]: moduleCatalog };
      }

      const transformedRichTextModuleDefinition: IModuleDefinition = {
        ...richTextModuleDefinition,
        hasTranslatableProperties: true
      };

      return {
        ...transformedResult,
        [contextKey]: {
          ...moduleCatalog,
          [richTextModuleKey]: transformedRichTextModuleDefinition
        }
      };
    },
    {}
  );

  return transformedContexts;
}

export function getTranslatablePropertyValueByCulture(
  translatableProperties: Record<string, TranslatableProperty> | undefined,
  culture: string
) {
  if (translatableProperties === undefined) {
    return "";
  }

  return ((translatableProperties[culture] as any)?.value as string) ?? "";
}

async function getRichTextEntriesForCulture(
  value: string,
  culture: string
): Promise<IRichTextEntry> {
  const html = (await MarkdownHtmlConverter.convertMarkdownToHtml(
    value
  )) as string;

  return {
    culture,
    convertedText: html,
    value,
    hasTranslation: !isEmpty(html)
  };
}

export async function getRichTextEntriesForCultures(
  cultures: string[],
  translatableProperties: Record<string, TranslatableProperty>
): Promise<IRichTextEntry[]> {
  const result = await Promise.all(
    map(cultures, (culture) => {
      const value = getTranslatablePropertyValueByCulture(
        translatableProperties,
        culture
      );

      return getRichTextEntriesForCulture(value, culture);
    })
  );

  return result;
}

export function getWordCountFromRichTextEditorContent(editor: IEditorInstance) {
  const wordCountPlugin = editor.plugins.wordcount;
  return wordCountPlugin.getCount();
}

export function getRichTextEditorInstanceById(id: string) {
  const tinymce = (window as any).tinymce as ITinyMceGlobal;

  if (tinymce?.get === undefined) {
    return;
  }

  const editorInstance = find(tinymce.get(), (editor) => editor.id === id);
  return editorInstance;
}

export function getShouldHandleRichTextAfterNotificationReceived(
  ntf: ModuleAddedToSlotNotification | ModuleCopiedIntoSlotNotification
) {
  const notifications = [
    "ModuleCopiedIntoSlotNotification",
    "ModuleAddedToSlotNotification",
    "LayoutAddedToSlotNotification"
  ];

  return includes(notifications, ntf.notification);
}
