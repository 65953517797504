export * from "./dropPageElementContext";

export type ElementIdentifier = {
  instanceId?: string;
  key: string;
  type: "Module" | "Layout";
  contextName?: string;
};

export const DEFAULT_IDENTIFIER: ElementIdentifier = {
  key: "",
  contextName: "",
  type: "Module"
};
