import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1
  },
  textRenderer: {
    fontFamily: "Rubik",
    fontSize: theme.typography.pxToRem(13),
    overflow: "hidden",
    maxHeight: theme.spacing(40)
  },
  noAvailableTranslationsMessage: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.custom.ds.grayscales.gr600
  },
  warningIcon: {
    color: theme.palette.custom.ds.warning.warning500,
    position: "relative",
    top: 5,
    marginRight: theme.spacing(1)
  }
}));
