import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(18.5),
    height: theme.spacing(22),
    display: "flex",
    flexDirection: "column"
  },
  button: {
    "&:hover": {
      backgroundColor: "inherit",
      "& $preview": {
        boxShadow: theme.palette.custom.ds.shadow.s400
      }
    }
  },
  preview: {
    height: theme.spacing(25),
    border: `1px solid ${theme.palette.custom.greyscales.bordersAndSeparators.lineDarker}`
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    maxWidth: "100%"
  },
  labelText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(13)
  },
  selected: {
    borderColor: theme.palette.custom.ds.viking.viking500,
    color: theme.palette.custom.ds.viking.viking500
  }
}));
