import { Action } from "redux-actions";
import { IRichTextState } from "models/domainStates";

import { reducerFactory } from "redux/reducers/coreReducer";
import {
  disposeRichTextModal,
  triggerRichTextModal,
  updateRichTextTranslationFormValue
} from "redux/actions/richTextActions";
import { IRichTextTranslationForm } from "models/richText";

export const initialState: IRichTextState = {
  isAddRichTextModalOpened: false,
  richTextTranslationForm: undefined
};

const reactions = {
  [triggerRichTextModal.toString()]: (
    state: IRichTextState,
    action: Action<IRichTextTranslationForm>
  ) => {
    state.richTextTranslationForm = action.payload;
    state.isAddRichTextModalOpened = true;
  },
  [disposeRichTextModal.toString()]: (state: IRichTextState) => {
    state.richTextTranslationForm = undefined;
    state.isAddRichTextModalOpened = false;
  },
  [updateRichTextTranslationFormValue.toString()]: (
    state: IRichTextState,
    action: Action<string>
  ) => {
    if (state.richTextTranslationForm === undefined) {
      return;
    }

    state.richTextTranslationForm.value = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
