import { useState } from "react";
import classnames from "classnames";
import { makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IComponentProperty } from "models/componentProperty";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { useDebounce } from "components/hooks";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  errorMessage?: string;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  textFieldArea: {
    display: "flex",
    flexDirection: "row",
    "& .MuiTextField-root:has(+.MuiAutocomplete-root:not([hidden]))": {
      display: "none"
    }
  },
  noVariableSwitch: {
    paddingRight: theme.spacing(4)
  }
}));

export const StringProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    disabled = false,
    errorMessage = "",
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const hideVariableSwitch = !propertyDefinition.canUseVariables ?? false;
  const textFieldAreaClassName = classnames(classes.textFieldArea, {
    [classes.noVariableSwitch]: hideVariableSwitch
  });

  const [localValue, setLocalValue] = useState(value);
  const debouncedOnChange = useDebounce(onChange, 2500);

  const handleChange = (newValue: string) => {
    if (localValue === newValue) {
      return;
    }

    setLocalValue(newValue);
    debouncedOnChange(propertyDefinition.name, newValue);
  };

  const handleBlur = () => {
    debouncedOnChange.flush();
  };

  const clearValueOnVariableSwitch = () => {
    setLocalValue("");
    debouncedOnChange(propertyDefinition.name, value);
  };

  const showErrorMessage = errorMessage.length > 0;
  const showMandatoryMessage =
    mandatory.showMessage ||
    (propertyDefinition.mandatory && localValue === "");

  const getHelperText = () => {
    if (showMandatoryMessage) return mandatory.message;
    if (showErrorMessage) return errorMessage;

    return "";
  };

  return (
    <div className={rootClassName}>
      <TextFieldLabel
        value={propertyDefinition.displayName || propertyDefinition.name}
        hint={propertyDefinition.description}
        mandatory={propertyDefinition.mandatory}
        disabled={disabled}
      />
      <div className={textFieldAreaClassName}>
        <TextField
          className={showMandatoryMessage ? mandatory.className : ""}
          fullWidth={true}
          value={localValue}
          variant="outlined"
          size="small"
          onChange={({ target }) => handleChange(target.value)}
          onBlur={handleBlur}
          disabled={disabled}
          helperText={getHelperText()}
          error={showErrorMessage}
        />
        {!hideVariableSwitch && (
          <VariableSwitch
            propertyName={propertyDefinition.name}
            propertyValue={localValue}
            propertyTypeName={propertyDefinition.typeName}
            disabled={disabled}
            onChange={onChange}
            onSwitch={clearValueOnVariableSwitch}
            mandatory={mandatory}
          />
        )}
      </div>
    </div>
  );
};
