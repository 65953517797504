import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar, openSidebar } from "redux/actions/siteStructureActions";
import { selectSidebarOpen } from "redux/selectors/siteStructureSelectors";

export function useCollapsePropertiesSidebar() {
  const dispatch = useDispatch();
  const open = useSelector(selectSidebarOpen);

  const dispatchOpenSidebar = useCallback(
    () => dispatch(openSidebar()),
    [dispatch]
  );

  const dispatchCloseSidebar = useCallback(
    () => dispatch(closeSidebar()),
    [dispatch]
  );

  return {
    isOpen: open,
    openSidebar: dispatchOpenSidebar,
    closeSidebar: dispatchCloseSidebar
  };
}
