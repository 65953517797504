import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { CopyMode } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext/copyPageElementContext";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { IModule } from "models/pages";
import { useContext } from "react";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme) => ({
  contextMenuItem: {
    fontSize: "0.8rem"
  },
  contextMenuItemIcon: {
    fontSize: "1rem",
    color: theme.palette.custom.ds.viking.viking500,
    marginRight: theme.spacing(1)
  }
}));

interface IModuleActionProps {
  module: IModule;
  anchorEl: null | HTMLElement;
  isDisabled?: boolean;
  handleClose: (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => void;
  onCopy: (module: IModule) => void;
  onMove: (module: IModule) => void;
  onDelete: (module: IModule) => void;
  onSelect: (module: IModule) => void;
}

export const ModuleActions = (props: IModuleActionProps) => {
  const {
    module,
    anchorEl,
    isDisabled = false,
    handleClose,
    onCopy,
    onMove,
    onDelete,
    onSelect
  } = props;

  const classes = useStyles();

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);

  const moveDisabled =
    dropContext.dropMode === DropMode.Add ||
    copyContext.copyMode === CopyMode.Copy;

  const copyDisabled =
    dropContext.dropMode === DropMode.Add ||
    dropContext.dropMode === DropMode.Move;

  const canCopy = !isDisabled && !copyDisabled;
  const canMove = !isDisabled && !moveDisabled;
  const canDelete = !isDisabled;

  return (
    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
      <MenuItem
        className={classes.contextMenuItem}
        disabled={!canCopy}
        onClick={(e) => {
          e.stopPropagation();
          onCopy(module);
          handleClose(e);
          onSelect(module);
        }}
      >
        <UxdIcon name="content_copy" className={classes.contextMenuItemIcon} />
        {translate("sitestructure.copy")}
      </MenuItem>
      <MenuItem
        className={classes.contextMenuItem}
        disabled={!canMove}
        onClick={(e) => {
          e.stopPropagation();
          onMove(module);
          handleClose(e);
          onSelect(module);
        }}
      >
        <UxdIcon name="zoom_out_map" className={classes.contextMenuItemIcon} />
        {translate("sitestructure.move")}
      </MenuItem>
      <MenuItem
        className={classes.contextMenuItem}
        disabled={!canDelete}
        onClick={(e) => {
          e.stopPropagation();
          onDelete(module);
          handleClose(e);
        }}
      >
        <UxdIcon
          name="delete_outline"
          className={classes.contextMenuItemIcon}
        />
        {translate("sitestructure.delete")}
      </MenuItem>
    </Menu>
  );
};

export default ModuleActions;
