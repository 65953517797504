import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    outline: "none", // added 'outline: none' to remove the blue outline when the button is clicked
    border: `1px dashed ${theme.palette.custom.ds.viking.viking500}`,
    backgroundColor: theme.palette.custom.ds.viking.viking300,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0, 2, 0, 2),
    margin: theme.spacing(1),
    minHeight: theme.spacing(3),
    flex: 1,
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    display: "block",
    width: "calc(100% - 25px * 2)",
    boxSizing: "content-box",
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking500
    }
  },
  hidden: {
    display: "none"
  }
}));
