import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { CopyModule } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { CopySiteAssetPayload } from "redux/actions/typings/siteStructureActions";
import {
  fetchPage,
  setLastTriggeredCommandId
} from "redux/actions/siteStructureActions";

export function* sendCopyModule(action: Action<CopySiteAssetPayload>) {
  try {
    const command = new CopyModule(action.payload);
    const updateLastCommandIdAction = setLastTriggeredCommandId(
      command.bodyObject.commandId ?? ""
    );

    const commandAction = issueCommand({
      command,
      onSuccessAction: fetchPage(action.payload.targetPageId),
      errorMessage: "notification.copyitem.error",
      successMessage: "notification.copyitem.success",
      toggleNotification: true
    });

    yield put(commandAction);
    yield put(updateLastCommandIdAction);
  } catch (error) {
    console.error(error);
  }
}
