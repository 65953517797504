import { IRichTextConfiguration } from "models/configuration";

export const TINYMCE_SCRIPT_SRC = "/tinymce/tinymce.min.js";
export const LICENSE_KEY =
  "T7LK931C1A09FEDDECBD835831EE9E3A638B33910295F91988C3CFF836365DF4";

export const richTextConfig: IRichTextConfiguration = {
  PresetTextsConfiguration: {
    PresetTexts: []
  },
  RichTextCustomEntitiesConfiguration: {
    RichTextCustomEntities: []
  },
  SpellCheckConfiguration: {
    IsEnabled: false,
    SpellcheckRootUrl: ""
  },
  TextEditorConfiguration: {
    Capitalization: true,
    ClearFormatting: true,
    FormatPainter: true,
    Quote: true,
    Redo: true,
    Strikethrough: true,
    Subscript: true,
    Superscript: true,
    Symbols: true,
    Undo: true,
    Emojis: true,
    Spellcheck: true
  }
};
