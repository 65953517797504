import React, { useContext, createContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContextValue, useNotifications } from "components/hooks";
import {
  ModuleTranslatablePropertySetNotification,
  ModuleTranslatablePropertyUnsetNotification
} from "@d3-forge/forge-notifications";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import {
  getIsRichTextModuleSelected,
  getModuleDefinitionByModuleId
} from "components/richText/utils";
import {
  disposeRichTextModal,
  updateRichTextTranslationFormValue
} from "redux/actions/richTextActions";
import {
  RICH_TEXT_MODULE_ID,
  RICH_TEXT_MODULE_TRANSLATABLE_PROPERTIES_KEY
} from "components/richText/constants";
import { selectModules } from "redux/selectors/assetCatalogSelectors";
import {
  selectSelectedPage,
  selectSelectedPageElement
} from "redux/selectors/siteStructureSelectors";
import { useRichTextCommands } from "components/richText/hooks/useRichTextCommands";
import {
  IRichTextTranslationForm,
  IRichTextCrudLoaders
} from "models/richText";
import {
  closeSidebar,
  fetchPage,
  setSelectedSiteAssetType,
  unsetSelectedPageElement
} from "redux/actions/siteStructureActions";
import { AssetTypes } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";

export interface RichTextManagementContextValue {
  onSelectRichTextModule: () => void;
  onCloseAddRichTextModal: () => void;
  onRichTextTranslationChange: (value: string) => void;
  onSubmitTranslation: (form: IRichTextTranslationForm) => Promise<void>;
  onDeleteTranslation: (culture: string) => Promise<void>;
  loaders: IRichTextCrudLoaders;
}

export const RichTextManagementContext = createContext(
  {} as RichTextManagementContextValue
);

export const RichTextManagementContextProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const dropContext = useContext(DropPageElementContext);
  const modules = useSelector(selectModules);
  const currentPageSelected = useSelector(selectSelectedPage);
  const currentPageElementSelected = useSelector(selectSelectedPageElement);

  const richTextCommands = useRichTextCommands();

  const onSelectRichTextModule = useCallback(() => {
    const moduleKey =
      getModuleDefinitionByModuleId(
        modules,
        currentPageSelected,
        RICH_TEXT_MODULE_ID
      )?.key ?? "";

    if (getIsRichTextModuleSelected(dropContext.pageElementIdentifier)) {
      dropContext.clear();
      return;
    }

    dispatch(unsetSelectedPageElement());
    dispatch(setSelectedSiteAssetType(undefined));
    dispatch(closeSidebar());

    dropContext.select(DropMode.Add, {
      key: moduleKey,
      type: AssetTypes.MODULE,
      contextName: currentPageSelected?.contextName
    });
  }, [dispatch, currentPageSelected, modules, dropContext]);

  const onRichTextTranslationChange = useCallback(
    (value: string) => {
      dispatch(updateRichTextTranslationFormValue(value));
    },
    [dispatch]
  );

  const onSubmitTranslation = useCallback(
    async (form: IRichTextTranslationForm) => {
      //TODO: Gather the ones not set but present possibly from the
      // modal itself

      if (currentPageSelected === null || currentPageElementSelected === null) {
        return;
      }

      const { culture, value } = form;

      await richTextCommands.setModuleTranslatableProperties({
        pageId: currentPageSelected.id,
        instanceId: currentPageElementSelected.instanceId,
        translatableProperties: {
          [RICH_TEXT_MODULE_TRANSLATABLE_PROPERTIES_KEY]: {
            [culture]: { value: value as any }
          }
        }
      });
    },
    [currentPageSelected, currentPageElementSelected, richTextCommands]
  );

  const onDeleteTranslation = useCallback(
    async (culture: string) => {
      if (currentPageSelected === null || currentPageElementSelected === null) {
        return;
      }

      await richTextCommands.unsetModuleTranslatableProperties({
        pageId: currentPageSelected.id,
        instanceId: currentPageElementSelected.instanceId,
        propertyName: RICH_TEXT_MODULE_TRANSLATABLE_PROPERTIES_KEY,
        culture
      });
    },
    [currentPageElementSelected, currentPageSelected, richTextCommands]
  );

  const onCloseAddRichTextModal = useCallback(() => {
    dispatch(disposeRichTextModal());
  }, [dispatch]);

  const handleModuleTranslatablePropertySetNotification = useCallback(
    async (notification: ModuleTranslatablePropertySetNotification) => {
      dispatch(fetchPage(notification.pageId));
      dispatch(disposeRichTextModal());
    },
    [dispatch]
  );

  useNotifications(
    ["ModuleTranslatablePropertySetNotification"],
    (notification: ModuleTranslatablePropertySetNotification) => {
      handleModuleTranslatablePropertySetNotification(notification);
    },
    [dispatch]
  );

  useNotifications(
    ["ModuleTranslatablePropertyUnsetNotification"],
    (notification: ModuleTranslatablePropertyUnsetNotification) => {
      dispatch(fetchPage(notification.pageId));
    },
    [dispatch]
  );

  const contextValue = useContextValue<RichTextManagementContextValue>({
    onSelectRichTextModule,
    onCloseAddRichTextModal,
    onRichTextTranslationChange,
    onSubmitTranslation,
    onDeleteTranslation,
    loaders: richTextCommands.loaders
  });

  return (
    <RichTextManagementContext.Provider value={contextValue}>
      {children}
    </RichTextManagementContext.Provider>
  );
};

export const useRichTextManagementContext =
  (): RichTextManagementContextValue => {
    const context = useContext(RichTextManagementContext);
    return context;
  };
