import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { useMemoSelector } from "components/hooks";
import { makeSelectPageElementDefinition } from "redux/selectors/assetCatalogSelectors";
import { useState } from "react";
import { themeSizing } from "theme/deltatreTheme";
import SiteItemSidebarContent from "components/siteStructure/siteItemSidebar/siteItemSidebarContent";
import {
  AssetTypes,
  SiteItemElement
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { SiteItemSidebarHeader } from "components/siteStructure/siteItemSidebar/siteItemSidebarHeader";
import { PageSidebarAssetNotFound } from "components/siteStructure/pageView/pageSidebar/pageSidebarAssetNotFound";
import SiteAssetSelectSidebar from "components/siteStructure/pageView/pageSidebar/siteAssetSelectSidebar";
import { useCollapsePropertiesSidebar } from "./hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: themeSizing.rightPanel,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    borderLeft: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
    transition: "width .3s ease-in-out",
    "&$collapsed": {
      width: theme.spacing(8)
    }
  },
  collapsed: {},
  error: {
    color: theme.palette.custom.ds.error.error500
  },
  scrolled: {
    boxShadow: `${theme.spacing(0, 0.125, 0.5)} ${
      theme.palette.custom.greyscales.shadows.sh500
    }`
  }
}));

interface IProps extends IBaseProps {
  node: ISiteItem;
  element: SiteItemElement;
}

export const SiteItemSidebar = (props: IProps) => {
  const { className, node, element } = props;
  const classes = useStyles();
  const { isOpen, openSidebar, closeSidebar } = useCollapsePropertiesSidebar();

  const [scrolled, setScrolled] = useState<boolean>(false);

  const headerClassName = classnames({ [classes.scrolled]: scrolled });
  const rootClassName = classnames(classes.root, className, {
    [classes.collapsed]: !isOpen
  });

  const definition = useMemoSelector(makeSelectPageElementDefinition, element);
  const isMenu = element.moduleType === AssetTypes.MENU;

  if (!isOpen && !isMenu) {
    return <SiteAssetSelectSidebar page={node} />;
  }

  if (!isMenu && !definition) {
    return (
      <PageSidebarAssetNotFound
        className={rootClassName}
        assetName={element.label}
        type={element.moduleType}
      />
    );
  }

  const handleScroll = (isScrolled: boolean): void => setScrolled(isScrolled);

  return (
    <div className={rootClassName}>
      <SiteItemSidebarHeader
        className={headerClassName}
        open={isOpen}
        element={element}
        definition={definition}
        onOpen={openSidebar}
        onClose={closeSidebar}
      />
      <SiteItemSidebarContent
        open={isOpen}
        node={node}
        element={element}
        definition={definition}
        isMenu={isMenu}
        onScroll={handleScroll}
      />
    </div>
  );
};

export default SiteItemSidebar;
