import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { ActionSnackbar } from "components/shared/actionSnackbar";
import { CopyMode } from "./copyPageElementContext";

interface IProps extends IBaseProps {
  onCancel: () => void;
  elementType: "Module" | "Layout";
  mode: CopyMode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& strong": {
      margin: theme.spacing(0, 1)
    }
  }
}));

const icons = {
  copy: "content_copy"
};

export const CopyPageElementSnackbar = (props: IProps) => {
  const { className, mode, elementType, onCancel } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <ActionSnackbar
      className={rootClassName}
      show={mode !== CopyMode.None}
      icon={icons[mode]}
      message={translate(`sitestructure.snackbar.${CopyMode[mode]}`, {
        asset: translate(
          `sitestructure.${elementType.toLowerCase()}`
        ).toLowerCase()
      })}
      onCancel={() => onCancel()}
    />
  );
};
