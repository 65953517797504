import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { fetchPage } from "redux/actions/siteStructureActions";
import { PageLayout } from "./pageLayout";
import { ISiteItem } from "models/siteItem";
import {
  selectSelectedPage,
  selectSelectedPageElement
} from "redux/selectors/siteStructureSelectors";
import { useNotifications, useRequestStatus } from "components/hooks";
import { PageTemplateChangedNotification } from "@d3-forge/forge-notifications";
import { ChangeTemplateContextProvider } from "components/contexts/changeTemplateContext";
import { SkeletonPageDetail } from "components/siteStructure/pageView/skeletonPageDetail";
import ErrorPage from "components/errorPages";
import { DropPageElementContextProvider } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import SiteItemSidebar from "components/siteStructure/siteItemSidebar/siteItemSidebar";
import { RichTextManagementContextProvider } from "components/richText/context";

interface IProps extends IBaseProps {
  node: ISiteItem;
  isLoading: boolean;
}
/*
  TODO: since lot of stuff is requiring the pageId
        probably is worth creating a context for it.
        Evaluate context for the whole page object.
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex"
  },
  main: {
    flex: 1,
    display: "flex",
    overflow: "hidden",
    position: "relative"
  }
}));

export const PageDetail = (props: IProps) => {
  const { className, node, isLoading: nodeIsLoading } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPage(node.nodeId));
  }, [dispatch, node.nodeId]);

  const page = useSelector(selectSelectedPage);
  const element = useSelector(selectSelectedPageElement);
  const request = useRequestStatus(fetchPage, node.nodeId);

  useNotifications(
    ["PageTemplateChangedNotification"],
    (ntf: PageTemplateChangedNotification) => {
      if (ntf.pageId === node.nodeId) {
        dispatch(fetchPage(ntf.pageId));
      }
    },
    [node.nodeId]
  );

  const shouldRenderSkeleton =
    !page || page.id !== node.nodeId || request.isLoading || nodeIsLoading;

  if (shouldRenderSkeleton) {
    return <SkeletonPageDetail />;
  }

  if (request.error) {
    return <ErrorPage error="internal server error" />;
  }

  return (
    <div className={rootClassName}>
      {page && (
        <ChangeTemplateContextProvider
          pageId={page.id}
          pageContext={page.contextName}
          currentTemplateKey={page.template.key}
        >
          <DropPageElementContextProvider pageId={page.id}>
            <RichTextManagementContextProvider>
              <div className={classes.main}>
                <CurrentSiteItemContext.Provider value={node.path}>
                  <PageLayout page={page} />
                </CurrentSiteItemContext.Provider>
              </div>
              <SiteItemSidebar node={node} element={element ?? page.template} />
            </RichTextManagementContextProvider>
          </DropPageElementContextProvider>
        </ChangeTemplateContextProvider>
      )}
    </div>
  );
};
