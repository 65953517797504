import { SiteStructureOperationExecuted } from "models/siteStructure";

export const OPERATIONS_THAT_DISPOSE_SELECTED_DROP_OPERATION: SiteStructureOperationExecuted[] =
  [
    "VIEW_HISTORY_EXECUTED",
    "ADVANCED_SETTINGS_EXECUTED",
    "PAGE_ACTIONS_EXECUTED",
    "PUBLISH_BTN_EXECUTED",
    "UNPUBLISH_BTN_EXECUTED",
    "OPEN_PREVIEW_EXECUTED"
  ];

export const NOTHING_SELECTED_ERROR_MESSAGE =
  "[DropPageElementContext] Need to select a page element with a specific mode before dropping";
export const MISSING_INSTANCEID_ERROR_MESSAGE =
  "[DropPageElementContext] Missing instance id of the selected element";
export const MANDATORY_INSTANCEID_FOR_MOVE_ERROR_MESSAGE =
  "[DropPageElementContext] InstanceId must be provided to start a move action";
