import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    width: "900px",
    padding: "0px"
  },
  path: {
    fontSize: theme.typography.pxToRem(14)
  },
  fieldlabel: {
    marginTop: theme.spacing(2)
  },
  noErrorTextField: {
    marginBottom: theme.spacing(3)
  },
  editorContainer: {
    width: "100%",
    height: "100px"
  },
  modalContent: {
    padding: "0px"
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr800,
    position: "relative",
    top: "-4px"
  },
  wordCount: {
    position: "absolute",
    left: theme.spacing(4)
  }
}));
