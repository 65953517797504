import { useContext, useMemo, useState } from "react";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";
import {
  selectDefaultContext,
  selectLayoutCatalog,
  selectTemplatesCatalog
} from "redux/selectors/assetCatalogSelectors";
import { useDispatch, useSelector } from "react-redux";
import { IDomainState } from "models/domainStates";
import SiteAssetSelectButton from "./siteAssetSelectButton";
import SiteAssetSidebarHeader from "./siteAssetSidebarHeader";
import SiteAssetSelectList from "./siteAssetSelectList";
import SiteAssetSearch from "./siteAssetSearch";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "./styles";
import { translate } from "utils/i18n";
import classNames from "classnames";
import {
  changePageTemplate,
  setSelectedSiteAssetType
} from "redux/actions/siteStructureActions";
import { ISiteItem } from "models/siteItem";
import { NamespaceKeyContract } from "@d3-forge/forge-commands";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";
import { SiteAssetType } from "models/siteAssets/siteAssetTypes";
import { useRichTextManagementContext } from "components/richText/context";

import { getIsRichTextModuleSelected } from "components/richText/utils";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import { useModuleDefinitons } from "redux/hooks/useModuleDefinitions";
import { RICH_TEXT_MODULE_ID } from "components/richText/constants";
import { STATIC_IMAGE_MODULE_ID } from "components/staticImage/constants";
import { selectSelectedPage } from "redux/selectors/siteStructureSelectors";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";

export type IAssetDefinition =
  | ITemplateDefinition
  | ILayoutDefinition
  | IModuleDefinition;

const HEADER_LABELS: Record<
  Exclude<SiteAssetType, "contexts">,
  { title: string; description: string }
> = {
  templates: {
    title: "sitestructure.site_asset_sidebar.header.title.template",
    description: "sitestructure.site_asset_sidebar.header.description.template"
  },
  layouts: {
    title: "sitestructure.site_asset_sidebar.header.title.layout",
    description: "sitestructure.site_asset_sidebar.header.description.layout"
  },
  modules: {
    title: "sitestructure.site_asset_sidebar.header.title.module",
    description: "sitestructure.site_asset_sidebar.header.description.module"
  }
};

export interface ISiteAssetSelectSidebarProps extends IBaseProps {
  page: ISiteItem;
}

export const SiteAssetSelectSidebar = (props: ISiteAssetSelectSidebarProps) => {
  const { className, page } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const dropContext = useContext(DropPageElementContext);

  const defaultContext = useSelector(selectDefaultContext);
  const { onSelectRichTextModule } = useRichTextManagementContext();
  const { pageElementIdentifier } = useContext(DropPageElementContext);

  const currentPage = useSelector(selectSelectedPage);

  const type = useSelector(
    (state: IDomainState) => state.siteStructure.selectedSiteAssetType
  );

  const templates: ITemplateDefinition[] = useSelector((state: IDomainState) =>
    Object.values(selectTemplatesCatalog(state, page.contextName) ?? {})
  );

  const layouts: ILayoutDefinition[] = useSelector((state: IDomainState) =>
    Object.values(selectLayoutCatalog(state, page.contextName) ?? {})
  );

  const modules: IModuleDefinition[] = useModuleDefinitons(page.contextName, [
    RICH_TEXT_MODULE_ID,
    STATIC_IMAGE_MODULE_ID
  ]);

  const shouldRenderAddRichTextControl =
    userService.hasPermissions(PermissionCodes.EditContent) &&
    page.contextName !== defaultContext;

  const isRichTextModuleSelected =
    shouldRenderAddRichTextControl &&
    getIsRichTextModuleSelected(pageElementIdentifier);

  const canEdit =
    userService.hasSiteItemPermissions(page.path) ||
    userService.hasPermissions(PermissionCodes.EditContent);

  const rootClassName = classNames(className, classes.root);
  const listClassName = classNames(classes.listArea, {
    [classes.hidden]: type === undefined
  });

  const header = HEADER_LABELS[type ?? "templates"];

  const [search, setSearch] = useState<string>("");

  const assets = useMemo(() => {
    switch (type) {
      case "templates":
        return templates;
      case "layouts":
        return layouts.filter((layout) =>
          layout.label.toLowerCase().includes(search)
        );
      case "modules":
        return modules.filter((layout) =>
          layout.label.toLowerCase().includes(search)
        );
      default:
        return [];
    }
  }, [layouts, modules, search, templates, type]);

  const onClose = () => {
    dispatch(setSelectedSiteAssetType(undefined));
  };

  const onClick = (selected: SiteAssetType) => {
    const newType = selected === type ? undefined : selected;
    dispatch(setSelectedSiteAssetType(newType));
  };

  const onSiteAssetSelect = (id: string, namespace: string) => {
    if (!canEdit) {
      return;
    }

    switch (type) {
      case "templates": {
        const template: NamespaceKeyContract = { id, namespace };
        dispatch(changePageTemplate({ pageId: page.nodeId, template }));
        break;
      }

      case "layouts": {
        dropContext.select(DropMode.Add, {
          key: `${id}|${namespace}`,
          type: "Layout",
          contextName: currentPage?.contextName
        });
        break;
      }

      case "modules": {
        dropContext.select(DropMode.Add, {
          key: `${id}|${namespace}`,
          type: "Module",
          contextName: currentPage?.contextName
        });
        break;
      }

      default:
        break;
    }
  };

  const showSearch = type === "layouts" || type === "modules";

  return (
    <div className={rootClassName}>
      <div id="site-assets-list" className={listClassName}>
        <SiteAssetSidebarHeader
          title={translate(header.title)}
          description={translate(header.description)}
          onClose={onClose}
        />
        {showSearch && (
          <SiteAssetSearch
            type={type}
            onSearch={(searched) => setSearch(searched)}
          />
        )}
        {type && (
          <SiteAssetSelectList
            assets={assets}
            type={type}
            onSelect={onSiteAssetSelect}
          />
        )}
      </div>
      <div id="site-assets-list-buttons" className={classes.buttonArea}>
        <SiteAssetSelectButton
          onClick={() => onClick("templates")}
          selected={type === "templates"}
          icon="auto_awesome_mosaic"
          label={translate(
            "sitestructure.site_asset_sidebar.button_label.template"
          )}
        />
        <hr className={classes.buttonSeparator} />
        <SiteAssetSelectButton
          onClick={() => onClick("layouts")}
          selected={type === "layouts"}
          icon="view_day"
          label={translate(
            "sitestructure.site_asset_sidebar.button_label.layout"
          )}
        />
        <hr className={classes.buttonSeparator} />
        <SiteAssetSelectButton
          onClick={() => onClick("modules")}
          selected={type === "modules"}
          icon="view_in_ar"
          label={translate(
            "sitestructure.site_asset_sidebar.button_label.module"
          )}
        />
        <hr className={classes.buttonSeparator} />

        {shouldRenderAddRichTextControl && (
          <>
            <SiteAssetSelectButton
              selected={isRichTextModuleSelected}
              onClick={onSelectRichTextModule}
              icon="text_fields"
              label="Add Text"
            />
            <hr className={classes.buttonSeparator} />
          </>
        )}

        <div className={classes.hack} />
      </div>
    </div>
  );
};

export default SiteAssetSelectSidebar;
