import { call, put, select } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  fetchPageSucceded,
  fetchPageFailed,
  checkUrlTranslationDuplicates
} from "redux/actions/siteStructureActions";
import { PagesApi } from "api/pagesApi";
import { IDomainState } from "models/domainStates";
import { IPage } from "models/pages";
import { ISiteItem } from "models/siteItem";
import { setIsReadyToScrollIntoElement } from "redux/actions/scrollManagementActions";

export function* getPage(action: Action<string>) {
  try {
    const page: IPage = yield call(PagesApi.getPage, action.payload);

    const siteItems = yield select(
      (state: IDomainState) => state.siteStructure.siteItems
    );
    const current = Object.values<ISiteItem>(siteItems).find(
      (item) => item.nodeId === page.id
    );

    if (current) {
      yield put(
        checkUrlTranslationDuplicates({
          siteItems,
          currentSiteItem: current
        })
      );
    }

    yield put(fetchPageSucceded(page));
    yield put(setIsReadyToScrollIntoElement(true));
  } catch (error) {
    yield put(fetchPageFailed(error));
  }
}
