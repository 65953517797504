import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  unsetModal: {
    "& .MuiPaper-root": {
      width: theme.spacing(55.5), // 444px
      height: theme.spacing(35) // 180px
    }
  }
}));
