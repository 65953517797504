import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2.5, 3)
  },
  main: {
    flexGrow: 1
  },
  button: {
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  title: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24)
  },
  description: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr700
  }
}));
