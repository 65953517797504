import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { ActionSnackbar } from "components/shared/actionSnackbar";
import { DropMode } from "./dropPageElementContext";
import { ElementIdentifier } from "../index";
import { getIsRichTextModuleSelected } from "components/richText/utils";

interface IProps extends IBaseProps {
  onCancel: () => void;
  elementIdentifier: ElementIdentifier;
  mode: DropMode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& strong": {
      margin: theme.spacing(0, 1)
    }
  }
}));

const icons = {
  add: "add_circle_outline",
  add_rich_text: "lightbulb_outline",
  move: "zoom_out_map",
  copy: "content_copy"
};

export const DropPageElementSnackbar = (props: IProps) => {
  const { className, mode, elementIdentifier, onCancel } = props;
  const { type: elementType } = elementIdentifier;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const isRichTextModule = getIsRichTextModuleSelected(elementIdentifier);

  const snackbarMessage = isRichTextModule
    ? translate(`sitestructure.snackbar.add_rich_text`)
    : translate(`sitestructure.snackbar.${DropMode[mode]}`, {
        asset: translate(
          `sitestructure.${elementType.toLowerCase()}`
        ).toLowerCase()
      });

  const icon = isRichTextModule ? icons.add_rich_text : icons[mode];

  return (
    <ActionSnackbar
      className={rootClassName}
      show={mode !== DropMode.None}
      icon={icon}
      message={snackbarMessage}
      onCancel={() => onCancel()}
    />
  );
};
