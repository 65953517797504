import { useMemo } from "react";

import {
  SetModuleTranslatableProperties,
  ISetModuleTranslatableProperties,
  IUnsetModuleTranslatableProperty,
  UnsetModuleTranslatableProperty
} from "@d3-forge/forge-commands";
import { useCommand } from "api/queryHooks";
import {
  IRichTextCrudLoaders,
  ISetModuleTranslatablePropertiesPayload,
  IUnsetModuleTranslatablePropertyPayload
} from "models/richText";

export const useRichTextCommands = () => {
  const setModuleTranslatablePropertiesCommand = useCommand(
    "set-module-translatable-properties",
    {
      errorMessage:
        "notification.richtext.set_module_translatable_properties.error",
      successMessage:
        "notification.richtext.set_module_translatable_properties.success"
    }
  );

  const unsetModuleTranslatablePropertyCommand = useCommand(
    "set-module-translatable-properties",
    {
      errorMessage:
        "notification.richtext.unset_module_translatable_property.error",
      successMessage:
        "notification.richtext.unset_module_translatable_property.success"
    }
  );

  const loaders: IRichTextCrudLoaders = useMemo(
    () => ({
      isSettingModuleTranslatableProperties:
        setModuleTranslatablePropertiesCommand.isLoading,
      isUnsettingModuleTranslatableProperty:
        unsetModuleTranslatablePropertyCommand.isLoading
    }),
    [
      setModuleTranslatablePropertiesCommand.isLoading,
      unsetModuleTranslatablePropertyCommand.isLoading
    ]
  );

  const setModuleTranslatableProperties = async (
    payload: ISetModuleTranslatablePropertiesPayload
  ) => {
    const {
      pageId,
      instanceId: moduleInstanceId,
      translatableProperties
    } = payload;

    const commandBody: ISetModuleTranslatableProperties = {
      moduleInstanceId,
      pageId,
      translatableProperties
    };

    const command = new SetModuleTranslatableProperties(commandBody);

    try {
      const result = await setModuleTranslatablePropertiesCommand.mutateAsync(
        command
      );
      if (!result.success) {
        return true;
      }
    } catch (err) {
      console.log("Error:", err);
      return false;
    }
    return true;
  };

  const unsetModuleTranslatableProperties = async (
    payload: IUnsetModuleTranslatablePropertyPayload
  ) => {
    const { pageId, instanceId, culture, propertyName } = payload;

    const commandBody: IUnsetModuleTranslatableProperty = {
      pageId,
      culture,
      propertyName,
      moduleInstanceId: instanceId
    };

    const command = new UnsetModuleTranslatableProperty(commandBody);

    try {
      const result = await unsetModuleTranslatablePropertyCommand.mutateAsync(
        command
      );
      if (!result.success) {
        return true;
      }
    } catch (err) {
      console.log("Error:", err);
      return false;
    }
    return true;
  };

  return {
    loaders,
    setModuleTranslatableProperties,
    unsetModuleTranslatableProperties
  };
};
