import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    overflowX: "clip",
    width: "100%",
    "& .MuiButton-label": {
      justifyContent: "unset"
    }
  },
  tile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer"
  },
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    width: "100%",
    justifyContent: "space-between",
    gap: theme.spacing(1)
  },
  label: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    width: "100%",
    textAlign: "left",
    marginLeft: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr800,
    wordBreak: "initial",
    lineHeight: theme.typography.pxToRem(18)
  },
  icon: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord500
  }
}));
