import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IRow } from "models/pages";
import { CellContent } from "./cellContent";

interface IProps extends IBaseProps {
  rows: IRow[];
  layout?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row"
  }
}));

export const RowList = (props: IProps) => {
  const { className, rows, layout } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className={classes.row}>
          {row.cells.map((cell, cellIndex) => (
            <CellContent
              key={`${rowIndex}-${cellIndex}`}
              width={cell.width}
              content={cell.content}
              layout={layout}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
