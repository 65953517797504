import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import {
  Badge,
  Button,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";

import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";

import { contextualMenu } from "theme/commonClasses";
import { useUrlsAndAliasesContext } from "components/siteStructure/aliases/context";
import { PermissionCodes } from "catalogs/permissionCodes";
import { userService } from "services/userService";
import { setSiteStructureOperationExecuted } from "redux/actions/siteStructureActions";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    minWidth: "unset",
    padding: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr700,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  icon: {
    fontSize: theme.spacing(2),
    color: theme.palette.custom.ds.viking.viking500
  },
  active: {
    color: theme.palette.custom.ds.viking.viking500
  },
  contextualMenu
}));

export const AdvancedSettings = () => {
  const classes = useStyles();
  const { toggleOverlayPortal: openUrlAndAliasesPortal, hasAliasesSet } =
    useUrlsAndAliasesContext();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setSiteStructureOperationExecuted("ADVANCED_SETTINGS_EXECUTED"));
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  const menuButtonClassName = classnames(classes.menuButton, {
    [classes.active]: anchorEl
  });

  const menuItems = [
    {
      id: "urls_and_aliases",
      iconName: "link",
      label: "sitestructure.urlssection.contextual_menu.items.urls_and_aliases",
      isDisabled: !userService.hasPermissions(
        PermissionCodes.ViewUrlTranslations
      ),
      onClick: openUrlAndAliasesPortal
    }
  ];

  return (
    <>
      <Tooltip
        title={translate("sitestructure.urlssection.contextual_menu")}
        arrow={true}
      >
        <Button
          className={menuButtonClassName}
          size="small"
          onClick={menuClick}
        >
          <Badge badgeContent="A" color="primary" invisible={!hasAliasesSet}>
            <UxdIcon name="settings" />
          </Badge>
        </Button>
      </Tooltip>
      <Menu
        className={classes.contextualMenu}
        open={Boolean(anchorEl)}
        onClick={menuClose}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        keepMounted
        onClose={menuClose}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            onClick={menuItem.onClick}
            disabled={menuItem.isDisabled}
          >
            <ListItemIcon>
              <UxdIcon className={classes.icon} name={menuItem.iconName} />
            </ListItemIcon>
            {translate(menuItem.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
