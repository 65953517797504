import TurndownService from "turndown";
import { marked } from "marked";

export class MarkdownHtmlConverter {
  private static turndownService = new TurndownService({
    headingStyle: "atx",
    emDelimiter: "*"
  })
    .addRule("link", {
      filter: (node, _) =>
        node.nodeName.toLowerCase() === "a" && !!node.getAttribute("target"),
      replacement: (content, node, _) => {
        const href = node["href"];
        const target = node["target"];
        const title = node["title"];

        const hrefAttribute = href ? ` href="${this.normalizeUrl(href)}"` : "";
        const targetAttribute = target ? ` target="${target}"` : "";
        const titleAttribute = title ? ` title="${title}"` : "";

        return `<a${hrefAttribute}${targetAttribute}${titleAttribute}>${content}</a>`;
      }
    })
    .addRule("strikethrough", {
      // specific tinyMCE strikethrough rendered as span
      filter: ["s"],
      replacement: (content) => "<s>" + content + "</s>"
    })
    .addRule("sup", {
      // specific tinyMCE superscript rule
      filter: ["sup"],
      replacement: (content) => "<sup>" + content + "</sup>"
    })
    .addRule("sub", {
      // specific tinyMCE subscript rule
      filter: ["sub"],
      replacement: (content) => "<sub>" + content + "</sub>"
    })
    .addRule("orderedList", {
      filter: "li",
      replacement: function (content, node, options) {
        content = content
          .replace(/^\n+/, "") // remove leading newlines
          .replace(/(\r?\n)+$/, "\n") // replace trailing newlines with just a single one
          .replace(/\n/gm, "\n    "); // indent
        let prefix = options.bulletListMarker + "   ";
        const parent = node.parentNode;
        if (parent!.nodeName === "OL") {
          const start = (parent! as any).getAttribute("start");
          // this is the change! we exclude empty li items, to have a correct count!
          const parentChildrensNotEmpty = Array.from(parent!.children).filter(
            (n: any) => !!n.textContent?.trim()
          );
          const index = Array.prototype.indexOf.call(
            parentChildrensNotEmpty,
            node
          );
          prefix = (start ? Number(start) + index : index + 1) + ".  ";
        }
        return (
          prefix +
          content +
          (node.nextSibling && !content.endsWith("\n") ? "\n" : "")
        );
      }
    });

  public static readonly normalizeUrl = (url: string) => {
    try {
      const uri = new URL(url);
      return uri.toString();
    } catch (error) {
      console.warn(`Failed to normalize url '${url}'`);
      return url;
    }
  };

  public static readonly convertHtmlToMarkdown = (html: string) => {
    const markdown = this.turndownService.turndown(html);
    return MarkdownHtmlConverter.keepAngleBrackets(markdown);
  };

  public static readonly convertMarkdownToHtml = (markdown: string) => {
    return Promise.resolve(marked(markdown || "", { breaks: true }));
  };

  private static keepAngleBrackets = (markdown: string) => {
    const htmlTags = [...markdown.matchAll(/<[^>]*>/g)];

    const allowedTags = [
      "<a>",
      "<abbr>",
      "<acronym>",
      "<address>",
      "<area>",
      "<article>",
      "<aside>",
      "<b>",
      "<bdi>",
      "<big>",
      "<blockquote>",
      "<br>",
      "<button>",
      "<caption>",
      "<center>",
      "<cite>",
      "<code>",
      "<col>",
      "<colgroup>",
      "<data>",
      "<datalist>",
      "<dd>",
      "<del>",
      "<details>",
      "<dfn>",
      "<dir>",
      "<div>",
      "<dl>",
      "<dt>",
      "<em>",
      "<fieldset>",
      "<figcaption>",
      "<figure>",
      "<font>",
      "<footer>",
      "<form>",
      "<h1>",
      "<h2>",
      "<h3>",
      "<h4>",
      "<h5>",
      "<h6>",
      "<header>",
      "<hr>",
      "<i>",
      "<img>",
      "<input>",
      "<ins>",
      "<kbd>",
      "<keygen>",
      "<label>",
      "<legend>",
      "<li>",
      "<main>",
      "<map>",
      "<mark>",
      "<menu>",
      "<menuitem>",
      "<meter>",
      "<nav>",
      "<ol>",
      "<optgroup>",
      "<option>",
      "<output>",
      "<p>",
      "<pre>",
      "<progress>",
      "<q>",
      "<rp>",
      "<rt>",
      "<ruby>",
      "<s>",
      "<samp>",
      "<section>",
      "<select>",
      "<small>",
      "<span>",
      "<strike>",
      "<strong>",
      "<sub>",
      "<summary>",
      "<sup>",
      "<table>",
      "<tbody>",
      "<td>",
      "<textarea>",
      "<tfoot>",
      "<th>",
      "<thead>",
      "<time>",
      "<tr>",
      "<tt>",
      "<u>",
      "<ul>",
      "<var>",
      "<wbr>"
    ];

    htmlTags.forEach((tag) => {
      const stringTag = tag.toLocaleString();

      // skip encoding '<' and '>' characters if the tag is one of the allowed tags
      // auto closing tags are encoded (e.g. '<br/>')
      if (allowedTags.includes(stringTag)) return;

      if (
        !stringTag.startsWith("</") &&
        !stringTag.startsWith("<a ") &&
        !stringTag.startsWith("<s>") &&
        !stringTag.startsWith("<sub") &&
        !stringTag.startsWith("<sup")
      ) {
        const replacedTag = stringTag.replace("<", "&lt;").replace(">", "&gt;");
        markdown = markdown.replace(stringTag, replacedTag);
      }
    });

    const htmlNotClosingTags = [
      ...markdown.matchAll(/<[^><]+((?=<)|(?!.*>))/g)
    ];
    htmlNotClosingTags.forEach((tag) => {
      let stringTag = tag.toLocaleString();
      stringTag = stringTag.substring(0, stringTag.length - 1);
      const replacedTag = stringTag.replace("<", "&lt;");
      markdown = markdown.replace(stringTag, replacedTag);
    });
    return markdown;
  };
}
