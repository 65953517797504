import React, { useMemo } from "react";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { Button, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "./styles";
import { PageElementIcon } from "components/siteStructure/pageView/pageElements/pageElementIcon";
import { Hint } from "components/shared/hint";
import classnames from "classnames";

export interface ISiteAssetListItemProps extends IBaseProps {
  asset: IModuleDefinition;
  showHighlight?: boolean;
  highlightRegex?: RegExp;
  onSelect: (id: string, namespace: string) => void;
}

export const SiteAssetListItem = (props: ISiteAssetListItemProps) => {
  const {
    asset,
    showHighlight = false,
    highlightRegex = /^$/,
    className,
    onSelect
  } = props;
  const classes = useStyles();

  const { label, description } = asset;

  // split label in highligh-able parts and apply highlight to appropriate part
  const labelParts = useMemo(
    () =>
      label.split(highlightRegex).map((part, index) => {
        const shouldHighlightPart = showHighlight && highlightRegex.test(part);
        const highlighted = <mark key={part}>{part}</mark>;

        return shouldHighlightPart ? highlighted : part;
      }),
    [highlightRegex, label, showHighlight]
  );

  function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    onSelect(asset.id, asset.namespace);
  }

  return (
    <Button className={classnames(className, classes.root)} onClick={onClick}>
      <div className={classes.tile}>
        <PageElementIcon
          elementType="Module"
          pageElementDefinition={asset}
          size="small"
          className={classes.icon}
        />
        <div className={classes.container}>
          <Typography variant="subtitle2" className={classes.label}>
            {labelParts.map((part, i) => part)}
          </Typography>
          {description && <Hint hint={description ?? ""} noSpacing={true} />}
        </div>
      </div>
    </Button>
  );
};

export default SiteAssetListItem;
