import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";

// styles
import { useStyles } from "./styles";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { CopyMode } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext/copyPageElementContext";

interface IProps extends IBaseProps {
  slot: string;
  containerId: string;
  position: number;
  visible: boolean;
}

export const DestinationSlotArea = (props: IProps) => {
  const { slot, containerId, position, visible } = props;
  const classes = useStyles();

  const rootClassNames = classnames(classes.root, {
    [classes.hidden]: !visible
  });

  const [currentMode, setCurrentMode] = useState<DropMode | CopyMode>(
    DropMode.None
  );

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);

  useEffect(() => {
    const mode =
      dropContext.dropMode !== DropMode.None
        ? dropContext.dropMode
        : copyContext.copyMode;

    setCurrentMode(mode);
  }, [dropContext.dropMode, copyContext.copyMode]);

  const onClick = (event: any) => {
    event.stopPropagation();

    const DropModeConditionActionMap: Record<DropMode, () => void> = {
      [DropMode.Add]: () => dropContext.add(containerId, slot, position),
      [DropMode.Move]: () => dropContext.move(containerId, slot, position),
      [DropMode.Copy]: () => copyContext.copy(containerId, slot, position),
      [DropMode.None]: () => undefined
    };

    DropModeConditionActionMap[currentMode]();
  };

  return (
    <button
      className={rootClassNames}
      onClick={onClick}
      onKeyPress={onClick}
      tabIndex={0}
      style={{ outline: "none" }}
    />
  );
};
