import { DirectoryDetail } from "components/siteStructure/directoryView/directoryDetail";
import PageDetail from "components/siteStructure/pageView";
import MenuDetail from "components/siteStructure/menuView";

import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";
import { RichTextModal } from "components/richText/components/addRichTextModal";
import { RichTextManagementContextProvider } from "components/richText/context";
import { useSelector } from "react-redux";
import { selectIsRichTextModalOpened } from "redux/selectors/richTextSelectors";

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
  isLoading: boolean;
}

const NodeTypeComponentMap: Record<SiteNodeType, React.ElementType> = {
  Directory: DirectoryDetail,
  Page: PageDetail,
  Menu: MenuDetail
};

export function ContentView(props: IProps) {
  const { currentSiteItem, isLoading } = props;

  const isAddRichTextModalOpened = useSelector(selectIsRichTextModalOpened);

  const { nodeType } = currentSiteItem;
  const nodeDetailProps = { node: currentSiteItem, isLoading };

  const DetailComponent = NodeTypeComponentMap[nodeType];

  const shouldRenderAddRichTextModal =
    isAddRichTextModalOpened && nodeType === SiteNodeTypes.PAGE;

  return (
    <>
      <DetailComponent {...nodeDetailProps} />
      {shouldRenderAddRichTextModal && (
        <RichTextManagementContextProvider>
          <RichTextModal />
        </RichTextManagementContextProvider>
      )}
    </>
  );
}
