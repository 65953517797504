import { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { useSelector, useDispatch } from "react-redux";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import TemplateGridItem from "components/siteStructure/template";
import { changePageTemplate } from "redux/actions/siteStructureActions";
import { DialogBase } from "components/shared/dialogBase";
import { selectTemplatesCatalog } from "redux/selectors/assetCatalogSelectors";
import { IDomainState } from "models/domainStates";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";

interface IProps extends IBaseProps {
  pageId: string;
  pageContext: string;
  isOpen: boolean;
  selectedTemplateKey: string;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  templateTile: {
    marginTop: theme.spacing(1)
  }
}));

export const ChangeTemplateModal = (props: IProps) => {
  const {
    className,
    pageId,
    pageContext,
    isOpen: open,
    selectedTemplateKey,
    onClose
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const templates: ITemplateDefinition[] = useSelector((state: IDomainState) =>
    Object.values(selectTemplatesCatalog(state, pageContext))
  );

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(selectedTemplateKey);

  useEffect(() => {
    setSelected(selectedTemplateKey);
  }, [selectedTemplateKey]);

  const onTemplateSelected = useCallback(
    (id: string, namespace: string) => {
      setSelected(`${id}|${namespace}`);

      dispatch(
        changePageTemplate({
          pageId,
          template: {
            id,
            namespace
          }
        })
      );

      onClose();
    },
    [setSelected, onClose, dispatch, pageId]
  );

  return (
    <DialogBase
      className={rootClassName}
      title={translate("sitestructure.selectTemplate")}
      open={open}
      onClose={onClose}
    >
      <GridList cellHeight={"auto"} cols={5} spacing={10}>
        {templates.map((template) => (
          <GridListTile
            key={template.key}
            cols={1}
            className={classes.templateTile}
          >
            <TemplateGridItem
              template={template}
              selected={selected === template.key}
              onSelect={onTemplateSelected}
            />
          </GridListTile>
        ))}
      </GridList>
    </DialogBase>
  );
};
