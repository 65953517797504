import { call, put } from "redux-saga/effects";
import {
  fetchFrontendCulturesSucceeded,
  fetchFrontendCulturesFailed
} from "redux/actions/frontendActions";
import { FrontendApi } from "api/frontendApi";

export function* getFrontendCultures() {
  try {
    const sites = yield call(FrontendApi.getCultures);
    yield put(fetchFrontendCulturesSucceeded(sites));
  } catch (error) {
    yield put(fetchFrontendCulturesFailed(error));
  }
}
