import { ThemeOptions } from "@material-ui/core";

// needs to be imported even if not used in order to override theme for @material-ui/lab components
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { LabComponentNameToClassKey } from "@material-ui/lab/themeAugmentation"; //NOSONAR

import createSpacing from "@material-ui/core/styles/createSpacing";
import { common } from "@material-ui/core/colors";

// color palette here: https://projects.invisionapp.com/share/FKWOJUKWGQV#/screens/422417387
const mainAccentRed = "#f75258";
const mainAccentBlue = "#5371f7";
const tiffany = "#53cedc";

const customPalette = {
  brandBlues: {
    d3Blue: "#212553",
    d3BlueLighter: "#383d8c"
  },
  mainAccent: {
    red: mainAccentRed,
    redHover: "#ff383f",
    blue: mainAccentBlue,
    blueHover: "#7b93ff",
    lightHover: "#e1f3f4"
  },
  secondaryAccent: {
    purple: "#685DC8",
    tiffany: tiffany,
    yellow: "#fcba26",
    green: "#54cc52",
    orange: "#f55e3f",
    selected: "#5371f7"
  },
  other: {
    lightLilla: "#bdc4dd",
    lightCyan: "#ebfdff"
  },
  greyscales: {
    backgrounds: {
      darkGrey: "#212121",
      grey60: "#999999",
      grey64: "#a5a5a5",
      grey93: "#eeeeee",
      grey95: "#f3f2f1",
      grey97: "#f7f7f7",
      grey98: "#f9f8f8",
      grey99: "#fcfcfc"
    },
    bordersAndSeparators: {
      lineDarker: "#d4d4d7",
      lineLight: "#d8d7e0",
      lineLightHover: "#c6c6d4",
      lineLighter: "#e9e9eb"
    },
    shadows: {
      sh300: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
      sh500: "rgba(0, 0, 0, 0.1)"
    }
  },
  icons: {
    inactive: "#b8b9d166", // opacity 40%
    noAction: "#989ab3",
    blueAccent: mainAccentBlue,
    blueMedium: "#7b93ff",
    blueLight: "#b8c6ff",
    red: mainAccentRed,
    highlight: tiffany,
    lightGrey: "#cecfd1"
  },
  viking: {
    viking100: "#EEFAFB",
    viking300: "#A9E7EE",
    viking500: "#53CEDC",
    viking700: "#47AFBB",
    viking900: "#3E9BA5"
  },
  bahama: {
    bahama100: "#E8F2F7",
    bahama300: "#BBD8E8",
    bahama500: "#1C7CB2",
    bahama700: "#155D85",
    bahama900: "#0F4462"
  },
  text: {
    // will be reworked when text palette will come from UXD
    header: "#6c6694",
    caption: "#525360",
    content: "#929AA0"
  },
  stages: {
    unpublished: "#F55E3F",
    reviewed: "#FCBA26",
    published: "#54CC52",
    publishedLight: "#e6f8e5"
  },
  warning: {
    lightest: "#fff8e9",
    light: "#feeabe",
    main: "#fcba26",
    dark: "#d69e20"
  },
  error: {
    background: "#feefec"
  },
  ds: {
    viking: {
      viking100: "#EEFAFB",
      viking300: "#A9E7EE",
      viking500: "#53CEDC",
      viking700: "#47AFBB",
      viking900: "#3E9BA5"
    },
    bahama: {
      bahama100: "#E8F2F7",
      bahama300: "#BBD8E8",
      bahama500: "#1C7CB2",
      bahama700: "#155D85",
      bahama900: "#0F4462"
    },
    grayscales: {
      gr200: "#F8F8F8",
      gr300: "#EAEBEC",
      gr400: "#D3D7D9",
      gr500: "#B8BEC2",
      gr600: "#929AA0",
      gr700: "#5F676D",
      gr800: "#303336",
      gr900: "#0F1011"
    },
    warning: {
      warning100: "#FFF8E9",
      warning300: "#FEEABE",
      warning500: "#FCBA26",
      warning700: "#D69E20"
    },
    fiord: {
      // Fiord
      fiord100: "#f4f4f6",
      fiord300: "#d3d3dd",
      fiord500: "#4d5175",
      fiord700: "#212553",
      fiord900: "#1a1e42"
    },
    valencia: {
      // Valencia
      valencia100: "#feeeee",
      valencia300: "#f9868a",
      valencia500: "#f75258",
      valencia700: "#d2464b",
      valencia900: "#a13539"
    },
    success: {
      // Success
      success100: "#eefaee",
      success300: "#ccf0cb",
      success500: "#54cc52",
      success700: "#43a342"
    },
    error: {
      // Error
      error100: "#feefec",
      error300: "#fccfc5",
      error500: "#f55e3f",
      error700: "#c44b32"
    },
    info: {
      // Info
      info100: "#f6f8ff",
      info300: "#cbd4fd",
      info500: "#5371f7",
      info700: "#3a4fad"
    },
    shadow: {
      s100: "0px 1px 4px rgba(0, 0, 0, 0.1)",
      s300: "0px 2px 8px rgba(0, 0, 0, 0.1);",
      s400: "0px 2px 8px 0px #0000001A",
      s500: "0px 4px 16px rgba(0, 0, 0, 0.1);",
      s700: "0px 4px 24px rgba(0, 0, 0, 0.1);",
      s900: "0px 4px 40px rgba(0, 0, 0, 0.1);"
    },
    variables: {
      urivariable100: "#e4f8f4",
      urivariable500: "#00AE8F",
      keyvalue100: "rgba(28, 124, 178, 0.1)",
      keyvalue500: "#1C7CB2",
      dataitem100: "rgba(237, 158, 3, 0.1)",
      dataitem500: "#ED9E03",
      datalist100: "rgba(234, 107, 35, 0.1)",
      datalist500: "#EA6B23"
    }
  }
};

interface CustomPalette {
  custom: typeof customPalette;
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

const spacing = createSpacing(8);

/*
 * Site sizing
 */

export const themeSizing = {
  drawer: spacing(9), // 72px
  siteTree: spacing(42.5), // 340px
  rightPanel: spacing(55) // 440px,
};

export const mediaQueryBreakpoints = {
  smaller: `@media (max-width: 1100px)`
};

export const deltatreTheme: ThemeOptions = {
  spacing: spacing,
  palette: {
    primary: {
      main: customPalette.ds.fiord.fiord700,
      light: customPalette.ds.fiord.fiord300
    },
    secondary: {
      main: customPalette.ds.valencia.valencia700
    },
    custom: customPalette,
    text: {
      primary: customPalette.ds.grayscales.gr800,
      secondary: customPalette.ds.grayscales.gr600,
      disabled: customPalette.ds.grayscales.gr300,
      hint: customPalette.ds.grayscales.gr300
    }
  },
  typography: {
    fontFamily: "Rubik, Montserrat!important"
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: customPalette.brandBlues.d3BlueLighter
      }
    },
    MuiTreeItem: {
      root: {
        "& $content $label": {
          "& .MuiIconButton-root:hover": {
            backgroundColor: customPalette.ds.grayscales.gr300
          }
        },
        "&$selected": {
          "& > $content $label": {
            color: customPalette.ds.viking.viking500,
            backgroundColor: customPalette.ds.grayscales.gr400,
            "&:hover": {
              backgroundColor: customPalette.ds.grayscales.gr400
            },
            "& .MuiIconButton-root:hover": {
              backgroundColor: customPalette.ds.grayscales.gr400
            }
          }
        }
      },
      iconContainer: {
        color: customPalette.icons.noAction,
        marginRight: 0
      },
      content: {
        padding: spacing(0.25)
      },
      label: {
        color: customPalette.icons.noAction,
        position: "initial",
        "&:hover": {
          backgroundColor: customPalette.ds.grayscales.gr300
        }
      }
    },
    MuiInputBase: {
      root: {
        fontSize: "0.90rem",
        "&.Mui-disabled": {
          color: customPalette.ds.grayscales.gr500
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "2px",
        backgroundColor: common.white,
        color: customPalette.ds.grayscales.gr800,
        borderColor: customPalette.ds.grayscales.gr400,
        "&:hover $notchedOutline": {
          borderWidth: 1,
          borderColor: customPalette.ds.grayscales.gr500
        },
        "&$focused $notchedOutline": {
          borderColor: customPalette.ds.viking.viking300,
          borderWidth: 2
        },
        "&.Mui-disabled": {
          backgroundColor: customPalette.ds.grayscales.gr200,
          "& $notchedOutline": {
            borderColor: customPalette.ds.grayscales.gr300
          }
        }
      },
      notchedOutline: {
        borderColor: customPalette.greyscales.bordersAndSeparators.lineLighter
      },
      adornedStart: {
        paddingLeft: spacing(1)
      },
      adornedEnd: {
        paddingRight: spacing(1)
      }
    },
    MuiTableCell: {
      head: {
        lineHeight: 0,
        fontSize: "0.75rem",
        color: customPalette.text.header,
        fontWeight: 400
      },
      body: {
        padding: spacing(1)
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: spacing(4)
      }
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: customPalette.ds.viking.viking100
        }
      }
    },
    MuiDialogTitle: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: spacing(1, 1, 1, 2)
      }
    },
    MuiDialogContent: {
      root: {
        wordBreak: "break-all"
      }
    },
    MuiSkeleton: {
      root: {
        borderRadius: 0
      },
      text: {
        borderRadius: 0
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: customPalette.ds.grayscales.gr800,
        fontSize: "12px",
        fontWeight: 400
      },
      arrow: {
        color: customPalette.ds.grayscales.gr800
      }
    },
    MuiTextField: {
      root: {
        "&[hidden]": {
          display: "none"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontStyle: "italic",
        color: customPalette.ds.warning.warning700
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: spacing(1, 3, 2)
      }
    },
    MuiTypography: {
      root: {
        "& strong": {
          fontWeight: 500
        }
      }
    },
    MuiSnackbarContent: {
      message: {
        "& strong": {
          fontWeight: 500
        }
      },
      action: {
        "& .MuiButton-text": {
          color: `${customPalette.viking.viking500} !important`
        }
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: "2px"
      }
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: customPalette.viking.viking500,
        fontSize: "0.6rem",
        height: "14px",
        width: "14px",
        minWidth: "unset"
      }
    },
    MuiRadio: {
      root: {
        color: customPalette.ds.grayscales.gr500
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor: "rgb(238, 250, 251, 0.7)"
        },
        "&.Mui-checked": {
          color: customPalette.ds.viking.viking500,
          "&:hover": {
            backgroundColor: "rgb(238, 250, 251, 0.7) !important"
          }
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        "&.Mui-disabled": {
          color: customPalette.ds.grayscales.gr500
        }
      }
    }
  }
};
