import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(8),
    height: theme.spacing(9.25),
    borderRadius: 0,
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      alignContent: "center"
    },
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.common.white,
      "& .icon": {
        color: theme.palette.custom.ds.viking.viking500
      }
    }
  },
  buttonDefault: {
    borderLeft: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`,
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    "& .MuiButton-label .icon": {
      color: theme.palette.custom.ds.grayscales.gr600
    }
  },
  buttonActive: {
    borderRight: `${theme.spacing(0.375)}px solid ${
      theme.palette.custom.ds.viking.viking500
    }`,
    // balance the border to keep icon centered
    // total width is constrained so it will not be out of alignment
    // with other icons
    borderLeft: `${theme.spacing(0.375)}px solid ${theme.palette.common.white}`,
    backgroundColor: theme.palette.common.white,
    "& .MuiButton-label .icon": {
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  label: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(9),
    lineHeight: theme.typography.pxToRem(11),
    color: theme.palette.common.black,
    textAlign: "center",
    textTransform: "capitalize"
  }
}));
