import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { SiteStructureOperationExecutedPayload } from "redux/actions/typings/siteStructureActions";
import { setSiteStructureOperationExecuted } from "redux/actions/siteStructureActions";
import { waitNextTick } from "utils/javascriptUtils";

export function* resetSiteStructureOperationExecuted(
  action: Action<SiteStructureOperationExecutedPayload>
) {
  try {
    const { payload } = action;

    if (payload === undefined) {
      return;
    }

    const actionToResetOperationExecuted =
      setSiteStructureOperationExecuted(undefined);

    yield waitNextTick();
    yield put(actionToResetOperationExecuted);
  } catch (error) {
    console.error(error);
  }
}
