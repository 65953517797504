import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenuItem } from "redux/actions/siteStructureActions";
import { translate } from "utils/i18n";
import { Accordion } from "components/shared/accordion";
import { IBaseProps } from "components/_baseProps";
import { IMenu, IMenuItem } from "models/menus";
import { makeStyles } from "@material-ui/core";
import ModuleProperty from "components/siteStructure/pageView/pageSidebar/moduleProperties";
import { useMenuBuilderContext } from "components/siteStructure/menuView/context";
import { selectSelectedMenu } from "redux/selectors/siteStructureSelectors";
import { isTrimmedValueEmpty } from "utils/javascriptUtils";
import { IComponentProperty } from "models/componentProperty";
import {
  buildComponentProperty,
  buildItemProperties
} from "components/siteStructure/menuView/components/menuSidebar/componentPropertyBuilder";
import { isValidUrl } from "utils/pathUtils";

interface IMenuSidebarContentProps extends IBaseProps {
  menu: IMenu;
  item: IMenuItem;
  canEditProperties: boolean;
}

const useStyles = makeStyles((theme) => ({
  nameField: {
    padding: theme.spacing(0, 5, 3, 7.8)
  }
}));

const shouldShowMandatoryMessage = (
  name,
  value,
  nameProperty: IComponentProperty,
  properties: IComponentProperty[]
) => {
  const isPropertyMandatory =
    name === nameProperty.name
      ? nameProperty.mandatory
      : properties.find((p) => p.name === name)?.mandatory;

  return isPropertyMandatory && value === "";
};

export const MenuItemSidebarContent = (props: IMenuSidebarContentProps) => {
  const { menu, item, canEditProperties } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updateMenuItem } = useMenuBuilderContext();

  const nameProperty = buildComponentProperty({
    name: "name",
    canUseVariables: false
  });

  const properties = buildItemProperties();
  const categories = [...new Set(properties.map((x) => x.category))];
  const [updatedMenuItem, setUpdatedMenuItem] = useState(item);

  const [errorMessages, setErrorMessages] = useState({});
  const selectedMenu = useSelector(selectSelectedMenu);

  const hasDuplicateId = useCallback(
    (items: IMenuItem[], newId: string) => {
      for (let i of items) {
        if (i.id === newId && i.id !== item.id) return true;

        const childHasDuplicateId = hasDuplicateId(i.items, newId);
        if (childHasDuplicateId) return true;
      }

      return false;
    },
    [item.id]
  );

  const shouldShowErrorMessage = useCallback(
    (name, value) => {
      let errorMessage = "";

      if (name === "id") {
        const idAlreadyExists = hasDuplicateId(
          selectedMenu?.items ?? [],
          value
        );

        if (idAlreadyExists) {
          errorMessage = translate(
            "sitestructure.menu_sidebar.errors.duplicate_id"
          );
        }
      }

      if (name === "link") {
        const isValidLink = isValidUrl(value);

        if (!isValidLink) {
          errorMessage = translate(
            "sitestructure.moduleproperties.errors.invalid_url"
          );
        }
      }

      setErrorMessages({
        ...errorMessages,
        [name]: errorMessage
      });

      return errorMessage !== "";
    },
    [hasDuplicateId, setErrorMessages, errorMessages, selectedMenu?.items]
  );

  const canSave = useCallback(
    (name: string, value: string) => {
      const isValid =
        !shouldShowMandatoryMessage(name, value, nameProperty, properties) &&
        !isTrimmedValueEmpty(value) &&
        !shouldShowErrorMessage(name, value);

      return isValid;
    },
    [shouldShowErrorMessage, nameProperty, properties]
  );

  const setMenuItemProperty = useCallback(
    async (name, value) => {
      if (!canSave(name, value)) {
        return;
      }

      const updatedMenuItemEntry = { ...updatedMenuItem, [name]: value };

      setUpdatedMenuItem(updatedMenuItemEntry);

      await updateMenuItem(item.id, updatedMenuItemEntry);

      dispatch(setSelectedMenuItem(updatedMenuItemEntry));
    },
    [canSave, dispatch, item.id, updateMenuItem, updatedMenuItem]
  );

  return (
    <>
      <ModuleProperty
        key={`${menu.id}-${nameProperty.name}`}
        propertyDefinition={nameProperty}
        value={updatedMenuItem.name ?? ""}
        disabled={!canEditProperties}
        className={classes.nameField}
        onChange={setMenuItemProperty}
      />
      {categories.map((category) => (
        <Accordion
          key={category}
          label={translate(`sitestructure.menu_sidebar.headers.${category}`)}
        >
          {properties
            .filter((x) => x.category === category)
            .map((property) => (
              <ModuleProperty
                key={`${menu.id}-${property.name}`}
                propertyDefinition={property}
                value={updatedMenuItem[property.name]}
                disabled={!canEditProperties}
                onChange={setMenuItemProperty}
                errorMessage={errorMessages[property.name]}
              />
            ))}
        </Accordion>
      ))}
    </>
  );
};
