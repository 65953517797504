import { createSelector } from "reselect";
import { IDomainState } from "models/domainStates";
import { Directionality } from "components/shared/TinyMCEEditor/types";

export const selectFrontendSites = (state: IDomainState) =>
  state.frontend.sites;

export const selectFrontendCultures = (state: IDomainState) =>
  Array.from(new Set(state.frontend.sites.map((x) => x.culture)));

export const selectFrontendCultureDetails = (state: IDomainState) =>
  state.frontend.cultures;

const selectCultureCodeFromArgs = (_: IDomainState, cultureCode: string) =>
  cultureCode;

export const selectFrontendPlatforms = (state: IDomainState) =>
  Array.from(new Set(state.frontend.sites.map((x) => x.platform)));

export const selectFrontendEnvironments = (state: IDomainState) =>
  Array.from(new Set(state.frontend.sites.map((x) => x.environment)));

export const selectFrontendCultureCodes = (state: IDomainState) =>
  Array.from(
    new Set(state.frontend.cultures.map((cultureDetail) => cultureDetail.code))
  );

export const getLanguageName = (cultureDetails, cultureCode) => {
  const languageName =
    cultureDetails.find((culture) => culture.code === cultureCode)?.name ?? "";

  return languageName;
};

export const selectFrontendLanguageName = createSelector(
  selectFrontendCultureDetails,
  selectCultureCodeFromArgs,
  getLanguageName
);

export const selectFrontendCultureDirectionality = createSelector(
  selectFrontendCultureDetails,
  selectCultureCodeFromArgs,
  (cultureDetails, cultureCode) => {
    const cultureDirectionality: Directionality = cultureDetails.find(
      (culture) => culture.code === cultureCode
    )?.isRightToLeft
      ? "rtl"
      : "ltr";

    return cultureDirectionality;
  }
);
