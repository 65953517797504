import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  culture: {
    "& label": {
      color: theme.palette.custom.ds.grayscales.gr600
    }
  }
}));
