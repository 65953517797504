import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, filter, find, map, noop } from "lodash";
import {
  PageLinkRuleAddedNotification,
  LinkRuleUrlLinkedNotification,
  LinkRuleDisabledNotification,
  PageLinkRuleDisabledNotification
} from "@d3-forge/forge-notifications";
import { useContextValue, useNotifications } from "components/hooks";
import { ILinkRuleEntry, ILinkRuleProperty } from "../../../models/linkRules";
import {
  selectLinkRulesEntityList,
  selectSelectedLinkRule
} from "redux/selectors/linkRulesSelectors";
import {
  getUpdatedPropertyValue,
  initEmptyPropertyForm,
  initLinkRuleForm,
  getIsHasValueOperator,
  getValidationIsThereDuplicateConditions,
  getAllAttributesPerEntityType,
  getIsThereAnEmptyCondition,
  getIsThereAnEmptyMandatoryField,
  getEntityTypeByLinkRuleId,
  areThereFormChanges,
  onHandleBlockHistoryNavigation
} from "components/linkRules/utils";
import { ID3DropdownOption } from "components/shared/d3Components/d3Dropdown";
import { ID3AutoCompleteInputOption } from "components/shared/d3Components/d3AutoCompleteInput";
import { useLinkRuleCommands } from "components/linkRules/hooks/useLinkRuleCommands";
import {
  setSelectedLinkRuleId,
  loadLinkRulesEntityList
} from "redux/actions/linkRulesActions";
import { useGetLinkRulesList } from "api/queryHooks";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";
import { wait } from "utils/javascriptUtils";
import { useHistory } from "react-router-dom";
import { DASHBOARDS } from "routes/paths";
import { selectUser } from "redux/selectors/userSelectors";

// This is used in order to update the highlight mark for the newly created link rule entry item and then
// dismiss the highlight after 2.7seconds.
const HIGHLIGHT_EXPIRATION_TIME_MS = 2700;

type ButtonControlKey = "CANCEL" | "DELETE" | "SAVE";

interface IButtonControlState {
  isDisabled: boolean;
  isLoading: boolean;
  isHidden: boolean;
}

type IButtonsState = Record<ButtonControlKey, IButtonControlState>;

export interface ICancelEditingPayload {
  pendingLinkRuleToSelectId?: string;
  pendingUrlToRedirectTo?: string;
  mode?: "SELECTION" | "CANCEL" | "EXIT_SCREEN";
  isActive: boolean;
}

export interface INewLinkRulePayload {
  entryId: string;
  entityType: string;
  shouldApplyHighlight: boolean;
}

export type ValidationKey = "DUPLICATE_CONDITIONS" | "SOMETHING_ELSE";

export interface IValidationResult {
  validationKey: ValidationKey;
  isValid: boolean;
  payload: Record<string, any> | null;
}

export interface ILinkRulePropertyFormEntry extends Partial<ILinkRuleProperty> {
  id: string;
}

export interface ILinkRuleForm {
  id: string;
  entityType: string;
  url: string;
  pageId?: string;
  description?: string;
  properties: ILinkRulePropertyFormEntry[];
  isNew: boolean;
  isAbsolute: boolean;
}

export interface LinkRulesCrudManagementContextValue {
  form: ILinkRuleForm;
  buttonsControlState: IButtonsState;
  attributeOptions: ID3DropdownOption[];
  validationResult: IValidationResult | undefined;
  shouldShowCancelConfirmDialog: boolean;
  isNewItem: boolean;
  newLinkRulePayload: INewLinkRulePayload | undefined;
  selectedLinkRule: ILinkRuleEntry | undefined;
  onSave: () => void;
  onAddProperty: () => void;
  onRemoveProperty: (id: string) => void;
  onSelectNavigationRuleItem: (id: string) => void;
  onDelete: (id: string, isAbsolute: boolean) => Promise<void>;
  onExitEditConfirm: () => void;
  onExitEditCancel: () => void;
  onUpdateDescription: (description: string) => void;
  onEditCancel: () => void;
  onUpdatePropertyField: (
    id: string,
    key: keyof ILinkRulePropertyFormEntry,
    value: any
  ) => void;
  onUpdateFormField: (key: keyof ILinkRuleForm, value: any) => void;
}

export const initCancelEditingPayload = (): ICancelEditingPayload => ({
  isActive: false
});

export const getOkValidationResult = (
  validationKey: ValidationKey
): IValidationResult => ({
  validationKey,
  isValid: true,
  payload: {}
});

export const LinkRulesCrudManagementContext = createContext(
  {} as LinkRulesCrudManagementContextValue
);

export const LinkRulesCrudManagementContextProvider: React.FC = ({
  children
}) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(selectUser);
  const unblockHistoryRef = useRef<() => void>(noop);
  const history = useHistory();
  const [form, setForm] = useState<ILinkRuleForm>(initLinkRuleForm(undefined));
  const [cancelEditingPayload, setCancelEditingPayload] =
    useState<ICancelEditingPayload>(initCancelEditingPayload());
  const [validationResult, setValidationResult] = useState<
    IValidationResult | undefined
  >(undefined);

  const [newLinkRulePayload, setNewLinkRulePayload] = useState<
    INewLinkRulePayload | undefined
  >(undefined);

  const selectedLinkRule = useSelector(selectSelectedLinkRule);
  const linkRulesEntityList = useSelector(selectLinkRulesEntityList);

  const isUserAllowedToEditNavigationRules = userService.hasPermissions(
    PermissionCodes.EditNavigationRules
  );

  const linkRulesListRequest = useGetLinkRulesList();
  const { loaders, deleteLinkRule, createLinkRule, editLinkRule } =
    useLinkRuleCommands({ form });

  const isNewItem = useMemo(() => form.isNew, [form.isNew]);
  const areThereChanges = useMemo(() => {
    return areThereFormChanges(form, selectedLinkRule);
  }, [form, selectedLinkRule]);

  const shouldShowCancelConfirmDialog = useMemo(
    () => cancelEditingPayload.isActive,
    [cancelEditingPayload]
  );

  const buttonsControlState: IButtonsState = useMemo(() => {
    const isSaveDisabled =
      !userService.getIsUserAllowedToAddNavigationRules() ||
      loaders.isUpdatingLinkRuleProperties ||
      loaders.isCreatingLinkRule ||
      getIsThereAnEmptyMandatoryField(form) ||
      getIsThereAnEmptyCondition(form) ||
      !areThereChanges;

    const isDeleteDisabled =
      !isUserAllowedToEditNavigationRules || loaders.isDeletingLinkRule;

    const controlsState = {
      CANCEL: {
        isDisabled: false,
        isLoading: false,
        isHidden: false
      },
      DELETE: {
        isDisabled: isDeleteDisabled,
        isLoading: loaders.isDeletingLinkRule,
        isHidden: isNewItem
      },
      SAVE: {
        isDisabled: isSaveDisabled,
        isLoading:
          loaders.isUpdatingLinkRuleProperties || loaders.isCreatingLinkRule,
        isHidden: false
      }
    };

    return controlsState;
  }, [
    form,
    loaders,
    areThereChanges,
    isUserAllowedToEditNavigationRules,
    isNewItem
  ]);

  const attributeOptions: ID3AutoCompleteInputOption[] = useMemo(() => {
    const entityType = isNewItem
      ? form.entityType || undefined
      : selectedLinkRule?.entityType;

    return map(
      getAllAttributesPerEntityType(linkRulesEntityList, entityType),
      (attributeKey) => ({
        value: attributeKey,
        label: attributeKey
      })
    );
  }, [linkRulesEntityList, selectedLinkRule, form.entityType, isNewItem]);

  const onUpdateFormField = useCallback(
    (key: keyof ILinkRuleForm, value: any) => {
      setValidationResult(undefined);
      setForm((current) => ({ ...current, [key]: value }));
    },
    []
  );

  const onUpdatePropertyField = useCallback(
    (id: string, key: keyof ILinkRulePropertyFormEntry, value: any) => {
      const { properties } = form;

      const shouldClearPropertyValueField =
        key === "operator" && getIsHasValueOperator(value);

      const updatedProperties = map(properties, (property) =>
        property.id === id
          ? {
              ...property,
              value: getUpdatedPropertyValue(
                property,
                shouldClearPropertyValueField
              ),
              [key]: value
            }
          : property
      );

      onUpdateFormField("properties", updatedProperties);
    },
    [form, onUpdateFormField]
  );

  const onAddProperty = useCallback(() => {
    const { properties } = form;

    const updatedProperties = [...properties, initEmptyPropertyForm()];
    onUpdateFormField("properties", updatedProperties);
  }, [form, onUpdateFormField]);

  const onRemoveProperty = useCallback(
    (id: string) => {
      const { properties } = form;

      const updatedProperties = filter(
        properties,
        (property) => property.id !== id
      );

      onUpdateFormField("properties", updatedProperties);
    },
    [form, onUpdateFormField]
  );

  const onDelete = useCallback(
    async (id: string, isAbsolute: boolean) => {
      await deleteLinkRule(id, isAbsolute);
      dispatch(setSelectedLinkRuleId(null));
    },
    [deleteLinkRule, dispatch]
  );

  const onUpdateDescription = useCallback(
    (description: string) => {
      onUpdateFormField("description", description);
    },
    [onUpdateFormField]
  );
  const onReset = useCallback(() => {
    setForm(initLinkRuleForm(undefined));
    setValidationResult(undefined);
  }, []);

  const validateOnSave = useCallback((): boolean => {
    const validationChecks = [
      getValidationIsThereDuplicateConditions(
        form,
        form.entityType,
        linkRulesEntityList
      )
    ];

    const firstInvalidCheck = find(validationChecks, { isValid: false });

    setValidationResult(firstInvalidCheck);

    return isEmpty(firstInvalidCheck);
  }, [form, linkRulesEntityList]);

  const onSubmit = useCallback(async () => {
    const commandResult = isNewItem
      ? await createLinkRule(userId)
      : await editLinkRule();

    if (!commandResult) {
      return;
    }

    if (!isNewItem) {
      await linkRulesListRequest.refetch();
    }

    dispatch(setSelectedLinkRuleId(null));
    onReset();
  }, [
    userId,
    isNewItem,
    onReset,
    dispatch,
    createLinkRule,
    editLinkRule,
    linkRulesListRequest
  ]);

  const onSave = useCallback(() => {
    const validationStatus = validateOnSave();
    if (!validationStatus) {
      return false;
    }

    onSubmit();
  }, [validateOnSave, onSubmit]);

  const onSelectNavigationRuleItem = useCallback(
    (id: string) => {
      if (selectedLinkRule?.id === id) {
        return;
      }

      const isThereSelectedItemOrIsNewItem =
        selectedLinkRule !== undefined || isNewItem;
      const shouldDelegateSelection =
        isThereSelectedItemOrIsNewItem && areThereChanges;

      if (shouldDelegateSelection) {
        setCancelEditingPayload({
          isActive: true,
          mode: "SELECTION",
          pendingLinkRuleToSelectId: id
        });
        return;
      }

      dispatch(setSelectedLinkRuleId(id));
    },
    [dispatch, isNewItem, areThereChanges, selectedLinkRule]
  );

  const onLinkRuleEditExitCancel = useCallback((): void => {
    setCancelEditingPayload(initCancelEditingPayload()); // close modal
  }, []);

  const onLinkRuleEditExitConfirm = useCallback((): void => {
    const { pendingLinkRuleToSelectId, mode, pendingUrlToRedirectTo } =
      cancelEditingPayload;

    onReset(); // reset form & validation
    dispatch(setSelectedLinkRuleId(pendingLinkRuleToSelectId ?? null));
    setCancelEditingPayload(initCancelEditingPayload()); // close modal

    unblockHistoryRef.current();

    if (mode === "EXIT_SCREEN") {
      history.push(pendingUrlToRedirectTo ?? DASHBOARDS);
    }
  }, [cancelEditingPayload, history, dispatch, onReset]);

  const onEditCancel = useCallback(() => {
    if (!areThereChanges) {
      dispatch(setSelectedLinkRuleId(null));
      return;
    }

    setCancelEditingPayload({
      isActive: true,
      mode: "CANCEL"
    });
  }, [dispatch, areThereChanges]);

  const handleNewLinkRulePayload = useCallback(
    async (linkRuleId: string, entityType: string) => {
      const updatedNewLinkRulePayload = {
        entryId: linkRuleId,
        entityType,
        shouldApplyHighlight: true
      };

      setNewLinkRulePayload(updatedNewLinkRulePayload);

      await wait(HIGHLIGHT_EXPIRATION_TIME_MS);

      setNewLinkRulePayload({
        ...updatedNewLinkRulePayload,
        shouldApplyHighlight: false
      });
    },
    []
  );

  const handleLinkRulePathLinkedNotification = useCallback(
    async (
      ntf: PageLinkRuleAddedNotification | LinkRuleUrlLinkedNotification
    ) => {
      const { linkRuleId, commandId } = ntf;

      try {
        const result = await linkRulesListRequest.refetch();
        if (result.data === undefined) {
          throw new Error("Could not fetch link rule list");
        }

        const entityType = getEntityTypeByLinkRuleId(result.data, linkRuleId);

        // the same notification(s) are emitted when the rule has been created or the linked path has changed
        const isNew = selectedLinkRule?.id !== linkRuleId;

        /*
          This check is for making sure that the scrolling
          to the just created link rule happens only
          for the user that created the link rule in the first place.
        */

        const isLinkRuleCreatedByCurrentUser = userId === commandId;

        const shouldHandleNewLinkRuleFlow =
          isNew && isLinkRuleCreatedByCurrentUser;

        if (shouldHandleNewLinkRuleFlow) {
          handleNewLinkRulePayload(linkRuleId, entityType);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      linkRulesListRequest,
      selectedLinkRule?.id,
      userId,
      handleNewLinkRulePayload
    ]
  );

  const handLinkRuleDisabledNotification = useCallback(
    async (
      _ntf: LinkRuleDisabledNotification | PageLinkRuleDisabledNotification
    ) => {
      try {
        const result = await linkRulesListRequest.refetch();

        if (result.data === undefined) {
          throw new Error("No data");
        }

        dispatch(loadLinkRulesEntityList(result.data));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, linkRulesListRequest]
  );

  useNotifications(
    ["PageLinkRuleAddedNotification", "LinkRuleUrlLinkedNotification"],
    (ntf: PageLinkRuleAddedNotification | LinkRuleUrlLinkedNotification) =>
      handleLinkRulePathLinkedNotification(ntf),
    [handleLinkRulePathLinkedNotification]
  );

  useNotifications(
    ["LinkRuleDisabledNotification", "PageLinkRuleDisabledNotification"],
    (ntf: LinkRuleDisabledNotification | PageLinkRuleDisabledNotification) =>
      handLinkRuleDisabledNotification(ntf),
    [handLinkRuleDisabledNotification]
  );

  useEffect(() => {
    onReset();
    setForm(initLinkRuleForm(selectedLinkRule));
  }, [onReset, selectedLinkRule]);

  useEffect(() => {
    const shouldBlockNavigation = areThereChanges;

    if (!shouldBlockNavigation) {
      unblockHistoryRef.current();
      return;
    }

    unblockHistoryRef.current = history.block(
      (location) =>
        onHandleBlockHistoryNavigation(
          location as any,
          setCancelEditingPayload
        ) as any
    );

    return () => {
      unblockHistoryRef.current();
    };
  }, [history, areThereChanges]);

  const contextValue = useContextValue<LinkRulesCrudManagementContextValue>({
    form,
    selectedLinkRule,
    buttonsControlState,
    attributeOptions,
    validationResult,
    shouldShowCancelConfirmDialog,
    isNewItem,
    newLinkRulePayload,
    onSave,
    onEditCancel: onEditCancel,
    onSelectNavigationRuleItem,
    onUpdateFormField,
    onUpdatePropertyField,
    onAddProperty,
    onRemoveProperty,
    onUpdateDescription,
    onDelete,
    onExitEditCancel: onLinkRuleEditExitCancel,
    onExitEditConfirm: onLinkRuleEditExitConfirm
  });

  return (
    <LinkRulesCrudManagementContext.Provider value={contextValue}>
      {children}
    </LinkRulesCrudManagementContext.Provider>
  );
};

export const useLinkRulesCrudManagementContext =
  (): LinkRulesCrudManagementContextValue => {
    const context = useContext(LinkRulesCrudManagementContext);
    return context;
  };
