import { D3Modal } from "components/shared/d3Components";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useRichTextManagementContext } from "components/richText/context";

interface IUnsetModalProps {
  open: boolean;
  cultureCode: string;
  cultureName: string;
  onClose: () => void;
}

export const UnsetModal = (props: Readonly<IUnsetModalProps>) => {
  const classes = useStyles();
  const { onDeleteTranslation } = useRichTextManagementContext();
  const { open, cultureCode, cultureName, onClose } = props;

  const onConfirm = () => {
    onDeleteTranslation(cultureCode);
    onClose();
  };

  const prefix = "sitestructure.richtext_sidebar.modal.unset";
  const body = translate(`${prefix}.body`, { language: cultureName });

  return (
    <D3Modal
      className={classes.unsetModal}
      maxWidth="xl"
      modalTitle={translate(`${prefix}.title`)}
      cancelLabel={translate(`${prefix}.cancel`)}
      confirmLabel={translate(`${prefix}.confirm`)}
      onConfirm={onConfirm}
      onCancel={onClose}
      open={open}
    >
      <Typography>{body}</Typography>
    </D3Modal>
  );
};

export default UnsetModal;
