import { GridList } from "@material-ui/core";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";
import SiteAssetListTile from "../siteAssetListTile";
import SiteAssetListItem from "../siteAssetListItem";
import { IAssetDefinition } from "../index";
import { useStyles } from "./styles";
import { SiteAssetType } from "models/siteAssets/siteAssetTypes";

interface ISiteAssetSelectListProps {
  type: SiteAssetType;
  assets: ITemplateDefinition[] | ILayoutDefinition[] | IModuleDefinition[];
  onSelect: (id: string, namespace: string) => void;
}

export const SiteAssetSelectList = (props: ISiteAssetSelectListProps) => {
  const { assets, type, onSelect } = props;
  const classes = useStyles();

  if (type === "templates" || type === "layouts") {
    return (
      <GridList
        cellHeight={"auto"}
        cols={2}
        spacing={10}
        className={classes.grid}
      >
        {assets.map((asset) => (
          <SiteAssetListTile
            key={asset.key}
            asset={asset}
            onSelect={onSelect}
          />
        ))}
      </GridList>
    );
  }

  return (
    <div className={classes.list}>
      {assets.map((asset: IAssetDefinition) => (
        <SiteAssetListItem
          key={asset.key}
          className={classes.item}
          asset={asset as IModuleDefinition}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
};

export default SiteAssetSelectList;
