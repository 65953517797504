import { useStyles } from "./styles";
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";

export interface ISiteAssetSelectButtonProps {
  icon: string;
  label: string;
  selected?: boolean;
  onClick: () => void;
}

export const SiteAssetSelectButton = (props: ISiteAssetSelectButtonProps) => {
  const { icon, label, selected = false, onClick } = props;
  const classes = useStyles();

  const buttonClassName = classNames(
    classes.root,
    selected ? classes.buttonActive : classes.buttonDefault
  );

  return (
    <Button onClick={onClick} disableRipple={true} className={buttonClassName}>
      <UxdIcon name={icon} className="icon" />
      <Typography className={classes.label}>{label}</Typography>
    </Button>
  );
};

export default SiteAssetSelectButton;
