import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState
} from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import split from "lodash/split";
import filter from "lodash/filter";
import { IBaseProps } from "components/_baseProps";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from "@material-ui/lab";
import { IComponentProperty } from "models/componentProperty";
import {
  isExternalTagFolder,
  isTagDatasource,
  TagsPickerSuggestion
} from "models/forgeEntities";
import { translate } from "utils/i18n";
import { TagsPickerChipContainer } from "./tagsPickerChipContainer";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { userService } from "services/userService";
import { TagsPickerSuggestionItem } from "./tagPickerSuggestionItem";
import { useDispatch } from "react-redux";
import { ExternalTagHierarchy } from "./tagsPicker";
import { getLabel } from "./tagsPickerUtils";
import { useDebouncedState } from "components/hooks";
import { setTagsPickerProperty } from "redux/actions/tagsActions";
import { TagsPickerInput } from "./tagsPickerInput";
import {
  useExternalTagsQuery,
  useInternalTagsQuery,
  useTagDatasources
} from "./tagsPickerQueries";
import { PermissionCodes } from "catalogs/permissionCodes";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";
import { VARIABLE_PREFIX } from "utils/variableUtils";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    margin: theme.spacing(0.5)
  },
  list: {
    padding: 0,
    "& > *": {
      padding: 0,
      '&[aria-selected="true"],&[data-focus="true"],&:active': {
        backgroundColor: theme.palette.custom.mainAccent.lightHover,
        "& *": {
          backgroundColor: theme.palette.custom.mainAccent.lightHover
        }
      }
    }
  },
  listItem: {
    flex: 1
  },
  fetchError: {
    color: theme.palette.custom.mainAccent.red
  },
  textFieldArea: {
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    "& .MuiAutocomplete-root:has(~.MuiAutocomplete-root:not([hidden]))": {
      display: "none"
    },
    "&:has(.MuiAutocomplete-root ~ .MuiAutocomplete-root:not([hidden]))": {
      "&~$chip": {
        display: "none"
      }
    }
  }
}));

const validationRegex = /\w/gi;

export const TagsPickerProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    onChange = () => {},
    disabled = false,
    mandatory
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const dispatch = useDispatch();

  const finalDisabled =
    disabled || !userService.hasPermissions(PermissionCodes.ViewContent);

  const [isVariable, setIsVariable] = useState(
    value?.startsWith(VARIABLE_PREFIX)
  );

  const [hierarchy, setHierarchy] = useState<ExternalTagHierarchy>([]);

  const [open, setOpen] = useState(false);
  const [terms, debouncedTerms, setTerms] = useDebouncedState("", 1000);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const abortController = useMemo(() => new AbortController(), [hierarchy]);
  const tagSlugs = useMemo(
    () => (isVariable ? [] : filter(split(value, ","))),
    [isVariable, value]
  );

  const sources = useTagDatasources(terms);

  const {
    data: suggestions = [],
    error: internalQueryError,
    isLoading: isLoadingSuggestions,
    isFetching: isFetchingSuggestions
  } = useInternalTagsQuery(
    debouncedTerms,
    hierarchy.length === 0,
    abortController
  );

  const {
    data: externals = [],
    error: externalQueryError,
    isLoading: isLoadingExternal,
    isFetching: isFetchingExternal
  } = useExternalTagsQuery(
    hierarchy,
    debouncedTerms,
    hierarchy.length > 0,
    abortController
  );

  const handleTagSelect = (
    _: React.ChangeEvent<{}>,
    newValues: (string | TagsPickerSuggestion)[],
    reason: AutocompleteChangeReason
  ) => {
    const shouldHandleTagSelect =
      reason === "select-option" || reason === "create-option";

    if (!shouldHandleTagSelect) {
      return;
    }

    const selectedItem = newValues[newValues.length - 1];

    let slug: string;
    if (typeof selectedItem === "string") {
      slug = selectedItem;
    } else if (isTagDatasource(selectedItem)) {
      return setHierarchy([selectedItem]);
    } else if (
      selectedItem.source === "external" &&
      isExternalTagFolder(selectedItem)
    ) {
      const [source, ...others] = hierarchy;
      if (!source) {
        console.error("Inconsistency in hierarchy array", hierarchy);
        return;
      }
      return setHierarchy([source, ...others, selectedItem]);
    } else if (selectedItem.source !== "internal") {
      const action = setTagsPickerProperty({
        dataSourceId: selectedItem.dataSourceId,
        dataSourceName: selectedItem.dataSourceName,
        dataSourcePath:
          selectedItem.source === "external"
            ? selectedItem.dataSourcePath
            : null,
        extradata: selectedItem.extradata,
        isExternalTag: selectedItem.source === "external",
        title: selectedItem.title,
        slug: selectedItem.slug,
        label: selectedItem.label,
        onSuccess: (actualSlug: string) => {
          if (!isTagAlreadyIncluded(actualSlug)) {
            onChange(
              propertyDefinition.name,
              [...tagSlugs, actualSlug].join(",")
            );
          }
        }
      });

      setOpen(false);
      dispatch(action);
      return;
    } else {
      slug = selectedItem.slug;
    }

    if (!isTagAlreadyIncluded(slug)) {
      onChange(propertyDefinition.name, [...tagSlugs, slug].join(","));
    }

    setTerms("");
    setOpen(false);
  };

  const isTagAlreadyIncluded = useCallback(
    (slug: string): boolean => tagSlugs.includes(slug),
    [tagSlugs]
  );

  const handleRemoveTag = useCallback(
    (removed: string) => {
      const newValue = tagSlugs.filter((s) => s !== removed);
      onChange(propertyDefinition.name, newValue.join(","));
    },
    [propertyDefinition.name, tagSlugs, onChange]
  );

  const renderItem = (
    item: TagsPickerSuggestion,
    state: AutocompleteRenderOptionState
  ) => {
    const label = getLabel(item);

    // TODO: use this when update typescript version to 4.4.x
    // const isFolder = isTagDatasource(item) || isExternalTagFolder(item);

    return (
      <TagsPickerSuggestionItem
        key={label}
        className={classes.listItem}
        search={state.inputValue}
        stage={
          isTagDatasource(item) || isExternalTagFolder(item)
            ? undefined
            : item.stage
        }
        value={label}
        isFolder={isTagDatasource(item) || isExternalTagFolder(item)}
      />
    );
  };

  const queryError = internalQueryError || externalQueryError;
  const queryLoading =
    isLoadingSuggestions ||
    isFetchingSuggestions ||
    isLoadingExternal ||
    isFetchingExternal;

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TagsPickerInput
      hierarchy={hierarchy}
      loading={queryLoading}
      error={queryError ? translate("tagspicker.fetcherror") : ""}
      mandatory={mandatory}
      {...params}
    />
  );

  // Handle backspace to delete hierarchy segments
  const handleInputKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!terms && hierarchy.length && event.key === "Backspace") {
      abortController.abort();

      const newHierarchy = hierarchy.slice(0, -1) as ExternalTagHierarchy;
      setHierarchy(newHierarchy);
    }
  };

  const handleInputChange = (_: ChangeEvent<{}>, newValue: string) => {
    if (newValue && !newValue.match(validationRegex)) {
      return;
    }

    setTerms(newValue);
  };

  const handleOpenSuggestions = (event: ChangeEvent<{}>) => {
    if (queryError || event.type === "mousedown") {
      return;
    }

    setOpen(true);
  };

  const handleCloseSuggestion = (
    _: ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "select-option") {
      return;
    }

    setOpen(false);
  };

  const items: TagsPickerSuggestion[] =
    hierarchy.length > 0 ? externals : [...sources, ...suggestions];

  const onToggleVariableSwitchMode = useCallback(
    (isChecked: boolean) => {
      onChange(propertyDefinition.name, "");
      setHierarchy([]);
      setIsVariable(isChecked);
    },
    [onChange, propertyDefinition.name]
  );

  return (
    <div className={rootClassName}>
      <TextFieldLabel
        value={propertyDefinition.displayName || propertyDefinition.name}
        hint={propertyDefinition.description}
        disabled={disabled}
      />
      <div className={classes.textFieldArea}>
        <Autocomplete
          classes={{
            listbox: classes.list
          }}
          multiple={true}
          value={tagSlugs}
          size="small"
          open={open}
          freeSolo={true}
          options={items}
          noOptionsText={translate("tagspicker.noresults")}
          disableClearable={true}
          loading={false}
          onInputChange={handleInputChange}
          inputValue={terms}
          onOpen={handleOpenSuggestions}
          onClose={handleCloseSuggestion}
          onChange={handleTagSelect}
          onKeyDown={handleInputKeyDown}
          disabled={finalDisabled}
          getOptionLabel={(option) => getLabel(option)}
          renderOption={renderItem}
          openOnFocus={false}
          renderInput={renderInput}
          includeInputInList={true}
          renderTags={() => null}
          filterSelectedOptions={true}
          fullWidth
        />
        <VariableSwitch
          propertyName={propertyDefinition.name}
          propertyValue={value}
          propertyTypeName={propertyDefinition.typeName}
          disabled={disabled}
          onChange={onChange}
          onSwitch={onToggleVariableSwitchMode}
          mandatory={mandatory}
        />
      </div>
      {tagSlugs.map((s, i) => (
        <TagsPickerChipContainer
          key={s}
          className={classes.chip}
          slug={s}
          onRemoveClick={handleRemoveTag}
          disabled={finalDisabled}
        />
      ))}
    </div>
  );
};
