import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { AddLayoutToSlot } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { AddLayoutPayload } from "redux/actions/typings/siteStructureActions";
import {
  fetchPage,
  setLastTriggeredCommandId
} from "redux/actions/siteStructureActions";

export function* sendAddLayout(action: Action<AddLayoutPayload>) {
  try {
    const command = new AddLayoutToSlot(action.payload);
    const updateLastCommandIdAction = setLastTriggeredCommandId(
      command.bodyObject.commandId ?? ""
    );

    const commandAction = issueCommand({
      command,
      onSuccessAction: fetchPage(action.payload.pageId),
      successMessage: "notification.createlayout.success",
      errorMessage: "notification.createlayout.error",
      toggleNotification: true
    });

    yield put(commandAction);
    yield put(updateLastCommandIdAction);
  } catch (error) {
    console.error(error);
  }
}
