export function makeCancelablePromise<T>(
  promiseWrapper: (controller: AbortController) => Promise<T>,
  controller?: AbortController
) {
  const internalController = controller || new AbortController();
  const promise = promiseWrapper(internalController);

  (promise as any).cancel = () => internalController.abort();

  return promise;
}

export const isTrimmedValueEmpty = (value: string) =>
  value !== "" && value.trim() === "";

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const waitNextTick = () =>
  new Promise((resolve) => window.requestAnimationFrame(resolve));
