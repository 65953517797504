import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { fetchPage } from "redux/actions/siteStructureActions";
import { SetLayoutProperties } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { SetLayoutPropertyPayload } from "redux/actions/typings/siteStructureActions";
import { ModuleType } from "models/pages";

const MessagesMap: Partial<
  Record<ModuleType, { errorMessage: string; successMessage: string }>
> = {
  Template: {
    errorMessage: "notification.edittemplate.error",
    successMessage: "notification.edittemplate.success"
  },
  Layout: {
    errorMessage: "notification.editlayout.error",
    successMessage: "notification.editlayout.success"
  }
};

export function* editLayoutProperty(action: Action<SetLayoutPropertyPayload>) {
  try {
    const { pageId, layoutInstanceId, layoutKey, name, value, isTemplate } =
      action.payload;

    const command = new SetLayoutProperties({
      pageId,
      layoutInstanceId,
      layoutKey,
      properties: {
        [name]: value || "#NOSET#"
      }
    });

    const resultMessagesKey: ModuleType = isTemplate ? "Template" : "Layout";
    const resultMessages = MessagesMap[resultMessagesKey] ?? {};

    const commandAction = issueCommand({
      command,
      onFailureAction: fetchPage(pageId),
      ...resultMessages,
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
