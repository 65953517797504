import { ISiteItemWithParentPayload } from "models/siteItem";
import { createAction } from "redux-actions";

export const openDeleteModal = createAction(
  "OPEN_DELETE_SITE_ITEM",
  (payload: ISiteItemWithParentPayload) => payload
);

export const closeDeleteModal = createAction("CLOSE_DELETE_SITE_ITEM");

export const disposeDeleteSiteItemParentNode = createAction(
  "DISPOSE_DELETE_SITE_ITEM_PARENT_NODE"
);
