import classnames from "classnames";
import { makeStyles, Tooltip, Icon } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  hint: string;
  noSpacing?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.ds.grayscales.gr500,
    margin: theme.spacing(0, 0.5),
    fontSize: "20px"
  },
  noSpacing: {
    margin: "unset",
    padding: "unset"
  },
  icon: {
    fontSize: "20px"
  }
}));

export const Hint = (props: IProps) => {
  const { className, hint, noSpacing = false } = props;
  const classes = useStyles();

  const rootClassName = classnames(
    classes.root,
    {
      [classes.noSpacing]: noSpacing
    },
    className
  );

  return (
    <Tooltip
      title={hint}
      arrow={true}
      placement="top"
      enterTouchDelay={10}
      leaveTouchDelay={3000}
    >
      <Icon fontSize="small" className={rootClassName}>
        <UxdIcon name="info_outline" className={classes.icon} />
      </Icon>
    </Tooltip>
  );
};
