import { ChangeEvent, useState } from "react";
import classnames from "classnames";
import { makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IComponentProperty } from "models/componentProperty";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { useDebounce } from "components/hooks";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  textFieldArea: {
    display: "flex",
    flexDirection: "row",
    "& .MuiTextField-root:has(+.MuiAutocomplete-root:not([hidden]))": {
      display: "none"
    }
  }
}));

export const MultilineTextProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    disabled = false,
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [localValue, setLocalValue] = useState(value);
  const debouncedOnChange = useDebounce(onChange, 2500);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = target;
    if (localValue === newValue) {
      return;
    }

    setLocalValue(newValue);
    debouncedOnChange(propertyDefinition.name, newValue);
  };

  const handleBlur = () => {
    debouncedOnChange.flush();
  };

  const clearValueOnVariableSwitch = () => {
    setLocalValue("");
    debouncedOnChange(propertyDefinition.name, localValue);
  };

  return (
    <div className={rootClassName}>
      <TextFieldLabel
        value={propertyDefinition.displayName || propertyDefinition.name}
        hint={propertyDefinition.description}
        mandatory={propertyDefinition.mandatory}
        disabled={disabled}
      />
      <div className={classes.textFieldArea}>
        <TextField
          className={mandatory.showMessage ? mandatory.className : ""}
          fullWidth={true}
          value={localValue}
          variant="outlined"
          size="small"
          onChange={handleChange}
          onBlur={handleBlur}
          multiline
          disabled={disabled}
          helperText={mandatory.showMessage ? mandatory.message : ""}
        />
        <VariableSwitch
          propertyName={propertyDefinition.name}
          propertyValue={localValue}
          propertyTypeName={propertyDefinition.typeName}
          disabled={disabled}
          onChange={onChange}
          onSwitch={clearValueOnVariableSwitch}
          mandatory={mandatory}
        />
      </div>
    </div>
  );
};
