import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { some } from "lodash";
import classnames from "classnames";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ISlot, IPage } from "models/pages";
import { AvailableSlot } from "components/shared/pageElementsDesign/slot/availableSlot";
import { ModuleLayoutContext } from "components/contexts/moduleLayoutContext";
import { selectSelectedPage } from "redux/selectors/siteStructureSelectors";
import { useRenderModule } from "components/siteStructure/pageView/useRenderModule";
import { OrphanContext } from "components/siteStructure/pageView/orphanContext";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import copyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { ElementIdentifier } from "components/siteStructure/pageView/pageElements/context";
import { DestinationSlotArea } from "components/siteStructure/pageView/pageElements/destinationSlot/destinationSlotArea";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { CopyMode } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext/copyPageElementContext";

interface IProps extends IBaseProps {
  content: ISlot;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  slotContent: {
    padding: theme.spacing(1)
  },
  module: {},
  slotName: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.custom.ds.grayscales.gr700,
    padding: theme.spacing(0, 0, 0.5, 0.5),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  slotWrap: {
    flex: 1,
    border: `1px dashed ${theme.palette.custom.ds.grayscales.gr400}`,
    borderRadius: theme.spacing(0.5),
    "&.hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    }
  },
  flexSlotWrap: {
    display: "flex"
  },
  destinationSlotAvailable: {
    "& $slotWrap": {
      borderColor: theme.palette.custom.ds.grayscales.gr500
    },
    "& $slotName": {
      color: theme.palette.custom.ds.viking.viking500
    },

    "&:has(#destination-slot:active)": {
      "& $slotWrap": {
        backgroundColor: theme.palette.custom.ds.viking.viking100,
        border: `2px solid ${theme.palette.custom.ds.viking.viking300}`
      }
    }
  },
  dropActive: {
    "& $slotWrap": {
      borderColor: theme.palette.custom.ds.grayscales.gr500
    },
    "& $slotName": {
      color: theme.palette.custom.ds.grayscales.gr700
    }
  }
}));

function isMovingTopmostModule(
  movedElementId: string,
  topmostModuleId: string
): boolean {
  return movedElementId === topmostModuleId;
}

const isSameContext = (elementId: ElementIdentifier, target: IPage) =>
  target.contextName.trim().toLowerCase() ===
  elementId.contextName?.trim().toLowerCase();

export const Slot = (props: IProps) => {
  const { className, content, description } = props;
  const classes = useStyles();

  const page = useSelector(selectSelectedPage)!;
  const renderModule = useRenderModule(page.id);
  const orphan = useContext(OrphanContext);

  const ancestorLayoutsIds = useContext(ModuleLayoutContext);

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(copyPageElementContext);
  const movingElementId = dropContext.pageElementIdentifier?.instanceId ?? "";

  const isMovingAncestorLayout = useMemo(
    () => ancestorLayoutsIds.includes(movingElementId),
    [ancestorLayoutsIds, movingElementId]
  );

  const mode: DropMode | null = useMemo(() => {
    const modes: Record<DropMode | CopyMode, DropMode | null> = {
      [DropMode.Add]: DropMode.Add,
      [DropMode.Move]: DropMode.Move,
      [DropMode.Copy]: DropMode.Copy,
      [DropMode.None]: null
    };

    return modes[dropContext.dropMode] ?? modes[copyContext.copyMode];
  }, [copyContext.copyMode, dropContext.dropMode]);

  const rootClassName = classnames(classes.root, className, "slot", {
    [classes.destinationSlotAvailable]:
      content.modules.length === 0 &&
      ((mode === DropMode.Move && !isMovingAncestorLayout) ||
        mode === DropMode.Add ||
        mode === DropMode.Copy),
    [classes.dropActive]:
      (mode === DropMode.Move && !isMovingAncestorLayout) ||
      mode === DropMode.Add ||
      mode === DropMode.Copy
  });

  const shouldRenderAvailableSlot =
    !mode || (mode === DropMode.Move && isMovingAncestorLayout);

  const renderEmpty = () => {
    if (shouldRenderAvailableSlot) {
      return <AvailableSlot className={classes.module} />;
    }

    if (orphan) {
      return null;
    }

    const isDestinationSlotAreaVisible = some([
      mode === DropMode.Move &&
        isSameContext(dropContext.pageElementIdentifier, page) &&
        !isMovingAncestorLayout,
      mode === DropMode.Add,
      mode === DropMode.Copy &&
        isSameContext(copyContext.pageElementIdentifier, page)
    ]);

    return (
      <DestinationSlotArea
        containerId={ancestorLayoutsIds[ancestorLayoutsIds.length - 1]}
        slot={content.name}
        position={0}
        visible={isDestinationSlotAreaVisible}
      />
    );
  };

  const getShouldRenderSlotArea = useCallback(
    (moduleIndex: number): boolean => {
      if (mode === DropMode.Move) {
        const module = content.modules.at(moduleIndex);
        const next = content.modules.at(moduleIndex + 1);

        const isMovingCurrentModule = movingElementId === module?.instanceId;
        const isMovingNextModule = movingElementId === next?.instanceId;

        const isSameCtx = isSameContext(
          dropContext.pageElementIdentifier,
          page
        );

        // cannot move inside moving element nor it's children
        // do not show slots directly around moved element
        // move must be inside the same context
        return (
          !isMovingAncestorLayout &&
          !isMovingCurrentModule &&
          !isMovingNextModule &&
          isSameCtx
        );
      }

      if (mode === DropMode.Copy) {
        // copy must be inside the same context
        return isSameContext(copyContext.pageElementIdentifier, page);
      }

      // always show all slots when adding
      return mode === DropMode.Add;
    },
    [
      content.modules,
      copyContext.pageElementIdentifier,
      dropContext.pageElementIdentifier,
      isMovingAncestorLayout,
      mode,
      movingElementId,
      page
    ]
  );

  const renderDestinationSlotArea = (visibility: boolean, position = 0) => {
    return (
      !orphan &&
      mode && (
        <DestinationSlotArea
          containerId={ancestorLayoutsIds[ancestorLayoutsIds.length - 1]}
          slot={content.name}
          position={position}
          visible={visibility}
        />
      )
    );
  };

  const shouldSlotWrapTakeRemainingSpace =
    content.modules.length === 0 && !shouldRenderAvailableSlot && !orphan;

  const slotWrapClassnames = classnames(classes.slotWrap, {
    [classes.flexSlotWrap]: shouldSlotWrapTakeRemainingSpace
  });

  return (
    <div className={rootClassName}>
      <Tooltip title={description ?? content.name} placement="bottom-start">
        <Typography className={classes.slotName} component="div">
          {description ?? content.name}
        </Typography>
      </Tooltip>

      <div className={slotWrapClassnames}>
        {content.modules.length === 0 ? (
          renderEmpty()
        ) : (
          <>
            {renderDestinationSlotArea(
              (mode === DropMode.Move &&
                !isMovingAncestorLayout &&
                isSameContext(dropContext.pageElementIdentifier, page) &&
                !isMovingTopmostModule(
                  movingElementId,
                  content.modules[0].instanceId
                )) ||
                mode === DropMode.Add ||
                (mode === DropMode.Copy &&
                  isSameContext(copyContext.pageElementIdentifier, page))
            )}
            {content.modules.map((module, index) => (
              <React.Fragment key={module.instanceId}>
                {renderModule(module)}
                {renderDestinationSlotArea(
                  getShouldRenderSlotArea(index),
                  index + 1
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
