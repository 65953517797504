import { IBaseProps } from "components/_baseProps";
import { useSelector } from "react-redux";
import { RICH_TEXT_MODULE_ID } from "components/richText/constants";
import { Accordion } from "components/shared/accordion";
import { PageElement } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";

import { useStyles } from "./styles";

import { selectFrontendCultures } from "redux/selectors/frontendSelectors";
import { Typography } from "@material-ui/core";
import TranslationPropertyItem from "components/richText/components/translationPropertyItem";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IRichTextEntry } from "models/richText";
import { getRichTextEntriesForCultures } from "components/richText/utils";
import { filter } from "lodash";
import { translate } from "utils/i18n";

interface IPageDetailSidebarContentProps extends IBaseProps {
  element: PageElement;
}

export const ModuleTranslations = (props: IPageDetailSidebarContentProps) => {
  const { element } = props;
  const classes = useStyles();
  const allCultures = useSelector(selectFrontendCultures);

  const [richTextEntries, setRichTextEntries] = useState<IRichTextEntry[]>([]);

  const onConvertTranslatablePropertiesToRichTextEntries = useCallback(
    async (cultures: string[], translatableProperties) => {
      const result = await getRichTextEntriesForCultures(
        cultures,
        translatableProperties
      );
      setRichTextEntries(result);
    },
    []
  );

  useEffect(() => {
    const { translatableProperties } = element;
    const translatablePropertiesForStaticTextModule =
      translatableProperties[RICH_TEXT_MODULE_ID];

    onConvertTranslatablePropertiesToRichTextEntries(
      allCultures,
      translatablePropertiesForStaticTextModule
    );
  }, [allCultures, onConvertTranslatablePropertiesToRichTextEntries, element]);

  const translationsCount = useMemo(
    () => filter(richTextEntries, "hasTranslation").length,
    [richTextEntries]
  );

  return (
    <Accordion
      key={`${element.instanceId}-translations`}
      className={classes.accordion}
      label={translate("sitestructure.richtext_sidebar.title")}
    >
      <Typography component="h3" className={classes.header}>
        {translate("sitestructure.richtext_sidebar.available_in_languages", {
          num_of_languages: translationsCount
        })}
      </Typography>
      <div className={classes.body}>
        {richTextEntries.map((entry, index) => (
          <TranslationPropertyItem key={entry.culture} richTextEntry={entry} />
        ))}
      </div>
    </Accordion>
  );
};

export default ModuleTranslations;
