import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  accordion: {},
  header: {
    height: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    "& b": {
      fontWeight: 500,
      padding: theme.spacing(0, 0.5)
    }
  },
  body: {
    borderRadius: theme.spacing(0.5)
  }
}));
