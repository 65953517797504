import { useContext } from "react";
import { ILayout, IModule } from "models/pages";
import { useDispatch, useSelector } from "react-redux";
import {
  removeLayout,
  removeModule,
  setSelectedPageElement
} from "redux/actions/siteStructureActions";
import { Layout } from "./pageElements/layout";
import { Module } from "./pageElements/module";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { userService } from "services/userService";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import { selectSelectedPage } from "redux/selectors/siteStructureSelectors";
import { DropMode } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/dropPageElementContext";
import { CopyMode } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext/copyPageElementContext";

export const useRenderModule = (pageId: string, orphan: boolean = false) => {
  const dispatch = useDispatch();
  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);
  const currentSiteItemPath = useContext(CurrentSiteItemContext);
  const currentPage = useSelector(selectSelectedPage);

  const onSelect = (element: IModule | ILayout) =>
    dispatch(setSelectedPageElement(element));

  const onMove = (element: IModule | ILayout) => {
    dropContext.select(DropMode.Move, {
      key: element.key,
      type: element.moduleType,
      instanceId: element.instanceId,
      contextName: currentPage?.contextName
    });
  };

  const onDelete = (element: IModule | ILayout) => {
    const action =
      element.moduleType === "Module"
        ? () => removeModule({ pageId, moduleInstanceId: element.instanceId })
        : () => removeLayout({ pageId, layoutInstanceId: element.instanceId });

    dispatch(action());
  };

  const onCopy = (element: IModule | ILayout) => {
    copyContext.select(CopyMode.Copy, {
      instanceId: element.instanceId,
      key: element.key,
      type: element.moduleType,
      contextName: currentPage?.contextName
    });
  };

  return (element: IModule | ILayout) => {
    const isDisabled =
      !userService.hasElementPermission(element.moduleType, element.key) ||
      !userService.hasSiteItemPermissions(currentSiteItemPath);

    const commonProps = {
      key: element.instanceId,
      orphan,
      isDisabled,
      onSelect,
      onMove,
      onDelete,
      onCopy
    };

    switch (element.moduleType) {
      case "Layout":
        return <Layout layout={element} {...commonProps} />;
      case "Module":
        return <Module module={element} {...commonProps} />;
    }
  };
};
