import { reducerFactory } from "./coreReducer";

import { Action } from "redux-actions";
import {
  fetchFrontendCulturesSucceeded,
  fetchFrontendSitesSucceeded
} from "redux/actions/frontendActions";
import {
  IFrontendSitesDetail,
  IFrontendCulturesDetail,
  IFrontendState
} from "models/domainStates";

export const initialState: IFrontendState = {
  sites: [],
  cultures: []
};

const reactions = {
  [fetchFrontendSitesSucceeded.toString()]: (
    state: IFrontendState,
    action: Action<IFrontendSitesDetail[]>
  ) => {
    state.sites = action.payload;
  },
  [fetchFrontendCulturesSucceeded.toString()]: (
    state: IFrontendState,
    action: Action<IFrontendCulturesDetail[]>
  ) => {
    state.cultures = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
