import { TraceableNotification } from "@d3-forge/forge-notifications";
import { debounce } from "lodash";
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createRef,
  DependencyList
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDomainState } from "models/domainStates";
import notificationService from "services/notificationService";
import { AnyFunction } from "typings";
import { makeSelectRequestInstance } from "redux/selectors/requestMonitorSelectors";
import { Action, ActionFunctionAny } from "redux-actions";
import { useLocation } from "react-router-dom";
import { changeTabView } from "redux/actions/siteStructureActions";
import { CONTENT_VIEW } from "routes/tabs-routes";

export function usePersistedState<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const persistedValue = window.localStorage.getItem(key);
    if (persistedValue === null) {
      return defaultValue;
    }
    try {
      const parsedValue = JSON.parse(persistedValue);
      if (persistedValue !== null && persistedValue !== undefined) {
        return parsedValue;
      }
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export function useNotificationGroup(groupName: string) {
  useEffect(() => {
    notificationService.subscribeToGroup(groupName);
    return () => {
      notificationService.unsubscribeFromGroup(groupName);
    };
  }, [groupName]);
}

export function useNotifications<T extends TraceableNotification>(
  notificationsNames: string[],
  callback: (ntf: T) => void,
  dependencies: any[] = []
) {
  const memoCallback = useCallback(callback, [callback, ...dependencies]);
  useEffect(() => {
    const subscriptionIds: string[] = [];

    notificationsNames.forEach((name) => {
      subscriptionIds.push(notificationService.on(name, memoCallback));
    });

    return () => {
      notificationsNames.forEach((name) => {
        subscriptionIds.forEach((subscriptionId) =>
          notificationService.off(name, subscriptionId)
        );
      });
    };
  }, [notificationsNames, memoCallback]);
}

export function useDebounce<T extends AnyFunction>(delegate: T, delay: number) {
  const debouncedDelegate = useMemo(
    () => debounce(delegate, delay),
    [delegate, delay]
  );

  return debouncedDelegate;
}

export function useMemoSelector<A extends any[], T>(
  selectorFactory: () => (state: IDomainState, ...args: A) => T,
  ...args: A
) {
  const selector = useMemo(selectorFactory, []); // eslint-disable-line react-hooks/exhaustive-deps
  const selected = useSelector((state: IDomainState) =>
    selector(state, ...args)
  );

  return selected;
}

export function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  callback: (this: Document, ev: DocumentEventMap[K]) => any,
  onlyWhen: boolean = true
) {
  useEffect(() => {
    if (!onlyWhen) {
      return;
    }

    document.addEventListener(eventName, callback);

    return () => {
      document.removeEventListener(eventName, callback);
    };
  }, [eventName, callback, onlyWhen]);
}

export function useLastNonNullProp<T>(
  currentValue: T,
  defaultValue: NonNullable<T>
) {
  const [prop, setProp] = useState<NonNullable<T>>(defaultValue);
  useEffect(() => {
    if (currentValue) {
      setProp(currentValue ?? defaultValue);
    }
  }, [currentValue, defaultValue]);

  return prop;
}

export function useCancelableImageLoading(src: string, deps: DependencyList) {
  if (!deps.length) {
    console.warn(
      "useCancelableImageLoading hook should be used with dependencies, otherwise the image will stop loading everytime the component finish rendering"
    );
  }

  const reference = createRef<HTMLImageElement>();

  useEffect(() => {
    const element = reference.current;
    if (element) {
      element.src = src;
    }
    return () => {
      if (element) {
        element.src = "";
      }
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return reference;
}

export function useMergeState<T extends {}>(
  initialValue: T | (() => T)
): [T, (update: Partial<T>) => void] {
  const [state, setState] = useState<T>(initialValue);
  const update = useCallback(
    (newState: Partial<T>) => setState({ ...state, ...newState }),
    [state, setState]
  );
  return [state, update];
}

export function useDebouncedState<S>(
  initialState: S | (() => S),
  delay: number
): [S, S, (value: S) => void] {
  const [state, setState] = useState<S>(initialState);
  const [debouncedState, setDebouncedState] = useState<S>(initialState);

  const debouncedSet = useDebounce(setDebouncedState, delay);

  const setter = useCallback(
    (value: S) => {
      setState(value);
      debouncedSet(value);
    },
    [setState, debouncedSet]
  );

  return [state, debouncedState, setter];
}

export function useAbortController(deps: DependencyList) {
  const abortController = useMemo(() => new AbortController(), deps); // eslint-disable-line react-hooks/exhaustive-deps
  return abortController;
}

export function useRequestStatus<T>(
  what: ActionFunctionAny<Action<T>>,
  parameter: T
) {
  return useMemoSelector(makeSelectRequestInstance, what, parameter);
}

export function useSlidebarResize<
  S extends HTMLElement = HTMLDivElement,
  R extends HTMLElement = HTMLDivElement
>(
  onToggle: () => void,
  options: { leftLimit: number; gap: number; initialWidth: number } = {
    leftLimit: 100,
    gap: 80,
    initialWidth: 288
  }
) {
  const sidebar = createRef<S>();
  const resizer = createRef<R>();

  useEffect(() => {
    const res = resizer.current;
    if (res && sidebar.current) {
      let isMove = false;

      const resize = (event: MouseEvent) => {
        if (sidebar.current) {
          event.preventDefault();
          isMove = true;
          document.body.classList.add("col-resize");
          if (event.clientX > options.leftLimit) {
            const size = `${event.clientX - options.gap}px`;
            sidebar.current.style.width = size;
          } else {
            sidebar.current &&
              (sidebar.current.style.width = `${options.initialWidth}px`);
            onToggle();
            document.body.classList.remove("col-resize");
          }
        }
      };

      const onMouseUp = () => {
        if (!isMove) {
          sidebar.current &&
            (sidebar.current.style.width = `${options.initialWidth}px`);
          onToggle();
        }
        isMove = false;
        document.removeEventListener("mousemove", resize);
        document.body.classList.remove("col-resize");
      };

      const onMouseDown = (event: MouseEvent) => {
        event.preventDefault();
        document.addEventListener("mousemove", resize);
        document.addEventListener("mouseup", onMouseUp, { once: true });
      };

      res.addEventListener("mousedown", onMouseDown);

      return () => {
        res?.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mouseup", onMouseUp);
        document.body.classList.remove("col-resize");
      };
    }
  }, [resizer, sidebar, onToggle, options]);

  return [sidebar, resizer];
}

export function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useTabSystem() {
  const query = useQueryParams();
  const dispatch = useDispatch();
  const tab = query.get("tab") ?? CONTENT_VIEW;
  useEffect(() => {
    dispatch(changeTabView(tab));
  }, [tab, dispatch]);

  return tab;
}

export function useContextValue<T extends {}>(contextValue: T): T {
  const memoisedContextValue = useMemo(() => contextValue, [contextValue]);
  return memoisedContextValue;
}
