import { useCallback, useMemo, useState } from "react";
import { find } from "lodash";
import classnames from "classnames";
import { filterAutocompleteOptions } from "utils/autocompleteUtils";

import { useDebounce } from "components/hooks";
import { D3AutoCompleteInput } from "components/shared/d3Components";
import { ID3AutoCompleteInputOption } from "components/shared/d3Components/d3AutoCompleteInput";
import { TextFieldLabel } from "components/shared/textFieldLabel";

import { IBaseProps } from "components/_baseProps";
import { IComponentProperty } from "models/componentProperty";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

import { useStyles } from "./styles";

const EMPTY_AUTOCOMPLETE_OPTION = {
  value: "",
  label: ""
};

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  errorMessage?: string;
  options: ID3AutoCompleteInputOption[];
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

export const AutocompleteStringProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    options,
    disabled = false,
    errorMessage = "",
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const hideVariableSwitch = !propertyDefinition.canUseVariables ?? false;
  const textFieldAreaClassName = classnames(classes.textFieldArea, {
    [classes.noVariableSwitch]: hideVariableSwitch
  });

  const [localValue, setLocalValue] = useState(value);
  const debouncedOnChange = useDebounce(onChange, 2500);

  const getFilteredOptions = useCallback(
    (options: ID3AutoCompleteInputOption[]) =>
      filterAutocompleteOptions(options, localValue),
    [localValue]
  );

  const handleChange = (newValue: string, isCausedByBlurEvent = false) => {
    if (localValue === newValue) {
      return;
    }

    setLocalValue(newValue);
    debouncedOnChange(propertyDefinition.name, newValue);

    if (isCausedByBlurEvent) {
      debouncedOnChange.flush();
    }
  };

  const clearValueOnVariableSwitch = () => {
    setLocalValue("");
    debouncedOnChange(propertyDefinition.name, localValue);
  };

  const showErrorMessage = errorMessage.length > 0;
  const showMandatoryMessage =
    mandatory.showMessage ||
    (propertyDefinition.mandatory && localValue === "");

  const helperText = useMemo(() => {
    if (showMandatoryMessage) {
      return mandatory.message;
    }
    if (showErrorMessage) {
      return errorMessage;
    }
    return "";
  }, [showMandatoryMessage, showErrorMessage, mandatory.message, errorMessage]);

  const autocompleteInputClassnames = classnames(
    classes.input,
    classes.autocompleteInput,
    {
      [mandatory.className]: showMandatoryMessage
    }
  );

  const currentOption: ID3AutoCompleteInputOption | undefined = useMemo(
    () =>
      localValue === ""
        ? EMPTY_AUTOCOMPLETE_OPTION
        : find(options, { value: localValue }) || {
            value: localValue,
            label: localValue
          },
    [options, localValue]
  );

  return (
    <div className={rootClassName}>
      <TextFieldLabel
        value={propertyDefinition.displayName || propertyDefinition.name}
        hint={propertyDefinition.description}
        mandatory={propertyDefinition.mandatory}
        disabled={disabled}
      />

      <div className={textFieldAreaClassName}>
        <D3AutoCompleteInput
          className={autocompleteInputClassnames}
          options={options}
          value={currentOption}
          shouldAutocompleteOption={false}
          autocompletePopperClassname={classes.autocompletePopperClassname}
          placeholder=""
          fullWidth={true}
          disabled={disabled}
          onBlur={(option) => handleChange(option.value, true)}
          onSubmit={(option) => handleChange(option.value)}
          onInputChange={(option) => handleChange(option.value)}
          error={showErrorMessage}
          filterOptions={getFilteredOptions}
          helperText={helperText}
        />

        {!hideVariableSwitch && (
          <VariableSwitch
            propertyName={propertyDefinition.name}
            propertyValue={localValue}
            propertyTypeName={propertyDefinition.typeName}
            disabled={disabled}
            onChange={onChange}
            onSwitch={clearValueOnVariableSwitch}
            mandatory={mandatory}
          />
        )}
      </div>
    </div>
  );
};
