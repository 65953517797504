import { Tooltip } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";
import { useStyles as useContainerStyles } from "../styles";

import { D3Button } from "components/shared/d3Components";
import classNames from "classnames";

export interface IDeleteButtonProps {
  onClick: () => void;
}

export const DeleteButton = (props: Readonly<IDeleteButtonProps>) => {
  const classes = useStyles();
  const containerClasses = useContainerStyles();

  const { onClick } = props;

  const tooltip = translate(`sitestructure.richtext_sidebar.toolbox.delete`);
  const icon = "delete";

  const buttonClassnames = classNames(
    classes.button,
    containerClasses.controlButton
  );

  return (
    <Tooltip title={tooltip} arrow={true} placement="top" enterDelay={200}>
      <span>
        <D3Button
          icon={icon}
          size="medium"
          variant="ghost"
          color="neutral"
          onClick={onClick}
          className={buttonClassnames}
        />
      </span>
    </Tooltip>
  );
};

export default DeleteButton;
