import _ from "lodash";
import { makeStyles, Tooltip, Typography, withStyles } from "@material-ui/core";
import { SiteNodeInfo } from "components/shared/siteNodeInfo";
import { RectangleSkeleton } from "components/skeletons";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { reloadPage } from "redux/actions/siteStructureActions";
import { translate } from "utils/i18n";
import { useMemo } from "react";
import { SiteNodeTypes } from "models/siteStructure";

interface IProps extends IBaseProps {
  isLoading: boolean;
  searchedText: string;
  nodes: ISiteItem[];
  hideSiteStructureTree: (val: boolean) => void;
  isLinkPicker?: boolean;
  onSelectedLinkPickerPage?: (id: string, path: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1, 2)
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(1)
  },
  skeletonTitle: {
    width: theme.spacing(9)
  },
  node: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: theme.spacing(4),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.custom.ds.grayscales.gr300
    }
  },
  nodeIcon: {
    "&$highlight": {
      color: theme.palette.custom.secondaryAccent.tiffany
    }
  },
  resultsText: {
    color: theme.palette.custom.ds.grayscales.gr700,
    margin: theme.spacing(1, 0),
    display: "block"
  },
  highlight: {}
}));

const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "0 0 -4px"
  }
})(Tooltip);

export function SiteStructureSearchResults(props: IProps) {
  const {
    isLoading,
    searchedText,
    nodes,
    hideSiteStructureTree,
    isLinkPicker = false,
    onSelectedLinkPickerPage = () => {}
  } = props;
  const showResults = searchedText.length > 0;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelection = (item: ISiteItem) => {
    if (isLinkPicker) {
      console.log("Link picker selected item", item.nodeId, item.path);
      onSelectedLinkPickerPage(item.nodeId, item.path);
      return;
    }

    hideSiteStructureTree(false);
    dispatch(reloadPage());
    history.replace(`/sitestructure/${item.path}`);
  };

  const nodesToShow = useMemo(() => {
    return isLinkPicker
      ? nodes.filter((node) => node.nodeType === SiteNodeTypes.PAGE)
      : nodes;
  }, [isLinkPicker, nodes]);

  const memorizedResultsText = useMemo(() => {
    const minimumNumberOfCharacters = 3;

    if (searchedText.length < minimumNumberOfCharacters)
      return translate("sitestructure.search.notenoughcharacters", {
        numberOfCharacters: String(minimumNumberOfCharacters)
      });

    const nodesMatching =
      nodesToShow.filter((item) => item.path.includes(searchedText)).length ??
      0;
    const aliasesMatching =
      nodesToShow.filter(
        (item) => item.alias?.value.includes(searchedText) ?? false
      ).length ?? 0;

    return translate("sitestructure.search.results", {
      numberOfResults: String(nodesMatching + aliasesMatching)
    });
  }, [nodesToShow, searchedText]);

  const nodesWithConditions = useMemo(() => {
    return nodesToShow.map((node) => ({
      ...node,
      siteItemLabel: node.path.replaceAll(
        searchedText,
        `<b>${searchedText}</b>`
      ),
      aliasLabel:
        node.alias?.value.replaceAll(searchedText, `<b>${searchedText}</b>`) ??
        "",
      showAsNode: node.path.includes(searchedText),
      showAsAlias: node.alias?.value.includes(searchedText) ?? false,
      commonProps: {
        nodeType: node.nodeType,
        stage: node.status,
        size: "small" as any,
        nodeIconClassName: classes.nodeIcon,
        parseLabelToHtml: true,
        contextName: node.contextName,
        isSearchResult: true,
        wrapUrlText: true
      }
    }));
  }, [nodesToShow, searchedText, classes.nodeIcon]);

  if (isLoading && searchedText.length) {
    return (
      <div className={classes.skeletonContainer}>
        <RectangleSkeleton
          key="totalResults"
          size={4}
          className={classes.skeletonTitle}
        />
        {_.range(11).map((index) => (
          <RectangleSkeleton key={index} size={5} />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.root} hidden={!showResults}>
      <Typography variant="caption" className={classes.resultsText}>
        {memorizedResultsText}
      </Typography>
      {nodesWithConditions.map((node) => (
        <div key={node.nodeId}>
          {node.showAsNode && (
            <div className={classes.node} onClick={() => handleSelection(node)}>
              <SiteNodeInfo
                label={node.siteItemLabel}
                {...node.commonProps}
                isAlias={false}
              />
            </div>
          )}
          {node.showAsAlias && (
            <StyledTooltip title={node.path} placement="top">
              <div
                className={classes.node}
                onClick={() => handleSelection(node)}
              >
                <SiteNodeInfo
                  label={node.aliasLabel}
                  isAlias={true}
                  {...node.commonProps}
                />
              </div>
            </StyledTooltip>
          )}
        </div>
      ))}
    </div>
  );
}
