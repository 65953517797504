import { useState } from "react";
import classnames from "classnames";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IComponentProperty } from "models/componentProperty";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { useDebounce } from "components/hooks";
import { UxdIcon } from "components/shared/uxdIcon";
import ContentPicker from "components/contentPicker";
import { selectEntitiesDefinitions } from "redux/selectors/forgeSelectors";
import { useSelector } from "react-redux";
import { translate } from "utils/i18n";
import { ForgeEntitiesApi } from "api/forgeEntitiesApi";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";
import { D3Modal } from "components/shared/d3Components";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  disabledCause: {
    color: theme.palette.custom.ds.grayscales.gr600
  },
  textFieldArea: {
    display: "flex",
    flexDirection: "row",
    "& .MuiTextField-root:has(~.MuiAutocomplete-root:not([hidden]))": {
      display: "none"
    }
  }
}));

export const ContentPickerProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    disabled = false,
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [localValue, setLocalValue] = useState(value);
  const [openModal, setOpenModal] = useState(false);
  const debouncedOnChange = useDebounce(onChange, 2500);

  const [contentPickerOpen, setContentPickerOpen] = useState(false);

  const entitiesDefinition = useSelector(selectEntitiesDefinitions);

  const handleChange = (newValue: string) => {
    if (localValue === newValue) {
      return;
    }

    setLocalValue(newValue);
    debouncedOnChange(propertyDefinition.name, newValue);
  };

  const handleBlur = () => {
    debouncedOnChange.flush();
  };

  const handleModal = () => {
    setOpenModal(false);
  };

  const { entityType: entityTypeCode } = propertyDefinition.contentPicker!;

  const isEntityDefined = entitiesDefinition.some(
    (d) => entityTypeCode === d.typeCode
  );

  const hasPermission = userService.hasPermissions(PermissionCodes.ViewContent);
  const enabled = isEntityDefined && hasPermission && !disabled;

  const [entityType, entityCode] = entityTypeCode.split(".");

  const disabledCause = !isEntityDefined
    ? translate("contentpicker.entitynotdefined", {
        type: entityCode || entityType
      })
    : !hasPermission
    ? translate("contentpicker.nopermission", {
        type: entityCode || entityType
      })
    : "";

  const getHelperText = () => {
    if (disabledCause) return disabledCause;
    if (mandatory.showMessage) return mandatory.message;
    return "";
  };

  const openEntityDetail = () => {
    ForgeEntitiesApi.getEntityDetailUrl(
      entityCode || entityType,
      localValue
    ).then((url) => {
      if (!url) {
        setOpenModal(true);
      } else {
        window.open(url, "_blank");
      }
    });
  };

  const clearValueOnVariableSwitch = () => {
    setLocalValue("");
    debouncedOnChange(propertyDefinition.name, localValue);
  };

  return (
    <>
      <div className={rootClassName}>
        <TextFieldLabel
          value={propertyDefinition.displayName || propertyDefinition.name}
          hint={propertyDefinition.description}
          mandatory={propertyDefinition.mandatory}
          disabled={disabled}
        />
        <div className={classes.textFieldArea}>
          <TextField
            className={mandatory.showMessage ? mandatory.className : ""}
            fullWidth={true}
            value={localValue}
            variant="outlined"
            size="small"
            onChange={({ target }) => handleChange(target.value)}
            onBlur={handleBlur}
            disabled={!enabled}
            helperText={getHelperText()}
            FormHelperTextProps={{
              className: disabledCause ? classes.disabledCause : ""
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={translate("sitestructure.pickentity", {
                      type: entityCode || entityType
                    })}
                  >
                    <IconButton
                      onClick={() => setContentPickerOpen(true)}
                      size="small"
                      disabled={!enabled}
                    >
                      <UxdIcon name="search" />
                    </IconButton>
                  </Tooltip>
                  {userService.hasPermissions(PermissionCodes.EditContent) && (
                    <Tooltip
                      title={translate("sitestructure.navigatetodetail", {
                        type: entityCode || entityType
                      })}
                    >
                      <span>
                        <IconButton
                          onClick={openEntityDetail}
                          size="small"
                          disabled={!localValue}
                        >
                          <UxdIcon name="launch" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </InputAdornment>
              )
            }}
          />
          <ContentPicker
            entityTypeCode={entityTypeCode}
            open={contentPickerOpen}
            onClose={(selected) => {
              if (selected) {
                handleChange(selected.slug || "");
                debouncedOnChange.flush();
              }
              setContentPickerOpen(false);
            }}
          />
          <VariableSwitch
            propertyName={propertyDefinition.name}
            propertyValue={localValue}
            propertyTypeName={propertyDefinition.typeName}
            disabled={disabled}
            onChange={onChange}
            onSwitch={clearValueOnVariableSwitch}
            mandatory={mandatory}
          />
        </div>
      </div>
      <D3Modal
        modalTitle={translate("sitestructure.navigatetodetailerror.title")}
        onConfirm={handleModal}
        confirmLabel={translate("general.ok")}
        open={openModal}
      >
        <Typography gutterBottom>
          {translate("sitestructure.navigatetodetailerror.message", {
            slug: localValue
          })}
        </Typography>
      </D3Modal>
    </>
  );
};
