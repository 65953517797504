import { Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

export interface IWordCountProps extends IBaseProps {
  wordCount: number;
}

export const WordCount = (props: Readonly<IWordCountProps>) => {
  const { wordCount, className } = props;

  const plurality = wordCount === 1 ? "singular" : "plural";
  const key = `sitestructure.richtext_sidebar.wordcount.${plurality}`;

  return (
    <Typography component="label" className={className}>
      {translate(key, { num_of_words: wordCount })}
    </Typography>
  );
};

export default WordCount;
