import { createAction } from "redux-actions";
import { IRichTextTranslationForm } from "models/richText";

export const triggerRichTextModal = createAction(
  `TRIGGER_RICH_TEXT_MODAL`,
  (form: IRichTextTranslationForm) => form
);

export const disposeRichTextModal = createAction(`DISPOSE_RICH_TEXT_MODAL`);

export const updateRichTextTranslationFormValue = createAction(
  `UPDATE_RICH_TEXT_TRANSLATION_FORM_VALUE`,
  (value: string) => value
);
