import { every } from "lodash";
import { ScrollScope } from "models/scrollManagement";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unsetScrollManagementPayload } from "redux/actions/scrollManagementActions";
import { selectScrollManagementPayload } from "redux/selectors/scrollManagementSelectors";

interface Options {
  elementId: string;
  scrollableElementId: string;
  scrollScope: ScrollScope;
}

function executeScroll(
  scrollableElement: HTMLDivElement | null,
  elementToScrollInto: HTMLDivElement | null,
  offset = 50
) {
  if (scrollableElement === null || elementToScrollInto === null) {
    return false;
  }

  const elementToScrollIntoTop =
    elementToScrollInto.getBoundingClientRect().top;

  const scrollableElementTop = scrollableElement.getBoundingClientRect().top;

  const scrollTop = elementToScrollIntoTop - scrollableElementTop - offset;

  scrollableElement.scrollTo({ top: scrollTop, behavior: "smooth" });

  return true;
}

export function useScrollManagement({
  elementId,
  scrollableElementId,
  scrollScope
}: Options) {
  const elementToScrollIntoRef = useRef<HTMLDivElement>(
    null
  ) as React.MutableRefObject<HTMLDivElement>;
  const scrollManagementPayload = useSelector(selectScrollManagementPayload);
  const [isScrollingActive, setIsScrollingActive] = useState(false);

  const dispatch = useDispatch();

  const onScrollEnd = useCallback(() => {
    dispatch(unsetScrollManagementPayload());
    setIsScrollingActive(false);
    const scrollableElement = document.getElementById(
      scrollableElementId
    ) as HTMLDivElement | null;

    scrollableElement?.removeEventListener("scrollend", onScrollEnd);
  }, [dispatch, scrollableElementId]);

  useEffect(() => {
    const { scope, elementIdToScrollInto, isReadyToScrollIntoElement } =
      scrollManagementPayload;
    const scrollableElement = document.getElementById(
      scrollableElementId
    ) as HTMLDivElement | null;

    const shouldScroll = every([
      !isScrollingActive,
      scrollScope === scope,
      elementIdToScrollInto === elementId,
      scrollableElement !== null,
      elementToScrollIntoRef.current !== null,
      isReadyToScrollIntoElement
    ]);

    if (!shouldScroll) {
      return;
    }

    setIsScrollingActive(true);

    window.requestAnimationFrame(() => {
      scrollableElement?.addEventListener("scrollend", onScrollEnd);

      const didScroll = executeScroll(
        scrollableElement,
        elementToScrollIntoRef.current
      );

      if (!didScroll) {
        onScrollEnd();
      }
    });

    return () => {
      scrollableElement?.removeEventListener("scrollend", onScrollEnd);
    };
  }, [
    scrollManagementPayload,
    elementId,
    scrollScope,
    scrollableElementId,
    isScrollingActive,
    onScrollEnd
  ]);

  return {
    elementToScrollIntoRef
  };
}
