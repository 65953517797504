import { ISiteItem } from "models/siteItem";
import { IDomainState } from "models/domainStates";
import { createSelector } from "reselect";
import { normalizePath } from "utils/pathUtils";
import { getPageElement } from "services/pageService";
import { getSiteItemSiblings } from "utils/siteStructureUtils";
import { selectAuthorizationGroupList } from "redux/selectors/authorizationGroupSelectors";
import { IInheritedAuthorizationGroup } from "models/authorizationGroup";
import { IPage } from "models/pages";
import { IMenu } from "models/menus";

export const selectSiteItems = (state: IDomainState) =>
  state.siteStructure.siteItems;

export const makeSelectSiteItemById = () =>
  createSelector(
    selectSiteItems,
    (_: IDomainState, nodeId: string) => nodeId,
    (siteItems, nodeId) => siteItems[nodeId]
  );

export const makeSelectSiteItemChildren = () =>
  createSelector(
    selectSiteItems,
    (_: IDomainState, nodeId: string) => nodeId,
    (siteItems, nodeId) => siteItems[nodeId].children.map((id) => siteItems[id])
  );

const mapSiteItemsByPath = createSelector(
  selectSiteItems,
  (siteItems) =>
    new Map<string, ISiteItem>(
      Object.values(siteItems).map((item) => [normalizePath(item.path), item])
    )
);

export const makeSelectSiteItemByPath = () =>
  createSelector(
    mapSiteItemsByPath,
    (_: IDomainState, path: string) => normalizePath(path),
    (mapByPath, path) => mapByPath.get(path) || mapByPath.get("~")
  );

export const makeSelectHierarchySiteItems = () =>
  createSelector(
    selectSiteItems,
    (_: IDomainState, nodeId: string) => nodeId,
    (siteItems, nodeId) => {
      const currentItem = siteItems[nodeId];
      return [
        ...currentItem.parentNodesIds.map((parent) => siteItems[parent]),
        currentItem
      ];
    }
  );

export const makeSelectSiblingsSiteItems = () =>
  createSelector(
    selectSiteItems,
    (_: IDomainState, nodeId: string) => nodeId,
    getSiteItemSiblings
  );

export const selectHasSelectedNode = (state: IDomainState) =>
  !!state.siteStructure.selectedNode;

export const selectHasSelectedPageElement = (state: IDomainState) =>
  !!state.siteStructure.selectedPageElement;

export const selectSidebarOpen = (state: IDomainState) => {
  const { isAddRichTextModalOpened, richTextTranslationForm } = state.richText;

  const isCreatingRichText =
    isAddRichTextModalOpened && richTextTranslationForm?.isNew;

  return (
    ((!isCreatingRichText || richTextTranslationForm.shouldSidebarBeOpen) ??
      false) &&
    state.siteStructure.sidebarOpen
  );
};

export const selectRenamedItemPath = (state: IDomainState) =>
  state.siteStructure.renamedItemPath;

export const selectSelectedPage = (state: IDomainState) => {
  const { selectedNode } = state.siteStructure;

  if (!selectedNode) {
    return null;
  }
  /*
    Cast it intentionally in order to not mess up the 
    specific usage (cause selected nodecan be either IPage or IMenu)
  */

  const pageToBe = selectedNode as IPage;

  const isSelectedNodePage = pageToBe.contextName !== undefined;

  return isSelectedNodePage ? pageToBe : null;
};

export const selectSelectedCulture = (state: IDomainState) => {
  return state.siteStructure.selectedCulture;
};

export const selectSelectedPageElementId = (state: IDomainState) =>
  state.siteStructure.selectedPageElement;

export const selectSelectedPageElement = createSelector(
  selectSelectedPage,
  selectSelectedPageElementId,
  (page, instanceId) =>
    page && instanceId ? getPageElement(page, instanceId) ?? null : null
);

export const makeIsSelectedPageElement = () =>
  createSelector(
    selectSelectedPageElement,
    (_: IDomainState, instanceId: string) => instanceId,
    (pageElement, instanceId) => pageElement?.instanceId === instanceId ?? false
  );

export const pageIsToReload = (state: IDomainState) =>
  state.siteStructure.reloadPage;

export const selectCurrentTab = (state: IDomainState) =>
  state.siteStructure.selectedTab;

export const selectMetadataSiteItem = () =>
  createSelector(
    selectSiteItems,
    (_: IDomainState, nodeId: string) => nodeId,
    (siteItems, nodeId) => siteItems[nodeId].metadata
  );

export const selectPageRequiredModuleWarningFlag = (state: IDomainState) =>
  Object.values(state.siteStructure.pageRequiredModuleWarning).some(
    (flag) => flag === true
  );

export const selectSelectedItemLocalAuthGroups = () =>
  createSelector(
    selectAuthorizationGroupList,
    (_: IDomainState, selectedItemAuthIds: string[]) => selectedItemAuthIds,
    (allAuthGroups, selectedItemAuthIds) =>
      allAuthGroups?.filter((authGroup) =>
        selectedItemAuthIds?.includes(authGroup.groupId)
      )
  );

export const selectSelectedItemInheritedAuthGroups = () =>
  createSelector(
    selectSiteItems,
    selectAuthorizationGroupList,
    (_: IDomainState, parentNodesIds: string[]) => parentNodesIds,
    (siteItems, allAuthGroups, parentNodeIds) => {
      const selectedItemParentSiteItems = Object.values(siteItems).filter(
        (item) => parentNodeIds.some((id) => id === item.nodeId)
      );

      return selectedItemParentSiteItems.reduce(
        (prevItem: IInheritedAuthorizationGroup[], currItem: ISiteItem) => {
          const authGroups = allAuthGroups
            .filter((group) =>
              currItem.authorizationGroups?.includes(group.groupId)
            )
            .map((item) => ({ inheritancePath: currItem.path, ...item }));
          return [...prevItem, ...authGroups];
        },
        []
      );
    }
  );

export const selectSelectedMenu = (state: IDomainState) => {
  const { selectedNode } = state.siteStructure;

  if (!selectedNode) {
    return null;
  }

  /*
     Cast it intentionally in order to not mess up the 
     specific usage (cause selected node can be either IPage or IDirectory)
  */

  const menu = selectedNode as IMenu;
  if (!menu) {
    throw new Error("The selected node is not a Menu");
  }

  return menu;
};

export const selectSelectedMenuItem = (state: IDomainState) =>
  state.siteStructure.selectedMenuItem;

export const selectLastTriggeredCommandId = (state: IDomainState) =>
  state.siteStructure.lastTriggeredCommandId;

export const selectHighlightedSiteAssetId = (state: IDomainState) =>
  state.siteStructure.highlightedSiteAssetId;

export const selectIsSiteStructureSearchViewOpen = (state: IDomainState) =>
  state.siteStructure.isSiteStructureSearchViewOpen;

export const selectSiteStructureOperationExecuted = (state: IDomainState) =>
  state.siteStructure.siteStructureOperationExecuted;
