import { IBaseProps } from "components/_baseProps";
import { useStyles } from "./styles";
import { IconButton, Typography } from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import classNames from "classnames";

export interface ISiteAssetSidebarHeaderProps extends IBaseProps {
  title: string;
  description: string;
  onClose: () => void;
}

export const SiteAssetSidebarHeader = (props: ISiteAssetSidebarHeaderProps) => {
  const { title, description, className, onClose } = props;
  const classes = useStyles();

  const root = classNames(className, classes.root);

  return (
    <div className={root}>
      <div className={classes.main}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </div>
      <IconButton className={classes.button} onClick={onClose}>
        <UxdIcon name="last_page" />
      </IconButton>
    </div>
  );
};

export default SiteAssetSidebarHeader;
