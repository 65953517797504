import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    margin: theme.spacing(0, 3) // 24px
  },
  grid: {
    "&.MuiGridList-root": {
      padding: theme.spacing(0, 3)
    }
  },
  item: {
    height: theme.spacing(6),
    borderBottom: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  }
}));
