import { OPERATIONS_THAT_DISPOSE_SELECTED_DROP_OPERATION } from "components/siteStructure/pageView/pageElements/context/dropPageElementContext/constants";
import { useDispatch, useSelector } from "react-redux";
import { CopyPageElementSnackbar } from "./copyPageElementSnackbar";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  copyLayout,
  copyModule,
  unsetSelectedPageElement
} from "redux/actions/siteStructureActions";
import { CopySiteAssetPayload } from "redux/actions/typings/siteStructureActions";
import { selectSiteStructureOperationExecuted } from "redux/selectors/siteStructureSelectors";
import {
  DEFAULT_IDENTIFIER,
  ElementIdentifier
} from "components/siteStructure/pageView/pageElements/context";
import { includes } from "lodash";

const MISSING_INSTANCEID_ERROR_MESSAGE =
  "[CopyPageElementContext] Missing instance id of the selected element";

export enum CopyMode {
  None = 0,
  Copy = 1
}

type CopyPageElementContextShape = {
  copyMode: CopyMode;
  pageElementIdentifier: ElementIdentifier;
  select: (copyMode: CopyMode, identifier: ElementIdentifier) => void;
  copy: (layoutId: string, slot: string, position: number) => void;
  clear: () => void;
};

export const CopyPageElementContext =
  createContext<CopyPageElementContextShape>({
    copyMode: CopyMode.Copy,
    pageElementIdentifier: DEFAULT_IDENTIFIER,
    select: () => undefined,
    copy: () => undefined,
    clear: () => undefined
  });

interface Props {
  pageId: string;
}

export const CopyPageElementContextProvider = (
  props: PropsWithChildren<Props>
) => {
  const dispatch = useDispatch();

  const { children, pageId } = props;

  const [elementIdentifier, setElementIdentifier] =
    useState<ElementIdentifier>(DEFAULT_IDENTIFIER);
  const [sourcePageId, setSourcePageId] = useState<string | null>(null);
  const siteStructureOperationExecuted = useSelector(
    selectSiteStructureOperationExecuted
  );

  const [copyMode, setCopyMode] = useState<CopyMode>(CopyMode.None);

  const clear = useCallback(() => {
    setCopyMode(CopyMode.None);
    setElementIdentifier(DEFAULT_IDENTIFIER);
    setSourcePageId(null);
  }, []);
  const select = useCallback(
    (mode: CopyMode, identifier: ElementIdentifier) => {
      setCopyMode(mode);
      setElementIdentifier(identifier);
      setSourcePageId(pageId);
    },
    [pageId]
  );

  const copy = useCallback(
    (layoutId, slot, position) => {
      const { type, instanceId } = elementIdentifier;
      if (!instanceId) {
        console.error(MISSING_INSTANCEID_ERROR_MESSAGE);
        return;
      }
      if (type !== "Module" && type !== "Layout") {
        return;
      }

      const payload: CopySiteAssetPayload = {
        sourcePageId: sourcePageId ?? pageId,
        sourceInstanceId: instanceId,
        targetPageId: pageId,
        targetInstanceId: layoutId,
        targetSlot: slot,
        targetPosition: position
      };
      const action =
        type === "Module" ? copyModule(payload) : copyLayout(payload);

      dispatch(unsetSelectedPageElement());
      dispatch(action);

      clear();
    },
    [clear, dispatch, elementIdentifier, pageId, sourcePageId]
  );

  // cleanup on page change
  useEffect(() => {
    if (copyMode !== CopyMode.None) {
      return;
    }

    // This shoudn't be done for copy module across different page
    return clear();
  }, [copyMode, pageId, clear]);

  useEffect(() => {
    if (siteStructureOperationExecuted === undefined) {
      return;
    }

    if (
      includes(
        OPERATIONS_THAT_DISPOSE_SELECTED_DROP_OPERATION,
        siteStructureOperationExecuted
      )
    ) {
      clear();
    }
  }, [clear, siteStructureOperationExecuted]);

  const contextValue: CopyPageElementContextShape = useMemo(
    () => ({
      copyMode,
      pageElementIdentifier: elementIdentifier,
      select,
      copy,
      clear
    }),
    [copy, clear, select, elementIdentifier, copyMode]
  );

  return (
    <CopyPageElementContext.Provider value={contextValue}>
      {children}
      <CopyPageElementSnackbar
        mode={copyMode}
        elementType={elementIdentifier.type}
        onCancel={clear}
      />
    </CopyPageElementContext.Provider>
  );
};
