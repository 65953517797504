import { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";

import LinkRuleEntityGroup from "components/linkRules/linkRuleEntityGroup";
import { useLinkRulesScrollingManagementContext } from "components/linkRules/context/scrollManagement";
import SearchWidget from "components/linkRules/searchWidget";
import { useSearchManagement } from "components/linkRules/context/searchManagement";
import { D3Button } from "components/shared/d3Components";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import {
  NEW_NAVIGATION_RULE_ID,
  getLinkRulesMainDetailCalculatedWidth
} from "components/linkRules/utils";
import { translate } from "utils/i18n";
import { userService } from "services/userService";
import { useSelector } from "react-redux";
import { selectIsLinkRulesSidebarOpen } from "redux/selectors/linkRulesSelectors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100% - ${theme.spacing(11)}px)`
  },
  header: {
    backgroundColor: "white",
    margin: "0px",
    display: "flex",
    alignItems: "center",
    height: theme.spacing(11)
  },
  headerLeftSection: {
    flex: 1,
    fontSize: theme.typography.pxToRem(24)
  },
  mainSection: {
    scrollBehavior: "smooth",
    overflowY: "auto",
    height: "100%"
  },
  addButton: {
    marginRight: theme.spacing(4),
    whiteSpace: "nowrap"
  },
  resizableTrackerWrapper: {}
}));

export interface IScrollPayload {
  key: string;
  initialPositionY: number;
}

export default function LinkRuleDetail() {
  const classes = useStyles();
  const { filteredLinkRulesEntityList } = useSearchManagement();
  const isSidebarOpen = useSelector(selectIsLinkRulesSidebarOpen);

  const {
    sidebarWidth,
    scrollingSectionRef,
    scrollerOffsetPlaceholderHeight,
    onResizeTrackerContainerChange
  } = useLinkRulesScrollingManagementContext();
  const { onSelectNavigationRuleItem } = useLinkRulesCrudManagementContext();

  const entityKeys = useMemo(
    () => Object.keys(filteredLinkRulesEntityList),
    [filteredLinkRulesEntityList]
  );

  const containerWidth = useMemo(
    () => getLinkRulesMainDetailCalculatedWidth(sidebarWidth),
    [sidebarWidth]
  );

  useEffect(() => {
    if (scrollingSectionRef.current !== null) {
      const resizableTrackerWrapper = scrollingSectionRef.current.children[0];

      const resizeObserver = new ResizeObserver(onResizeTrackerContainerChange);
      resizeObserver.observe(resizableTrackerWrapper);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [onResizeTrackerContainerChange, scrollingSectionRef]);

  const handleNewItemClick = () => {
    onSelectNavigationRuleItem(NEW_NAVIGATION_RULE_ID);
  };

  const isUserAllowedToAddNavigationRules =
    userService.getIsUserAllowedToAddNavigationRules();

  const shouldShowAddNewNavigationRuleButton =
    isUserAllowedToAddNavigationRules && !isSidebarOpen;

  return (
    <div className={classes.root} style={{ width: containerWidth }}>
      <h3 className={classes.header}>
        <SearchWidget />
        {shouldShowAddNewNavigationRuleButton && (
          <D3Button
            icon="add_circle"
            className={classes.addButton}
            onClick={handleNewItemClick}
          >
            {translate("navigationrules.new_navigation_rule")}
          </D3Button>
        )}
      </h3>
      <div className={classes.mainSection} ref={scrollingSectionRef}>
        <div className={classes.resizableTrackerWrapper}>
          {entityKeys.map((key, index) => (
            <LinkRuleEntityGroup
              key={key}
              entityKey={key}
              linkRuleEntry={filteredLinkRulesEntityList[key]}
            />
          ))}
        </div>
        <div
          className="scroller-offset-placeholder"
          style={{ height: scrollerOffsetPlaceholderHeight }}
        ></div>
      </div>
    </div>
  );
}
