import { IBaseProps } from "components/_baseProps";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";
import { useStyles } from "./styles";
import { Button, Typography } from "@material-ui/core";
import { Hint } from "components/shared/hint";
import Preview from "components/siteStructure/template/preview";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectSelectedPage } from "redux/selectors/siteStructureSelectors";
import { useMemo } from "react";

interface ISiteAssetTileProps extends IBaseProps {
  asset: ITemplateDefinition | ILayoutDefinition;
  showHighlight?: boolean;
  highlightRegex?: RegExp;
  onSelect: (id: string, namespace: string) => void;
}

export const SiteAssetListTile = (props: ISiteAssetTileProps) => {
  const {
    asset,
    showHighlight = false,
    highlightRegex = /^$/,
    className,
    onSelect
  } = props;

  const classes = useStyles();

  const { id, namespace, design, label, description } = asset;

  // split label in highligh-able parts and apply highlight to appropriate part
  const labelParts = useMemo(
    () =>
      label.split(highlightRegex).map((part, index) => {
        const shouldHighlightPart = showHighlight && highlightRegex.test(part);
        const highlighted = <mark key={part}>{part}</mark>;

        return shouldHighlightPart ? highlighted : part;
      }),
    [highlightRegex, label, showHighlight]
  );

  const template = useSelector(selectSelectedPage)?.template;
  const selected =
    asset.id === template?.id && asset.namespace === template.namespace;

  const rootClassName = classNames(className, classes.root);

  const previewClassName = classNames(classes.preview, {
    [classes.selected]: selected
  });

  const labelClassName = classNames(classes.labelText, {
    [classes.selected]: selected
  });

  function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    onSelect(id, namespace);
  }

  return (
    <Button disableRipple onClick={onClick} className={classes.button}>
      <div className={rootClassName}>
        <Preview className={previewClassName} design={design} />
        <div className={classes.labelContainer}>
          <Typography variant="subtitle2" className={labelClassName}>
            {labelParts.map((part, i) => part)}
          </Typography>
          {description && <Hint hint={description} noSpacing={true} />}
        </div>
      </div>
    </Button>
  );
};

export default SiteAssetListTile;
