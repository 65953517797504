export interface UpdateInfo {
  lastUpdateDate: string;
  lastUpdateUser: string;
  lastUpdateUserId: string;
}

export type SiteNodeType = "Directory" | "Page" | "Menu";
export type SiteNodeStage = "Unpublished" | "Published" | "Reviewed";
export type SiteNodeAction = "Create" | "Rename";
export type StageAction = "Publish" | "Unpublish";
export type ClipboardOperation = "None" | "Copy" | "Cut" | "Paste";

export type SiteStructureOperationExecuted =
  | "VIEW_HISTORY_EXECUTED"
  | "ADVANCED_SETTINGS_EXECUTED"
  | "PAGE_ACTIONS_EXECUTED"
  | "PUBLISH_BTN_EXECUTED"
  | "UNPUBLISH_BTN_EXECUTED"
  | "OPEN_PREVIEW_EXECUTED";

export class SiteNodeTypes {
  static readonly DIRECTORY = "Directory";
  static readonly PAGE = "Page";
  static readonly MENU = "Menu";
}
export class SiteNodeActions {
  static readonly CREATE = "Create";
  static readonly RENAME = "Rename";
}
export class ClipboardOperations {
  static readonly NONE = "None";
  static readonly COPY = "Copy";
  static readonly CUT = "Cut";
  static readonly PASTE = "Paste";
}
