import { Action } from "redux-actions";
import { IScrollManagementState } from "models/domainStates";

import { reducerFactory } from "redux/reducers/coreReducer";

import {
  setIsReadyToScrollIntoElement,
  setScrollManagementPayload,
  unsetScrollManagementPayload
} from "redux/actions/scrollManagementActions";
import { IScrollManagementPayload } from "models/scrollManagement";

export const initialState: IScrollManagementState = {
  scrollManagementPayload: {
    elementIdToScrollInto: undefined,
    scope: undefined,
    isReadyToScrollIntoElement: false
  }
};

const reactions = {
  [setScrollManagementPayload.toString()]: (
    state: IScrollManagementState,
    action: Action<IScrollManagementPayload>
  ) => {
    state.scrollManagementPayload = action.payload;
  },
  [unsetScrollManagementPayload.toString()]: (
    state: IScrollManagementState
  ) => {
    state.scrollManagementPayload = {
      elementIdToScrollInto: undefined,
      scope: undefined,
      isReadyToScrollIntoElement: false
    };
  },
  [setIsReadyToScrollIntoElement.toString()]: (
    state: IScrollManagementState,
    action: Action<boolean>
  ) => {
    state.scrollManagementPayload = {
      ...state.scrollManagementPayload,
      isReadyToScrollIntoElement: action.payload
    };
  }
};

export default reducerFactory(initialState, reactions);
