import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  search: {
    margin: theme.spacing(0, 3, 1, 3),
    width: "88%",
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0, 1),
      height: theme.spacing(4),
      "&:placeholder": {
        color: theme.palette.custom.ds.grayscales.gr600
      }
    }
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500
  }
}));
