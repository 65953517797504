import { takeLatest, takeEvery } from "redux-saga/effects";
import { fetchVersion } from "redux/actions/appActions";
import { rootInterceptor } from "redux/sagas/interceptors/rootInterceptor";
import {
  fetchPage,
  removeModule,
  setModuleProperty,
  setLayoutProperty,
  removeLayout,
  removePage,
  removeDirectory,
  addDirectory,
  viewPreview,
  addPage,
  publishPage,
  publishDirectory,
  unpublishPage,
  unpublishDirectory,
  fetchSiteItemsFromRoot,
  fetchSiteItems,
  changePageTemplate,
  moveModule,
  copySiteItem,
  addModule,
  moveSiteItem,
  moveLayout,
  addLayout,
  publishSiteItems,
  unpublishSiteItems,
  rollback,
  setModuleLabel,
  setModuleImportance,
  setRenameSiteItem,
  copyModule,
  checkUrlTranslationDuplicates,
  addMenu,
  fetchMenu,
  removeMenu,
  unpublishMenu,
  publishMenu,
  copyLayout,
  setSiteStructureOperationExecuted
} from "redux/actions/siteStructureActions";
import {
  fetchModules,
  fetchLayouts,
  fetchTemplates,
  fetchContexts
} from "redux/actions/assetCatalogActions";
import { issueCommand } from "redux/actions/commandActions";

import { postCommand } from "redux/sagas/command/postCommand";
import { getPage } from "redux/sagas/siteStructure/getPage";
import { deleteModule } from "redux/sagas/siteStructure/deleteModule";
import { editModuleProperty } from "redux/sagas/siteStructure/editModuleProperty";
import { deleteLayout } from "redux/sagas/siteStructure/deleteLayout";
import { getModules } from "redux/sagas/assetCatalog/getModules";
import { getLayouts } from "redux/sagas/assetCatalog/getLayouts";
import { getTemplates } from "redux/sagas/assetCatalog/getTemplates";
import { getVersion } from "redux/sagas/app/getVersion";
import { editLayoutProperty } from "redux/sagas/siteStructure/editLayoutProperty";
import { deletePage } from "redux/sagas/siteStructure/deletePage";
import { deleteDirectory } from "redux/sagas/siteStructure/deleteDirectory";
import { createDirectory } from "redux/sagas/siteStructure/createDirectory";
import { openPreview } from "redux/sagas/siteStructure/openPreview";
import { createPage } from "redux/sagas/siteStructure/createPage";
import { sendPublishPage } from "redux/sagas/siteStructure/sendPublishPage";
import { sendPublishDirectory } from "redux/sagas/siteStructure/sendPublishDirectory";
import { sendUnpublishDirectory } from "redux/sagas/siteStructure/sendUnpublishDirectory";
import { sendUnpublishPage } from "redux/sagas/siteStructure/sendUnpublishPage";
import { getSiteItems } from "redux/sagas/siteStructure/getSiteItems";
import { getSiteItemsFromRoot } from "redux/sagas/siteStructure/getSiteItemsFromRoot";
import { sendChangePageTemplate } from "redux/sagas/siteStructure/sendChangePageTemplate";
import { sendMoveModule } from "redux/sagas/siteStructure/sendMoveModule";
import { sendCopySiteItem } from "redux/sagas/siteStructure/sendCopySiteItem";
import { sendAddModule } from "redux/sagas/siteStructure/sendAddModule";
import { sendMoveSiteItem } from "redux/sagas/siteStructure/sendMoveSiteItem";
import { sendMoveLayout } from "redux/sagas/siteStructure/sendMoveLayout";
import { sendPublishSiteItems } from "redux/sagas/siteStructure/sendPublishSiteItems";
import { sendAddLayout } from "redux/sagas/siteStructure/sendAddLayout";
import { sendUnpublishSiteItems } from "redux/sagas/siteStructure/sendUnpublishSiteItems";
import {
  fetchEntitiesDefinitions,
  fetchSystemLanguages,
  fetchTagsDatasources
} from "redux/actions/forgeActions";
import { getSystemLanguages } from "redux/sagas/forge/getSystemLanguages";
import { getEntitiesDefinitions } from "redux/sagas/forge/getEntitiesDefinitions";
import { sendRollback } from "redux/sagas/siteStructure/sendRollback";
import { getTagsDatasources } from "redux/sagas/forge/getTagsDatasources";
import {
  createCustomEntityTag,
  createExternalTag,
  setTagsPickerProperty
} from "redux/actions/tagsActions";
import { sendSetTagsPickerProperty } from "redux/sagas/tags/sendSetTagsPickerProperty";
import { sendCreateCustomEntityTag } from "./tags/sendCreateCustomEntityTag";
import { sendCreateExternalTag } from "redux/sagas/tags/sendCreateExternalTag";
import { sendSetModuleLabel } from "redux/sagas/siteStructure/sendSetModuleLabel";
import { sendSetModuleImportance } from "redux/sagas/siteStructure/sendSetModuleImportance";
import { sendRenameSiteItem } from "redux/sagas/siteStructure/sendRenameSiteItem";
import { sendCopyModule } from "redux/sagas/siteStructure/sendCopyModule";
import { getFrontendSites } from "redux/sagas/frontend/getFrontendSites";
import { getFrontendCultures } from "redux/sagas/frontend/getFrontendCultures";

import {
  fetchFrontendCultures,
  fetchFrontendSites
} from "redux/actions/frontendActions";
import { checkSiteItemUrlTranslationDuplicates } from "redux/sagas/siteStructure/checkUrlTranslationDuplicates";
import { fetchUser } from "redux/actions/userActions";
import { getUserData } from "redux/sagas/user/getUserData";
import { getContexts } from "redux/sagas/assetCatalog/getContexts";
import { fetchRoutes } from "redux/actions/variablesActions";
import { getRoutes } from "redux/sagas/variables/getRoutes";
import { fetchAliases } from "redux/actions/aliasesActions";
import { getAliases } from "redux/sagas/aliases/getAliases";
import { createMenu } from "redux/sagas/siteStructure/createMenu";
import { getMenu } from "redux/sagas/siteStructure/getMenu";
import { deleteMenu } from "redux/sagas/siteStructure/deleteMenu";
import { sendUnpublishMenu } from "redux/sagas/siteStructure/sendUnpublishMenu";
import { sendPublishMenu } from "redux/sagas/siteStructure/sendPublishMenu";
import { sendCopyLayout } from "redux/sagas/siteStructure/sendCopyLayout";
import { resetSiteStructureOperationExecuted } from "redux/sagas/siteStructure/resetSiteStructureOperationExecuted";

export default function* rootSaga() {
  yield takeEvery("*", rootInterceptor);

  // FETCH
  yield takeLatest(fetchFrontendSites.toString(), getFrontendSites);
  yield takeLatest(fetchFrontendCultures.toString(), getFrontendCultures);
  yield takeLatest(fetchVersion.toString(), getVersion);
  yield takeLatest(fetchPage.toString(), getPage);
  yield takeLatest(fetchModules.toString(), getModules);
  yield takeLatest(fetchLayouts.toString(), getLayouts);
  yield takeLatest(fetchTemplates.toString(), getTemplates);
  yield takeLatest(fetchContexts.toString(), getContexts);
  yield takeLatest(fetchMenu.toString(), getMenu);

  yield takeLatest(fetchSiteItems.toString(), getSiteItems);
  yield takeLatest(fetchSiteItemsFromRoot.toString(), getSiteItemsFromRoot);

  yield takeLatest(fetchSystemLanguages.toString(), getSystemLanguages);
  yield takeLatest(fetchEntitiesDefinitions.toString(), getEntitiesDefinitions);

  yield takeLatest(fetchTagsDatasources.toString(), getTagsDatasources);

  yield takeLatest(fetchUser.toString(), getUserData);

  yield takeLatest(fetchRoutes.toString(), getRoutes);

  yield takeLatest(fetchAliases.toString(), getAliases);

  // COMMANDS
  yield takeEvery(issueCommand.toString(), postCommand);
  yield takeLatest(removeModule.toString(), deleteModule);
  yield takeLatest(setModuleProperty.toString(), editModuleProperty);
  yield takeLatest(setLayoutProperty.toString(), editLayoutProperty);
  yield takeLatest(removeLayout.toString(), deleteLayout);
  yield takeLatest(removePage.toString(), deletePage);
  yield takeLatest(removeDirectory.toString(), deleteDirectory);
  yield takeLatest(removeMenu.toString(), deleteMenu);
  yield takeLatest(addDirectory.toString(), createDirectory);
  yield takeLatest(addPage.toString(), createPage);
  yield takeLatest(addMenu.toString(), createMenu);
  yield takeLatest(rollback.toString(), sendRollback);
  yield takeLatest(publishPage.toString(), sendPublishPage);
  yield takeLatest(unpublishPage.toString(), sendUnpublishPage);
  yield takeLatest(publishMenu.toString(), sendPublishMenu);
  yield takeLatest(unpublishMenu.toString(), sendUnpublishMenu);
  yield takeLatest(publishDirectory.toString(), sendPublishDirectory);
  yield takeLatest(unpublishDirectory.toString(), sendUnpublishDirectory);
  yield takeLatest(changePageTemplate.toString(), sendChangePageTemplate);
  yield takeLatest(moveModule.toString(), sendMoveModule);
  yield takeLatest(moveLayout.toString(), sendMoveLayout);
  yield takeLatest(copySiteItem.toString(), sendCopySiteItem);
  yield takeLatest(moveSiteItem.toString(), sendMoveSiteItem);
  yield takeLatest(addModule.toString(), sendAddModule);
  yield takeLatest(addLayout.toString(), sendAddLayout);
  yield takeLatest(publishSiteItems.toString(), sendPublishSiteItems);
  yield takeLatest(unpublishSiteItems.toString(), sendUnpublishSiteItems);
  yield takeLatest(setTagsPickerProperty.toString(), sendSetTagsPickerProperty);
  yield takeLatest(createCustomEntityTag.toString(), sendCreateCustomEntityTag);
  yield takeLatest(createExternalTag.toString(), sendCreateExternalTag);
  yield takeLatest(setModuleLabel.toString(), sendSetModuleLabel);
  yield takeLatest(setModuleImportance.toString(), sendSetModuleImportance);
  yield takeLatest(setRenameSiteItem.toString(), sendRenameSiteItem);
  yield takeLatest(copyModule.toString(), sendCopyModule);
  yield takeLatest(copyLayout.toString(), sendCopyLayout);
  yield takeLatest(
    setSiteStructureOperationExecuted.toString(),
    resetSiteStructureOperationExecuted
  );

  // UNCATEGORIZED (for now?)
  yield takeLatest(viewPreview.toString(), openPreview);
  yield takeLatest(
    checkUrlTranslationDuplicates.toString(),
    checkSiteItemUrlTranslationDuplicates
  );
}
