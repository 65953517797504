import { useState, MouseEvent, useMemo } from "react";
import { translate } from "utils/i18n";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import { useStyles } from "components/linkRules/linkRulesSidebar/linkRulesSidebarFooter/styles";
import { D3Button, D3Modal } from "components/shared/d3Components";
import { Typography } from "@material-ui/core";
import { userService } from "services/userService";

export default function LinkRulesSidebarFooter() {
  const ModalTranslationKeyPrefix = "navigationrules.delete.modal";

  const { form } = useLinkRulesCrudManagementContext();

  const classes = useStyles();
  const {
    buttonsControlState,
    selectedLinkRule,
    isNewItem,
    onSave,
    onDelete,
    onEditCancel
  } = useLinkRulesCrudManagementContext();

  const [showModal, setShowModal] = useState<boolean>(false);

  const onDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowModal(true);
  };

  const onDeleteConfirm = () => {
    if (selectedLinkRule === undefined) {
      return;
    }

    onDelete(selectedLinkRule.id, selectedLinkRule.isAbsolute);
    setShowModal(false);
  };

  const onDeleteCancel = () => setShowModal(false);

  const saveButtonLabel = useMemo(() => {
    if (buttonsControlState.SAVE.isLoading) {
      return translate("general.saving");
    } else {
      return isNewItem
        ? translate("general.create_and_save")
        : translate("general.save");
    }
  }, [buttonsControlState.SAVE.isLoading, isNewItem]);

  const deleteButtonLabel = useMemo(() => {
    if (buttonsControlState.DELETE.isLoading) {
      return translate("general.deleting");
    }

    return translate("general.delete");
  }, [buttonsControlState.DELETE.isLoading]);

  const isDeleteButtonDisabled =
    !userService.getIsUserAllowedToEditNavigationRules(
      form.url,
      form.isAbsolute
    );

  return (
    <div className={classes.footer}>
      <D3Button
        disabled={buttonsControlState.CANCEL.isDisabled}
        variant="ghost"
        color="neutral"
        onClick={onEditCancel}
      >
        {translate("general.cancel")}
      </D3Button>
      <div className={classes.rightSection}>
        <D3Button
          variant="ghost"
          color="neutral"
          icon="delete_outline"
          onClick={onDeleteClick}
          disabled={isDeleteButtonDisabled}
          className={buttonsControlState.DELETE.isHidden ? classes.hidden : ""}
        >
          {deleteButtonLabel}
        </D3Button>

        <D3Button
          disabled={buttonsControlState.SAVE.isDisabled}
          onClick={onSave}
        >
          {saveButtonLabel}
        </D3Button>
      </div>

      <D3Modal
        open={showModal}
        modalTitle={translate(`${ModalTranslationKeyPrefix}.title`)}
        confirmLabel={translate(`${ModalTranslationKeyPrefix}.confirm`)}
        cancelLabel={translate(`${ModalTranslationKeyPrefix}.cancel`)}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      >
        <Typography>
          {translate(`${ModalTranslationKeyPrefix}.message`)}
        </Typography>
      </D3Modal>
    </div>
  );
}
