import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import classnames from "classnames";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    minHeight: theme.spacing(5),
    color: theme.palette.custom.ds.grayscales.gr500,
    opacity: 0.8,
    gap: theme.spacing(1)
  },
  circleIcon: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr400,
    borderRadius: "50%",
    display: "flex",
    alignContent: "center",
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white
  },
  textFont: {
    fontSize: "small"
  }
}));

export const AvailableSlot = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();

  const rootClassName = classnames(classes.root, className);

  return <div className={rootClassName} />;
};
