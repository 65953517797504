import { IBaseProps } from "components/_baseProps";
import isEmpty from "lodash/isEmpty";

import { IModule } from "models/pages";

import {
  getConvertedHTMLTextFromMarkdown,
  getStaticTextModuleTranslationForCulture
} from "components/richText/utils";
import { useEffect, useMemo, useState } from "react";

import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";
import { UxdIcon } from "components/shared/uxdIcon";
import { useSelector } from "react-redux";
import { IDomainState } from "models/domainStates";
import {
  selectFrontendCultureDirectionality,
  selectFrontendLanguageName
} from "redux/selectors/frontendSelectors";

interface IProps extends IBaseProps {
  module: IModule;
  culture: string;
  shouldFallbackWithDefault?: boolean;
}

const NO_AVAILABLE_TRANSLATION_KEY =
  "sitestructure.richtext.no_available_translation";

const getNoAvailableTranslationText = (
  culture: string,
  translationValue: string,
  shouldFallbackWithDefault: boolean,
  languageFullName: string
) => {
  const isTranslationDefined = !isEmpty(translationValue);

  if (isTranslationDefined) {
    return undefined;
  }

  if (!shouldFallbackWithDefault) {
    return null;
  }

  return (
    <span>
      {translate(NO_AVAILABLE_TRANSLATION_KEY)} <b>{languageFullName}</b>.
    </span>
  );
};

const StaticTextModule = (props: IProps) => {
  const { module, culture, shouldFallbackWithDefault = true } = props;
  const classes = useStyles();

  const [parsedHTMLValue, setParsedHTMLValue] = useState("");
  const translationValue = getStaticTextModuleTranslationForCulture(
    culture,
    module
  );

  const languageName = useSelector((state: IDomainState) =>
    selectFrontendLanguageName(state, culture)
  );

  const cultureDirectionality = useSelector((state: IDomainState) =>
    selectFrontendCultureDirectionality(state, culture)
  );

  const isTranslationDefined = !isEmpty(translationValue);
  const noAvailableTranslationText = useMemo(
    () =>
      getNoAvailableTranslationText(
        culture,
        translationValue,
        shouldFallbackWithDefault,
        languageName
      ),
    [translationValue, languageName, culture, shouldFallbackWithDefault]
  );

  useEffect(() => {
    if (!isTranslationDefined) {
      return;
    }

    getConvertedHTMLTextFromMarkdown(translationValue).then((value) => {
      setParsedHTMLValue(value);
    });
  }, [translationValue, isTranslationDefined]);

  return (
    <div className={classes.container}>
      {isTranslationDefined ? (
        <div className={classes.textRenderer}>
          <span
            style={{ direction: cultureDirectionality }}
            dangerouslySetInnerHTML={{
              __html: parsedHTMLValue
            }}
          />
        </div>
      ) : (
        <Typography className={classes.noAvailableTranslationsMessage}>
          <UxdIcon className={classes.warningIcon} name="warning" />
          <span>{noAvailableTranslationText}</span>
        </Typography>
      )}
    </div>
  );
};

export default StaticTextModule;
