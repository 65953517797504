import { call, put, delay } from "redux-saga/effects";
import { Action } from "redux-actions";
import { CommandApi } from "api/command";
import {
  setCommandStatus,
  SendCommandActionPayload
} from "redux/actions/commandActions";
import { displayNotification } from "redux/actions/notificationActions";

export function* postCommand(
  action: Action<SendCommandActionPayload>,
  waitTimeBeforeIdle: number = 3000
) {
  const {
    command,
    onSuccessAction,
    onFailureAction,
    successMessage,
    errorMessage,
    toggleNotification
  } = action.payload;
  try {
    yield put(setCommandStatus("InProgress"));

    const { success, message } = yield call(CommandApi.sendCommand, command);
    if (success) {
      if (toggleNotification) {
        yield put(
          displayNotification({
            message: message || successMessage || "",
            severity: "success"
          })
        );
      }

      yield put(setCommandStatus("Success"));
    } else {
      throw new Error(message);
    }

    if (onSuccessAction) {
      yield put(onSuccessAction);
    }

    yield delay(waitTimeBeforeIdle);
    yield put(setCommandStatus("Idle"));
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        displayNotification({
          message: errorMessage || error.message || "",
          severity: "error"
        })
      );
    }
    yield put(setCommandStatus("Failure"));
    if (onFailureAction) {
      yield put(onFailureAction);
    }
  }
}
