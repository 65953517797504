import { Button, makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedPage,
  selectSelectedPageElementId,
  selectSidebarOpen
} from "redux/selectors/siteStructureSelectors";
import {
  closeSidebar,
  openSidebar,
  unsetSelectedPageElement
} from "redux/actions/siteStructureActions";

interface IProps extends IBaseProps {
  template: ITemplateDefinition | undefined;
}

interface IStyleProps {
  shouldHighlight: boolean;
  isBlankTemplate: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    padding: theme.spacing(1.5, 4, 0)
  },
  button: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(0, 1.5),
    height: theme.spacing(3),
    border: `2px solid transparent`,
    borderRadius: theme.spacing(0.5),
    gap: theme.spacing(0.5),
    backgroundColor: (props: IStyleProps) => {
      return props.shouldHighlight
        ? theme.palette.custom.ds.viking.viking500
        : "inherit";
    },
    "&:hover": {
      backgroundColor: (props: IStyleProps) => {
        return props.shouldHighlight
          ? theme.palette.custom.ds.viking.viking700
          : theme.palette.custom.ds.viking.viking100;
      }
    },
    "&:active": {
      borderColor: (props: IStyleProps) => {
        return props.shouldHighlight
          ? "transparent"
          : theme.palette.custom.ds.viking.viking300;
      },
      backgroundColor: (props: IStyleProps) => {
        return props.shouldHighlight
          ? theme.palette.custom.ds.viking.viking900
          : theme.palette.custom.ds.viking.viking100;
      }
    }
  },
  highlight: {
    backgroundColor: theme.palette.custom.ds.viking.viking500
  },
  prefix: {
    padding: theme.spacing(0, 0.5),
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(12),
    color: (props: IStyleProps) => {
      return props.shouldHighlight
        ? theme.palette.common.white
        : theme.palette.custom.ds.grayscales.gr800;
    }
  },
  template: {
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    color: (props: IStyleProps) => {
      if (props.shouldHighlight) {
        return theme.palette.common.white;
      }

      if (props.isBlankTemplate) {
        return theme.palette.custom.ds.grayscales.gr800;
      }

      return theme.palette.custom.ds.viking.viking700;
    }
  },
  icon: {
    fontSize: theme.typography.pxToRem(16),
    color: (props: IStyleProps) => {
      if (props.shouldHighlight) {
        return theme.palette.common.white;
      }

      if (props.isBlankTemplate) {
        return theme.palette.custom.ds.grayscales.gr600;
      }

      return theme.palette.custom.ds.viking.viking500;
    }
  }
}));

export const TemplateActionsBar = (props: IProps) => {
  const { template: definition } = props;
  const dispatch = useDispatch();

  const page = useSelector(selectSelectedPage);
  const isSidebarOpen = useSelector(selectSidebarOpen);
  const selectedElementId = useSelector(selectSelectedPageElementId);

  // should match when looking at the current template in the sidebar
  const isTemplateSelected =
    selectedElementId === null ||
    selectedElementId === page?.template.instanceId;

  const shouldHighlight = isSidebarOpen && isTemplateSelected;
  const isBlankTemplate = definition?.id === "No Template";

  const classes = useStyles({ shouldHighlight, isBlankTemplate });

  const onClick = () => {
    const action = shouldHighlight ? closeSidebar() : openSidebar();

    // clearing the selected page element causes `PageDetail` to default to template
    // so the sidebar opens showing the templates detail.
    dispatch(unsetSelectedPageElement());
    dispatch(action);
  };

  return (
    <div className={classes.root}>
      <Button className={classes.button} onClick={onClick} disableRipple>
        <UxdIcon name="auto_awesome_mosaic" className={classes.icon} />
        <Typography className={classes.prefix}>
          {translate("sitestructure.template")}:
        </Typography>
        <Typography className={classes.template}>
          {definition?.label ?? ""}
        </Typography>
      </Button>
    </div>
  );
};
