import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export interface ICultureInfoProps {
  culture: string;
}

export const CultureInfo = (props: Readonly<ICultureInfoProps>) => {
  const classes = useStyles();
  const { culture } = props;

  return (
    <div className={classes.container}>
      <Typography className={classes.culture}>{culture}</Typography>
    </div>
  );
};

export default CultureInfo;
