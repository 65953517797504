import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none"
  },
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    borderTop: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  },
  listArea: {
    width: theme.spacing(47),
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: theme.palette.common.white,
    borderLeft: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  },
  buttonArea: {
    width: theme.spacing(8),
    display: "flex",
    flexDirection: "column"
  },
  buttonSeparator: {
    margin: 0,
    color: theme.palette.common.white,
    borderTop: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  },
  hack: {
    flex: 1,
    borderLeft: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  }
}));
