import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "inherit",
    minHeight: theme.spacing(6),
    boxSizing: "border-box",
    maxHeight: theme.spacing(10.5),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
    transition: "background-color .3s ease-out",
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr200
    },
    "&:hover $buttons": {
      display: "flex" /* make target visible */,
      transition: "opacity 0.2s ease-out",
      opacity: 1
    },
    "&:hover $info": {
      display: "none",
      transition: "opacity 0.2s ease-out",
      opacity: 0
    }
  },
  topLane: {
    height: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.custom.ds.grayscales.gr800,
    "& p": {
      fontSize: `${theme.typography.pxToRem(14)}`
    },
    "& label": {
      fontSize: `${theme.typography.pxToRem(12)}`
    }
  },
  bottomLane: {
    height: theme.spacing(4),
    overflow: "hidden",
    marginTop: theme.spacing(0.5),
    color: theme.palette.custom.ds.grayscales.gr600,
    fontSize: `${theme.typography.pxToRem(12)}`,
    "& p": {
      padding: "0px",
      margin: "0px"
    }
  },
  topRightSection: {
    position: "relative"
  },
  buttons: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    display: "none" /* toggled on parent hover */,
    opacity: 0,
    alignItems: "center",
    justifyContent: "center"
  },
  hiddenRichTextEditor: {
    display: "none",
    width: "0px",
    height: "0px",
    border: "none"
  },
  controlButton: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(0.5)
  },

  info: {}
}));
