import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    }
  }
}));
