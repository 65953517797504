import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "utils/i18n";
import classnames from "classnames";

import {
  selectIsRichTextModalOpened,
  selectRichTextTranslationForm
} from "redux/selectors/richTextSelectors";
import { disposeRichTextModal } from "redux/actions/richTextActions";

import { D3Modal } from "components/shared/d3Components/d3Modal";
import { IBaseProps } from "components/_baseProps";
import TinyMCEEditor from "components/shared/TinyMCEEditor";

import { useRichTextManagementContext } from "components/richText/context";

import { isEmpty } from "lodash";
import { useStyles } from "./styles";
import { Box, Typography } from "@material-ui/core";
import {
  selectFrontendCultureDirectionality,
  selectFrontendLanguageName
} from "redux/selectors/frontendSelectors";
import { IDomainState } from "models/domainStates";
import WordCount from "components/richText/components/wordCount";
import {
  getRichTextEditorInstanceById,
  getWordCountFromRichTextEditorContent
} from "components/richText/utils";

interface IProps extends IBaseProps {}

interface ILeaveEditingDialogPayload {
  areThereRichTextContentChanges: boolean;
  isLeaveEditingDialogOpen: boolean;
}

const CancelDialogTranslationKeyPrefix =
  "navigationrules.sidebar.dialog.cancel";

const initLeaveEditingDialogPayload = (
  entry?: Partial<ILeaveEditingDialogPayload>
): ILeaveEditingDialogPayload => ({
  areThereRichTextContentChanges: false,
  isLeaveEditingDialogOpen: false,
  ...(entry ?? {})
});

export function RichTextModal(props: IProps) {
  const { className } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const rootClassName = classnames(classes.root, className);
  const isAddRichTextModalOpened = useSelector(selectIsRichTextModalOpened);
  const form = useSelector(selectRichTextTranslationForm)!;
  const { culture, value } = form;

  const [leaveEditingDialogPayload, setLeaveEditingDialogPayload] =
    useState<ILeaveEditingDialogPayload>(initLeaveEditingDialogPayload());

  const [initialRichTextContextValue, setInitialRichTextContextValue] =
    useState<string | undefined>(undefined);

  useEffect(() => {
    if (initialRichTextContextValue === undefined) {
      setInitialRichTextContextValue(value ?? "");
    }
  }, [value, initialRichTextContextValue]);

  const FIELD_ID = "add-rich-text";

  const [wordCount, setWordCount] = useState<number>(0);

  const { onRichTextTranslationChange, onSubmitTranslation, loaders } =
    useRichTextManagementContext();

  const onHandleRichTextTranslationChange = useCallback(
    (updatedValue) => {
      onRichTextTranslationChange(updatedValue);

      const areThereRichTextContentChanges =
        initialRichTextContextValue !== updatedValue;

      setLeaveEditingDialogPayload((current) => ({
        ...current,
        areThereRichTextContentChanges
      }));
    },
    [initialRichTextContextValue, onRichTextTranslationChange]
  );

  const onSetContent = () => {
    const instance = getRichTextEditorInstanceById(FIELD_ID);

    if (instance !== undefined) {
      const count = getWordCountFromRichTextEditorContent(instance);
      setWordCount(count);
    }
  };

  const languageName = useSelector((state: IDomainState) =>
    selectFrontendLanguageName(state, culture)
  );

  const cultureDirectionality = useSelector((state: IDomainState) =>
    selectFrontendCultureDirectionality(state, culture)
  );

  const modalTitleElement = (
    <Box display="flex">
      <Typography className={classes.title} component="label">
        {languageName}
      </Typography>
    </Box>
  );

  const onCloseModal = useCallback(
    () => dispatch(disposeRichTextModal()),
    [dispatch]
  );

  const onHandleCloseModal = useCallback(() => {
    if (leaveEditingDialogPayload.areThereRichTextContentChanges) {
      setLeaveEditingDialogPayload((current) => ({
        ...current,
        isLeaveEditingDialogOpen: true
      }));
      return;
    }

    onCloseModal();
  }, [onCloseModal, leaveEditingDialogPayload.areThereRichTextContentChanges]);

  const onConfirmLeaveEditingModal = useCallback(
    () => onCloseModal(),
    [onCloseModal]
  );
  const onCancelLeaveEditingModal = useCallback(() => {
    setLeaveEditingDialogPayload(
      initLeaveEditingDialogPayload({ areThereRichTextContentChanges: true })
    );
  }, []);

  const verb = form.isNew ? "create" : "save";
  const confirmLabel = loaders.isSettingModuleTranslatableProperties
    ? translate("sitestructure.loading")
    : translate(`general.${verb}`);

  const isConfirmDisabled =
    isEmpty(value) || loaders.isSettingModuleTranslatableProperties;

  return (
    <>
      <D3Modal
        maxWidth="xl"
        className={rootClassName}
        contentClassname={classes.modalContent}
        open={isAddRichTextModalOpened}
        modalTitle={modalTitleElement}
        cancelLabel={translate("general.cancel")}
        confirmLabel={confirmLabel}
        onConfirm={() => onSubmitTranslation(form)}
        disabled={isConfirmDisabled}
        onCancel={onHandleCloseModal}
        additionalFooterElement={
          <WordCount wordCount={wordCount} className={classes.wordCount} />
        }
      >
        <TinyMCEEditor
          className={classes.editorContainer}
          fieldId={FIELD_ID}
          isDisabled={false}
          value={value}
          onChange={onHandleRichTextTranslationChange}
          directionality={cultureDirectionality}
          onSetContent={onSetContent}
        />
      </D3Modal>

      <D3Modal
        open={leaveEditingDialogPayload.isLeaveEditingDialogOpen}
        modalTitle={translate(`${CancelDialogTranslationKeyPrefix}.title`)}
        confirmLabel={translate(`${CancelDialogTranslationKeyPrefix}.confirm`)}
        cancelLabel={translate(`${CancelDialogTranslationKeyPrefix}.cancel`)}
        onConfirm={onConfirmLeaveEditingModal}
        onCancel={onCancelLeaveEditingModal}
      >
        <Typography>
          {translate(`${CancelDialogTranslationKeyPrefix}.message`)}
        </Typography>
      </D3Modal>
    </>
  );
}
