import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IRichTextEntry } from "models/richText";
import { useStyles } from "./styles";
import TinyMCEEditor from "components/shared/TinyMCEEditor";

import {
  getRichTextEditorInstanceById,
  getWordCountFromRichTextEditorContent
} from "components/richText/utils";
import { IDomainState } from "models/domainStates";
import {
  selectFrontendCultureDirectionality,
  selectFrontendLanguageName
} from "redux/selectors/frontendSelectors";
import DeleteButton from "./deleteButton";
import CultureInfo from "./cultureInfo";
import TranslationInfo from "./translationInfo";
import TranslateButton from "./translateButton";
import UnsetModal from "./unsetModal";

interface Props extends IBaseProps {
  richTextEntry: IRichTextEntry;
}

const HIDDEN_EDITOR_PREFIX_KEY = "hidden-editor-";
const parser = new DOMParser();

const stripMarkup = (markup: string) =>
  parser.parseFromString(markup, "text/html").documentElement.innerText;

export const TranslationPropertyItem = (props: Props) => {
  const classes = useStyles();
  const { richTextEntry } = props;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [wordCount, setWordCount] = useState<undefined | number>(undefined);
  const {
    culture,
    convertedText,
    hasTranslation,
    value: markdownValue
  } = richTextEntry;

  const hiddenEditorFieldId = `${HIDDEN_EDITOR_PREFIX_KEY}${culture}`;

  const languageName = useSelector((state: IDomainState) =>
    selectFrontendLanguageName(state, culture)
  );

  const cultureDirectionality = useSelector((state: IDomainState) =>
    selectFrontendCultureDirectionality(state, culture)
  );

  const stringifiedRichContent = useMemo(
    () => stripMarkup(convertedText),
    [convertedText]
  );

  const onSetContent = useCallback(() => {
    const editorInstance = getRichTextEditorInstanceById(hiddenEditorFieldId);

    if (editorInstance === undefined) {
      return;
    }

    const result = getWordCountFromRichTextEditorContent(editorInstance);

    setWordCount(result);
  }, [hiddenEditorFieldId]);

  return (
    <div key={culture} className={classes.container}>
      <div className={classes.topLane}>
        <CultureInfo culture={languageName} />
        <div className={classes.topRightSection}>
          <TranslationInfo
            className={classes.info}
            hasTranslation={hasTranslation}
            wordCount={wordCount}
          />
          <div className={classes.buttons}>
            <TranslateButton
              hasTranslation={hasTranslation}
              text={markdownValue}
              culture={culture}
            />
            {hasTranslation && (
              <DeleteButton onClick={() => setIsDeleteModalOpen(true)} />
            )}
          </div>
        </div>
      </div>

      <UnsetModal
        open={isDeleteModalOpen}
        cultureCode={culture}
        cultureName={languageName}
        onClose={() => setIsDeleteModalOpen(false)}
      />

      {hasTranslation && (
        <>
          <TinyMCEEditor
            className={classes.hiddenRichTextEditor}
            fieldId={hiddenEditorFieldId}
            isDisabled={true}
            isHtml={true}
            onChange={() => {}}
            onSetContent={onSetContent}
            value={convertedText}
          />
          <Typography
            className={classes.bottomLane}
            component="span"
            style={{ direction: cultureDirectionality }}
          >
            {stringifiedRichContent}
          </Typography>
        </>
      )}
    </div>
  );
};

export default TranslationPropertyItem;
