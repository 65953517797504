import { Tooltip, Typography } from "@material-ui/core";
import { IRichTextTranslationForm } from "models/richText";
import { useDispatch } from "react-redux";
import { triggerRichTextModal } from "redux/actions/richTextActions";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";
import { useStyles as useContainerStyles } from "../styles";
import { D3Button } from "components/shared/d3Components";
import classNames from "classnames";

export interface ITranslateButtonProps {
  hasTranslation: boolean;
  culture: string;
  text: string;
}

export const TranslateButton = (props: Readonly<ITranslateButtonProps>) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const { hasTranslation, culture, text } = props;

  const verb = hasTranslation ? "edit" : "create";
  const tooltip = translate(`sitestructure.richtext_sidebar.toolbox.${verb}`);
  const label = hasTranslation ? "" : translate(`general.${verb}`);

  const icon = hasTranslation ? "font_download" : "translate";

  const action: IRichTextTranslationForm = {
    isNew: !hasTranslation,
    culture,
    value: text,
    shouldSidebarBeOpen: true
  };

  const buttonClassnames = classNames(
    classes.button,
    containerClasses.controlButton
  );

  return (
    <Tooltip title={tooltip} arrow={true} placement="top">
      <span>
        <D3Button
          icon={icon}
          iconPosition="left"
          variant="ghost"
          color="neutral"
          size="medium"
          onClick={() => dispatch(triggerRichTextModal(action))}
          className={buttonClassnames}
        >
          {!hasTranslation && (
            <Typography component="label" className={classes.label}>
              {label}
            </Typography>
          )}
        </D3Button>
      </span>
    </Tooltip>
  );
};

export default TranslateButton;
