import React, { useCallback, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { makeStyles, Paper } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { setCurrentRoute } from "redux/actions/appActions";
import {
  closeHistory,
  fetchSiteItemsFromRoot,
  openHistory,
  resetPageRequiredModuleWarning,
  setSiteStructureOperationExecuted
} from "redux/actions/siteStructureActions";
import { siteStructureRoute } from "routes/routes";
import { useParams, RouteComponentProps } from "react-router-dom";
import {
  makeSelectSiteItemByPath,
  pageIsToReload,
  selectHasSelectedNode,
  selectIsSiteStructureSearchViewOpen,
  selectSelectedPage
} from "redux/selectors/siteStructureSelectors";
import { CollapsiblePanel } from "components/shared/collapsiblePanel";
import Tree from "./tree";
import { SiteStructureHeader } from "./header/siteStructureHeader";
import { translate } from "utils/i18n";
import ErrorPage from "components/errorPages";
import {
  useMemoSelector,
  useNotificationGroup,
  usePersistedState,
  useRequestStatus,
  useTabSystem
} from "components/hooks";
import notificationService from "services/notificationService";
import { SiteStructureSidebarContext } from "components/contexts/siteStructureSidebarContext";
import { SiteStructureNotificationListener } from "components/siteStructure/siteStructureNotificationListener";
import { EMPTY_ROOT_ITEM } from "models/siteItem";
import { SiteItemClipboardContextProvider } from "components/contexts/siteItemClipboardContext";
import History from "components/siteStructure/history";
import { IDomainState } from "models/domainStates";
import { CopyPageElementContextProvider } from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { RenameSiteItemModal } from "components/siteStructure/modals/renameSiteItemModal/renameSiteItemModal";
import { CreateSiteItemModal } from "components/siteStructure/modals/createSiteItemModal/createSiteItemModal";
import { TabContext } from "@material-ui/lab";
import { SiteStructureTabPanels } from "components/siteStructure/siteStructureTabs";
import { useAuthGroupList } from "api/queryHooks";
import { loadAuthorizationGroups } from "redux/actions/authorizationGroupsActions";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import OverlayPortalComponent from "components/overlay-portal";
import UrlsAndAliases from "components/siteStructure/aliases";
import {
  UrlsAndAliasesContextProvider,
  useUrlsAndAliasesContext
} from "components/siteStructure/aliases/context";
import { SiteNodeTypes } from "models/siteStructure";
import { DeleteSiteItemModal } from "components/siteStructure/modals/deleteSiteItemModal";
import { PasteSiteItemModal } from "components/siteStructure/modals/pasteSiteItemModal";
import { themeSizing } from "theme/deltatreTheme";

interface IRouterProps {
  path: string;
}

interface IProps extends IBaseProps, RouteComponentProps<IRouterProps> {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex"
  },
  mainContent: {
    flex: 2,
    display: "flex",
    flexDirection: "column"
  },
  header: {
    minHeight: theme.spacing(8)
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden"
  },
  treeView: {
    width: "100%",
    padding: theme.spacing(1, 2, 1, 1)
  },
  treeContainer: {
    width: theme.spacing(36),
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    position: "relative"
  },
  fullScreenSearchView: {
    width: `calc(100vw - ${themeSizing.drawer}px) !important`,
    maxWidth: "none !important"
  },
  sidebarContainer: {
    zIndex: 2
  },
  error: {
    flex: 3
  },
  progress: {
    alignSelf: "center",
    margin: "auto"
  }
}));

const SiteStructureComponent = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const sidebarContainerRef = React.useRef(null);

  const { path } = useParams<{ path: string }>();
  const [shouldFetch, setShouldFetch] = useState(true);
  const currentTab = useTabSystem();

  const authGroupRequest = useAuthGroupList();

  const page = useSelector(selectSelectedPage);
  const reloadPage = useSelector(pageIsToReload);
  const isSiteStructureSearchViewOpen = useSelector(
    selectIsSiteStructureSearchViewOpen
  );

  const dispatch = useDispatch();

  const currentSiteItem =
    useMemoSelector(makeSelectSiteItemByPath, path) || EMPTY_ROOT_ITEM;

  useEffect(() => {
    dispatch(
      setCurrentRoute({
        name: siteStructureRoute.name,
        path: siteStructureRoute.path
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (shouldFetch) {
      batch(() => {
        dispatch(fetchSiteItemsFromRoot({ path }));
        dispatch(loadAuthorizationGroups(authGroupRequest.data || []));
      });
      setShouldFetch(false);
    }
  }, [authGroupRequest.data, shouldFetch, path, dispatch]);

  useEffect(() => {
    setShouldFetch(true);
  }, [reloadPage]);

  useEffect(() => {
    dispatch(resetPageRequiredModuleWarning());
  }, [path, dispatch]);

  const [showTreeView, setShowTreeView] = usePersistedState(
    "sitestructure.showTreeView",
    true
  );

  const { isOverlayPortalOpened, toggleOverlayPortal } =
    useUrlsAndAliasesContext();

  useNotificationGroup(notificationService.VSM_GROUP_NAME);

  const request = useRequestStatus(fetchSiteItemsFromRoot, { path });

  const hasSelectedNode = useSelector(selectHasSelectedNode);

  const historyOpen = useSelector(
    (state: IDomainState) => state.siteStructure.historyOpen
  );
  const dispatchCloseHistory = useCallback(
    () => dispatch(closeHistory()),
    [dispatch]
  );

  const onDispatchOpenHistory = useCallback(() => {
    dispatch(setSiteStructureOperationExecuted("VIEW_HISTORY_EXECUTED"));
    dispatch(openHistory());
  }, [dispatch]);

  if (request.error && !shouldFetch) {
    return <ErrorPage error={request.error} className={classes.error} />;
  }

  if (
    !shouldFetch &&
    !request.isLoading &&
    currentSiteItem === EMPTY_ROOT_ITEM
  ) {
    return <ErrorPage error="Not Found" className={classes.error} />;
  }

  const treeContainerClassnames = classnames(classes.treeContainer, {
    [classes.fullScreenSearchView]: isSiteStructureSearchViewOpen
  });

  return (
    <div className={rootClassName}>
      <TabContext value={currentTab}>
        <SiteStructureNotificationListener currentNode={currentSiteItem} />
        <SiteItemClipboardContextProvider>
          <CopyPageElementContextProvider pageId={page?.id ?? ""}>
            <CollapsiblePanel
              className={treeContainerClassnames}
              open={showTreeView}
              onToggle={() => setShowTreeView(!showTreeView)}
              handlePosition="right"
              handleText={translate("sitestructure.sitemapTree")}
            >
              <Tree
                className={classes.treeView}
                path={path}
                isLoading={request.isLoading}
              />
            </CollapsiblePanel>
            <div className={classes.mainContent}>
              <SiteStructureHeader
                className={classes.header}
                node={currentSiteItem}
                isLoading={request.isLoading}
                onHistoryClick={onDispatchOpenHistory}
              />
              <div className={classes.body}>
                <CurrentSiteItemContext.Provider value={currentSiteItem.path}>
                  <SiteStructureSidebarContext.Provider
                    value={sidebarContainerRef}
                  >
                    <SiteStructureTabPanels
                      currentSiteItem={currentSiteItem}
                      isLoading={request.isLoading}
                    />
                  </SiteStructureSidebarContext.Provider>
                  <History
                    open={historyOpen}
                    onClose={dispatchCloseHistory}
                    nodeId={currentSiteItem.nodeId}
                    nodeType={currentSiteItem.nodeType}
                  />
                </CurrentSiteItemContext.Provider>
              </div>
            </div>
            <RenameSiteItemModal />
            <CreateSiteItemModal />
            <DeleteSiteItemModal />
            <PasteSiteItemModal />
          </CopyPageElementContextProvider>
        </SiteItemClipboardContextProvider>
        {currentSiteItem.nodeType === SiteNodeTypes.PAGE && hasSelectedNode && (
          // conditional rendering will be removed when directory detail will be implemented
          // using the actual detail retrieved from the api, in that case both Pages and Directories
          // will have a sidebar (first condition) and there will always be a selected node (second condition)
          <Paper
            className={classes.sidebarContainer}
            elevation={2}
            ref={sidebarContainerRef}
          />
        )}
      </TabContext>
      {isOverlayPortalOpened && (
        <OverlayPortalComponent>
          <UrlsAndAliases onClose={toggleOverlayPortal} />
        </OverlayPortalComponent>
      )}
    </div>
  );
};

export const SiteStructure = (props) => (
  <UrlsAndAliasesContextProvider>
    <SiteStructureComponent {...props} />
  </UrlsAndAliasesContextProvider>
);
