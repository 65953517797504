import { ISiteItem, ISiteItemWithParentPayload } from "models/siteItem";
import { Action } from "redux-actions";
import {
  closeDeleteModal,
  disposeDeleteSiteItemParentNode,
  openDeleteModal
} from "redux/actions/siteItem/deleteSiteItemActions";
import { reducerFactory } from "redux/reducers/coreReducer";

export interface IDeleteSiteItemState {
  open: boolean;
  node: ISiteItem | null;
  parentNode: ISiteItem | null;
}

export const initialState: IDeleteSiteItemState = {
  open: false,
  node: null,
  parentNode: null
};

const reactions = {
  [openDeleteModal.toString()]: (
    state: IDeleteSiteItemState,
    action: Action<ISiteItemWithParentPayload>
  ) => {
    state.open = true;
    const { node, parentNode } = action.payload;
    state.node = node;
    state.parentNode = parentNode;

    return state;
  },

  [closeDeleteModal.toString()]: (state: IDeleteSiteItemState) => {
    state.open = false;
    state.node = null;

    return state;
  },

  [disposeDeleteSiteItemParentNode.toString()]: (
    state: IDeleteSiteItemState
  ) => {
    state.parentNode = null;

    return state;
  }
};

export default reducerFactory(initialState, reactions);
