import { IRichTextConfiguration } from "models/configuration";

const renderValueIf = (shouldRender: boolean, value: string) =>
  shouldRender ? `${value} ` : "";

export const initConfig: any = {
  branding: false,
  paste_data_images: false,
  forced_root_blocks: "br",
  block_formats:
    "Normal=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5;",
  // markdown strips any custom class we provide to an anchor
  // we use it to give a special color to mentions
  content_style:
    "a[href*='#/wcm/custom'] {  margin: 0 2px;  padding: 4px;  border-radius: 8px;  background-color: #eee; outline: unset;  color: #337ab7;  text-decoration: none;}",
  document_base_url: document.referrer,
  elementpath: false,
  menubar: false,
  powerpaste_word_import: "clean",
  powerpaste_googledocs_import: "clean",
  powerpaste_html_import: "clean",
  relative_urls: false,
  resize: false,
  toolbar_mode: "sliding",
  formats: {
    removeformat: [
      // Configures `clear formatting` to remove specified elements regardless of its attributes
      {
        selector: "b,strong,em,i,u,strike,s,sub,sup,code,blockquote,a",
        remove: "all",
        split: true,
        expand: false,
        deep: true
      },
      {
        selector: "span",
        attributes: ["style"],
        remove: "empty",
        split: true,
        expand: false,
        deep: true
      },
      { block: "h1", remove: "all" },
      { block: "h2", remove: "all" },
      { block: "h3", remove: "all" },
      { block: "h4", remove: "all" },
      { block: "h5", remove: "all" },
      { block: "ol", remove: "all" },
      { block: "ul", remove: "all" },
      { block: "li", remove: "all" }
    ]
  }
};

export const setupTinyPlugins = (config: IRichTextConfiguration) => {
  return `link lists powerpaste emoticons formatpainter charmap casechange wordcount ${
    config.SpellCheckConfiguration.IsEnabled ? "tinymcespellchecker" : ""
  }`;
};

const undoRedoToolbar = (config: IRichTextConfiguration) => {
  const { Undo, Redo } = config.TextEditorConfiguration;

  const shouldRenderDivider = Undo || Redo;

  const plugins = [
    renderValueIf(Undo, "undo"),
    renderValueIf(Redo, "redo"),
    renderValueIf(shouldRenderDivider, " | ")
  ];

  return plugins.join("");
};

const quoteToolbar = (config: IRichTextConfiguration) => {
  const isQuoteEnabled = config.TextEditorConfiguration.Quote;
  return renderValueIf(isQuoteEnabled, "blockquote ");
};

const emojiToolbar = (config: IRichTextConfiguration) => {
  const { Emojis } = config.TextEditorConfiguration;
  return renderValueIf(Emojis, "emoticons ");
};

const formatToolbar = (config: IRichTextConfiguration) => {
  const {
    Capitalization,
    ClearFormatting,
    FormatPainter,
    Strikethrough,
    Superscript,
    Subscript,
    Symbols
  } = config.TextEditorConfiguration;

  const toolbarPlugins = [
    renderValueIf(Capitalization, "casechange | "),
    renderValueIf(ClearFormatting, "removeformat"),
    renderValueIf(FormatPainter, "formatpainter"),
    renderValueIf(Strikethrough, "strikethrough"),
    renderValueIf(Superscript, "superscript"),
    renderValueIf(Subscript, "subscript"),
    renderValueIf(Symbols, "charmap")
  ];

  return toolbarPlugins.join("");
};

export const setupTinyToolbar = (config: IRichTextConfiguration) => {
  const toolbarPlugins = [
    undoRedoToolbar(config),
    "blocks | bold italic | ",
    quoteToolbar(config),
    "link ",
    emojiToolbar(config),
    "| numlist bullist | ",
    formatToolbar(config)
  ];

  return toolbarPlugins.join("");
};
