import { useState, useEffect, useMemo, useCallback } from "react";
import { MarkdownHtmlConverter } from "components/converters/markdownHtmlConverter";

export const useTinyManager = (
  isHtml: boolean,
  onChange: (value: string) => void,
  onSetContent?: () => void,
  value?: string
) => {
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [wasContentUpdated, setWasContentUpdated] = useState(false);

  useEffect(() => {
    if (wasContentUpdated) {
      return;
    }

    if (isHtml) {
      setHtmlContent(value ?? "");
      return;
    }

    MarkdownHtmlConverter.convertMarkdownToHtml(value ?? "").then(
      (currentHtmlValue) => {
        setHtmlContent(currentHtmlValue);
      }
    );

    setWasContentUpdated(true);
  }, [wasContentUpdated, value, isHtml]);

  const markdownContent = useMemo(
    () => MarkdownHtmlConverter.convertHtmlToMarkdown(htmlContent),
    [htmlContent]
  );

  const onEditorContentChange = useCallback(
    (editorCurrentValue: string) => {
      setHtmlContent(editorCurrentValue);

      if (onSetContent) {
        onSetContent();
      }

      const markdownvalue =
        MarkdownHtmlConverter.convertHtmlToMarkdown(editorCurrentValue);

      onChange(markdownvalue);
    },
    [onChange, onSetContent]
  );

  return {
    htmlContent,
    markdownContent,
    onEditorContentChange
  };
};
