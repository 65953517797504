import { Box } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import "../../../style/tinymce.css";
import {
  LICENSE_KEY,
  TINYMCE_SCRIPT_SRC,
  richTextConfig
} from "components/shared/TinyMCEEditor/config/constants";
import { useTinyManager } from "components/shared/TinyMCEEditor/hooks/useTinyManager";
import {
  setupTinyPlugins,
  initConfig,
  setupTinyToolbar
} from "components/shared/TinyMCEEditor/config";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "./styles";
import classNames from "classnames";
import { Directionality } from "components/shared/TinyMCEEditor/types";

interface IDispatch {
  onChange: (value: string) => void;
}

interface IProps extends IBaseProps, IDispatch {
  fieldId: string;
  value: string | undefined;
  isDisabled: boolean;
  shouldShowStatusBar?: boolean;
  isHtml?: boolean;
  onSetContent?: () => void;
  directionality?: Directionality;
}

const TinyMCEEditor = (props: IProps) => {
  const {
    fieldId,
    className,
    isDisabled,
    value,
    shouldShowStatusBar,
    isHtml,
    directionality = "ltr",
    onSetContent,
    onChange
  } = props;
  const classes = useStyles();
  const { htmlContent, onEditorContentChange } = useTinyManager(
    Boolean(isHtml),
    onChange,
    onSetContent,
    value
  );

  const containerBoxClassnames = classNames(classes.container, className);

  return (
    <Box className={containerBoxClassnames}>
      <Editor
        value={htmlContent}
        id={fieldId}
        onSetContent={onSetContent}
        tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
        licenseKey={LICENSE_KEY}
        init={{
          directionality,
          width: "100%",
          height: "100%",
          ...initConfig,
          plugins: setupTinyPlugins(richTextConfig),
          toolbar: setupTinyToolbar(richTextConfig),
          statusbar: Boolean(shouldShowStatusBar),
          contextmenu: false
        }}
        disabled={isDisabled}
        onEditorChange={onEditorContentChange}
      />
    </Box>
  );
};

export default TinyMCEEditor;
