import { InputAdornment, TextField } from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import { useStyles } from "./styles";
import { translate } from "utils/i18n";
import { ChangeEvent } from "react";
import { useDebounce } from "components/hooks";
import { SiteAssetType } from "models/siteAssets/siteAssetTypes";

const ASSET_TYPE_MAP: Record<
  Extract<SiteAssetType, "modules" | "layouts">,
  string
> = {
  modules: "module",
  layouts: "layout"
};

export interface ISiteAssetSearchProps {
  type: Extract<SiteAssetType, "modules" | "layouts">;
  onSearch: (value: string) => void;
}

export const SiteAssetSearch = (props: ISiteAssetSearchProps) => {
  const classes = useStyles();
  const { type, onSearch } = props;

  const onChange = useDebounce(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onSearch(event.target.value.trim().toLowerCase()),
    300
  );

  const adornment = (
    <InputAdornment position="start">
      <UxdIcon className={classes.icon} name="search" />
    </InputAdornment>
  );

  return (
    <TextField
      className={classes.search}
      variant="outlined"
      onChange={onChange}
      placeholder={translate(`sitestructure.search${ASSET_TYPE_MAP[type]}`)}
      InputProps={{ startAdornment: adornment }}
    />
  );
};

export default SiteAssetSearch;
