import { IAddMenuItemBody } from "@d3-forge/forge-commands";
import {
  concat,
  find,
  flatMap,
  includes,
  invoke,
  map,
  max,
  reduce
} from "lodash";
import { v4 as uuid } from "uuid";
import {
  IMenuItemPlacementModeMetadata,
  IMenuItemPositionMetadata,
  MAX_MENU_DEPTH,
  MenuBuilderLoaders,
  PlacementModePurpose,
  PlacementModeSegment
} from "components/siteStructure/menuView/types";
import { IMenuItem } from "models/menus";
import { translate } from "utils/i18n";

export const getMenuItemsCount = (menuItems: IMenuItem[]): number => {
  if (menuItems.length === 0) {
    return 0;
  }

  const descendantsCount = reduce(
    menuItems,
    (sum, { items }) => sum + getMenuItemsCount(items),
    0
  );

  return menuItems.length + descendantsCount;
};

export const getMenuItemDepth = (menuItem: IMenuItem): number => {
  if (menuItem.items.length === 0) {
    return 1;
  }

  const maxDepthFromChildren = max(map(menuItem.items, getMenuItemDepth)) ?? 1;

  return 1 + maxDepthFromChildren;
};

export const getMenuItemDescendants = (menuItem: IMenuItem): string[] =>
  flatMap(menuItem.items, (descendantMenuItem) =>
    concat(descendantMenuItem.id, getMenuItemDescendants(descendantMenuItem))
  );

export const getMenuItemsCountTranslationKey = (menuItems: IMenuItem[]) => {
  if (menuItems.length === 0) {
    return "";
  }

  const menuItemsCountTranslationKeyPrefix =
    "sitestructure.menu_items.content.menu_items_count";

  const menuItemsCount = getMenuItemsCount(menuItems);

  const suffix = menuItemsCount === 1 ? "singular" : "plural";

  return `${menuItemsCountTranslationKeyPrefix}.${suffix}`;
};

export const createNewMenuItemObject = (): IMenuItem => ({
  id: uuid(),
  name: "",
  tag: "",
  label: "New menu item",
  tooltip: "",
  link: "",
  icon: "",
  opensInNewTab: false,
  enabled: true,
  visible: "true",
  data: "",
  customProperties: "",
  items: []
});

export const buildItemCommandProperties = (
  item: IMenuItem,
  ignoreName: boolean = false
) => {
  const properties = {
    Tag: item.tag,
    Text: item.label,
    ToolTip: item.tooltip,
    Link: item.link,
    Icon: item.icon,
    Target: item.opensInNewTab.toString() === "true" ? "_blank" : "",
    Enabled: String(item.enabled),
    Visible: String(item.visible),
    Data: item.data,
    CustomProperties: item.customProperties
  };

  if (!ignoreName) {
    properties["Name"] = item.name?.trim() ?? "";
  }

  return properties;
};

export const constructMenuItemBody = (
  menuItem: IMenuItem,
  menuId: string,
  position: number,
  parentId?: string
): IAddMenuItemBody => ({
  menuId,
  parentId,
  itemId: menuItem.id,
  position,
  properties: buildItemCommandProperties(menuItem, true)
});

export const getMenuItemsPositionMetadataList = (
  menuItems: IMenuItem[],
  parentId: string | null = null,
  level = 0
): IMenuItemPositionMetadata[] => {
  if (menuItems.length === 0) {
    return [];
  }

  const menuItemsMetadataList = flatMap(menuItems, (item, index) =>
    concat(
      {
        id: item.id,
        position: index,
        parentId,
        childrenCount: item.items.length,
        level,
        depth: getMenuItemDepth(item)
      },
      getMenuItemsPositionMetadataList(item.items, item.id, level + 1)
    )
  );

  return menuItemsMetadataList;
};

export const getMenuItemPositionMetadataById = (
  list: IMenuItemPositionMetadata[],
  id: string
) =>
  find(list, {
    id
  });

interface IGetPlacementPositionOptions {
  segment: PlacementModeSegment;
  targetItemId: string;
  placementModeMetadata: IMenuItemPlacementModeMetadata;
  menuItemsPositionMetadataList: IMenuItemPositionMetadata[];
}

const getShouldDenominateMovePosition = (
  segment: PlacementModeSegment,
  placementModeMetadata: IMenuItemPlacementModeMetadata,
  targetItemPositionMetadata: IMenuItemPositionMetadata,
  menuItemsPositionMetadataList: IMenuItemPositionMetadata[]
) => {
  const { purpose, pickedItem } = placementModeMetadata;

  if (purpose === "CREATE_MENU_ITEM" || pickedItem === undefined) {
    return false;
  }

  const sourceItemPositionMetadata = getMenuItemPositionMetadataById(
    menuItemsPositionMetadataList,
    pickedItem.id
  );

  if (sourceItemPositionMetadata === undefined) {
    return false;
  }

  const { parentId: sourceParentId, position: sourcePosition } =
    sourceItemPositionMetadata;

  if (segment === "CHILD") {
    /*  Because when moving a child as a child of the same parent again,
         the position is not increased, cause that item currently exists for the same parent
    */
    const isSourcePlacedAsLastChildOfItsParent =
      sourceParentId === targetItemPositionMetadata.id;

    return isSourcePlacedAsLastChildOfItsParent;
  }

  const doSourceAndTargetHaveSameParent =
    sourceParentId === targetItemPositionMetadata.parentId;

  const isSourceItemPositionedBeforeTarget =
    sourcePosition < targetItemPositionMetadata.position;

  /*  Because when moving a sibling that comes before the target item,
         the position is not increased, cause that item currently exists for the same parent
  */
  const isMoveSourceItemBeforeTargetItemAtTheSameLevel =
    doSourceAndTargetHaveSameParent && isSourceItemPositionedBeforeTarget;

  const shouldDenominatePosition =
    isMoveSourceItemBeforeTargetItemAtTheSameLevel;

  return shouldDenominatePosition;
};

export const getPlacementPositionBySegmentAndItemMetadata = (
  payload: IGetPlacementPositionOptions
) => {
  const {
    segment,
    targetItemId,
    menuItemsPositionMetadataList,
    placementModeMetadata
  } = payload;

  const targetItemPositionMetadata = getMenuItemPositionMetadataById(
    menuItemsPositionMetadataList,
    targetItemId
  );

  if (targetItemPositionMetadata === undefined) {
    throw new Error("Target item position is undefined");
  }

  const shouldDenominateMovePosition = getShouldDenominateMovePosition(
    segment,
    placementModeMetadata,
    targetItemPositionMetadata,
    menuItemsPositionMetadataList
  );

  const segmentPositionMap: Record<PlacementModeSegment, () => number> = {
    CHILD: () =>
      shouldDenominateMovePosition
        ? targetItemPositionMetadata.childrenCount - 1
        : targetItemPositionMetadata.childrenCount,
    SIBLING_BEFORE: () =>
      shouldDenominateMovePosition
        ? targetItemPositionMetadata.position - 1
        : targetItemPositionMetadata.position,
    SIBLING_AFTER: () =>
      shouldDenominateMovePosition
        ? targetItemPositionMetadata.position
        : targetItemPositionMetadata.position + 1
  };

  const position = segmentPositionMap[segment]();
  const parentId =
    segment === "CHILD"
      ? targetItemPositionMetadata.id
      : targetItemPositionMetadata.parentId;

  return {
    parentId,
    position
  };
};

export const getIsMenuItemSelectedForMoving = (
  placementModeMetadata: IMenuItemPlacementModeMetadata | undefined,
  itemId: string
) => {
  if (placementModeMetadata === undefined) {
    return false;
  }
  const { pickedItem, purpose } = placementModeMetadata;

  return purpose === "MOVE_MENU_ITEM" && pickedItem?.id === itemId;
};

export const getIsMenuItemMovingItemDescendant = (
  placementModeMetadata: IMenuItemPlacementModeMetadata | undefined,
  menuItemId: string
) => {
  if (placementModeMetadata === undefined) {
    return false;
  }

  const { purpose, pickedItem, pickedItemDescendants } = placementModeMetadata;

  if (purpose === "CREATE_MENU_ITEM" || pickedItem === undefined) {
    return false;
  }

  return includes(pickedItemDescendants, menuItemId);
};

export const getSnackbarPropsByPurpose = (
  purpose: PlacementModePurpose | undefined,
  loaders: MenuBuilderLoaders
) => {
  if (purpose === undefined) {
    return {};
  }

  const SnackbarPropsMap: Record<
    PlacementModePurpose,
    () => Record<string, any>
  > = {
    CREATE_MENU_ITEM: () => ({
      icon: loaders.isCreatingMenuItem ? "autorenew" : "playlist_add",
      iconProps: { spin: loaders.isCreatingMenuItem },
      header: translate("sitestructure.add_menu_item_snackbar.title"),
      message: translate("sitestructure.add_menu_item_snackbar.body")
    }),
    MOVE_MENU_ITEM: () => ({
      icon: loaders.isMovingMenuItem ? "autorenew" : "zoom_out_map",
      iconProps: { spin: loaders.isMovingMenuItem },
      header: translate("sitestructure.move_menu_item_snackbar.title"),
      message: translate("sitestructure.move_menu_item_snackbar.body")
    })
  };

  const props = invoke(SnackbarPropsMap, purpose);

  return props;
};

export const getIsForbiddenSegmentHovered = (
  forbiddenPlacementSegments: PlacementModeSegment[],
  hoveredSegment: PlacementModeSegment | undefined
) => includes(forbiddenPlacementSegments, hoveredSegment);

export const getHoveredSegmentFromHandle = (
  isChildPlacementHovered: boolean,
  isSiblingBeforePlacementHovered: boolean,
  isSiblingAfterPlacementHovered: boolean
): PlacementModeSegment | undefined => {
  if (isChildPlacementHovered) {
    return "CHILD";
  }

  if (isSiblingBeforePlacementHovered) {
    return "SIBLING_BEFORE";
  }

  if (isSiblingAfterPlacementHovered) {
    return "SIBLING_AFTER";
  }
};

export const getForbiddenPlacementSegments = (
  menuItemsPositionMetadataList: IMenuItemPositionMetadata[],
  placementModeMetadata: IMenuItemPlacementModeMetadata | undefined,
  menuItemId: string
): PlacementModeSegment[] => {
  if (placementModeMetadata === undefined) {
    return [];
  }

  const sourceItemPositionMetadata = find(menuItemsPositionMetadataList, {
    id: placementModeMetadata.pickedItem?.id
  });

  const targetItemPositionMetadata = find(menuItemsPositionMetadataList, {
    id: menuItemId
  });

  if (targetItemPositionMetadata === undefined) {
    return [];
  }

  const forbiddenPlacementSegments: PlacementModeSegment[] = [];

  /*
    When purpose is in CREATE MENU ITEM, we don't have
    a sourceItemPositionMetadata, but technically we are
    adding one item, thus the depth is always 1.
  */
  const { depth: sourceItemDepth } = sourceItemPositionMetadata || { depth: 1 };
  const { level: targetItemLevel } = targetItemPositionMetadata;

  const totalDepth = targetItemLevel + sourceItemDepth;
  const isSiblingPlacementForbidden = totalDepth > MAX_MENU_DEPTH;
  const isChildPlacementForbidden = totalDepth + 1 > MAX_MENU_DEPTH;

  if (isSiblingPlacementForbidden) {
    forbiddenPlacementSegments.push("SIBLING_BEFORE", "SIBLING_AFTER");
  }

  if (isChildPlacementForbidden) {
    forbiddenPlacementSegments.push("CHILD");
  }

  return forbiddenPlacementSegments;
};
