import { useCallback } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { makeStyles, IconButton, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  PageElementDefinition,
  SiteItemElement
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { SiteItemElementIcon } from "components/siteStructure/siteItemSidebar/siteItemElementIcon";
import {
  setSelectedSiteAssetType,
  unsetSelectedPageElement
} from "redux/actions/siteStructureActions";
import { getIsRichTextModuleDefinition } from "components/richText/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3, 2.5),
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.custom.ds.fiord.fiord100
  },
  element: {
    marginLeft: theme.spacing(1),
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "start",
    width: theme.spacing(41.25)
  },
  type: {
    color: theme.palette.custom.icons.noAction,
    textTransform: "uppercase",
    width: theme.spacing(38)
  },
  label: {
    width: "100%",
    fontSize: "0.95rem"
  },
  collapsed: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: theme.spacing(7),
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.custom.ds.fiord.fiord100
  },

  controlsContainer: {
    display: "flex",
    position: "relative",
    right: theme.spacing(4)
  },

  iconContainer: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0.5),

    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.custom.ds.grayscales.gr600,
    fontSize: theme.typography.pxToRem(18)
  },

  iconDelimiter: {
    width: "1px",
    height: theme.spacing(3),
    backgroundColor: "#D9D9D9",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: "auto"
  },
  elementIcon: {
    backgroundColor: theme.palette.custom.ds.viking.viking500
  },
  properties: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    padding: theme.spacing(1, 5),
    width: theme.spacing(45) // 330px
  },
  empty: {
    marginTop: theme.spacing(5)
  }
}));

interface IPageDetailSidebarHeaderProps extends IBaseProps {
  element: SiteItemElement;
  definition: PageElementDefinition;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const SiteItemSidebarHeader = (props: IPageDetailSidebarHeaderProps) => {
  const { className, element, definition, open, onOpen, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const rootClassName = classnames(classes.root, className);
  const type = translate(`sitestructure.${element.moduleType.toLowerCase()}`);

  const handleClose = useCallback(() => {
    dispatch(setSelectedSiteAssetType(undefined));
    dispatch(unsetSelectedPageElement());
    onClose();
  }, [onClose, dispatch]);

  const isRichTextModuleDefinition =
    definition &&
    getIsRichTextModuleDefinition({
      id: definition.id
    } as any);

  if (!open) {
    return <CollapsedPageSidebarHeader onOpen={onOpen} />;
  }

  return (
    <div className={rootClassName}>
      <SiteItemElementIcon
        size="small"
        elementType={element.moduleType}
        pageElementDefinition={definition}
        className={classes.elementIcon}
      />

      <div className={classes.element}>
        {!isRichTextModuleDefinition && (
          <Typography className={classes.type} variant="caption">
            {type}
          </Typography>
        )}
        <Typography className={classes.label} variant="h6">
          {element.label || definition.label}
        </Typography>
      </div>

      <div className={classes.controlsContainer}>
        {element.moduleType === "Template" && (
          <>
            <IconButton
              className={classes.iconContainer}
              onClick={() => {
                handleClose();
                dispatch(setSelectedSiteAssetType("templates"));
              }}
            >
              <UxdIcon name="edit" outline className={classes.icon} />
            </IconButton>
            <div className={classes.iconDelimiter} />
          </>
        )}

        <IconButton className={classes.iconContainer} onClick={handleClose}>
          <UxdIcon name="close" className={classes.icon} />
        </IconButton>
      </div>
    </div>
  );
};

const CollapsedPageSidebarHeader = (props: { onOpen: () => void }) => {
  const { onOpen } = props;

  const classes = useStyles();

  return (
    <div className={classes.collapsed}>
      <IconButton
        className={classes.iconContainer}
        onClick={onOpen}
        disableRipple={true}
      >
        <UxdIcon name="first_page" className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default SiteItemSidebarHeader;
