import { createAction } from "redux-actions";
import {
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX,
  FAILED_ACTION_SUFFIX
} from "redux/actions/constants";
import { IPage, IModule, ILayout } from "models/pages";
import {
  RemoveLayoutPayload,
  RemoveModulePayload,
  SetModulePropertyPayload,
  SetLayoutPropertyPayload,
  ViewPreviewPayload,
  CreatePagePayload,
  ChangePageTemplateActionPayload,
  FetchSiteItemsFromRootPayload,
  RenameSiteItemPayload,
  MoveModulePayload,
  SetLastUpdateInfoPayload,
  CopySiteItemPayload,
  MoveSiteItemPayload,
  AddModulePayload,
  PublishSiteItemsPayload,
  FetchSiteItemsPayload,
  FetchSiteItemsSuccededPayload,
  AddLayoutPayload,
  UnpublishSiteItemsPayload,
  ChangeItemStagePayload,
  RollbackPayload,
  SetModuleLabelPayload,
  SetModuleImportancePayload,
  CopySiteAssetPayload,
  ChangeTabPayload,
  CreateDirectoryPayload,
  SetMetadataSiteItemPayload,
  AddMetadataSiteItemPayload,
  RemoveMetadataSiteItemPayload,
  UpdateUriSegmentTranslation,
  CheckUrlTranslationPayload,
  AddSiteItemAuthGroupsPayload,
  RemoveSiteItemAuthGroupsPayload,
  CreateMenuPayload,
  SiteStructureOperationExecutedPayload
} from "redux/actions/typings/siteStructureActions";
import { SiteItems } from "models/siteItem";
import { IMenu, IMenuItem } from "models/menus";
import { SiteAssetType } from "models/siteAssets/siteAssetTypes";

// SITE STRUCTURE
export const fetchSiteItems = createAction(
  `FETCH_SITE_ITEMS${REQUEST_ACTION_SUFFIX}`,
  (payload: FetchSiteItemsPayload) => payload
);
export const fetchSiteItemsSucceded = createAction(
  `FETCH_SITE_ITEMS${SUCCESS_ACTION_SUFFIX}`,
  (payload: FetchSiteItemsSuccededPayload) => payload
);
export const fetchSiteItemsFailed = createAction(
  `FETCH_SITE_ITEMS${FAILED_ACTION_SUFFIX}`
);

export const copySiteItem = createAction(
  "COPY_SITE_ITEM",
  (payload: CopySiteItemPayload) => payload
);

export const moveSiteItem = createAction(
  "MOVE_SITE_ITEM",
  (payload: MoveSiteItemPayload) => payload
);

// SITE STRUCTURE NODE
export const fetchSiteItemsFromRoot = createAction(
  `FETCH_SITE_ITEMS_FROM_ROOT${REQUEST_ACTION_SUFFIX}`,
  (payload: FetchSiteItemsFromRootPayload) => payload
);
export const fetchSiteItemsFromRootSucceded = createAction(
  `FETCH_SITE_ITEMS_FROM_ROOT${SUCCESS_ACTION_SUFFIX}`,
  (payload: SiteItems) => payload
);
export const fetchSiteItemsFromRootFailed = createAction(
  `FETCH_SITE_ITEMS_FROM_ROOT${FAILED_ACTION_SUFFIX}`
);

// SITE NODES OPERATIONS
export const changeItemStage = createAction(
  "CHANGE_ITEM_STAGE",
  (payload: ChangeItemStagePayload) => payload
);

export const removeSiteItem = createAction(
  "REMOVE_SITE_ITEM",
  (itemId: string) => itemId
);

export const renameSiteItem = createAction(
  "RENAME_SITE_ITEM",
  (payload: RenameSiteItemPayload) => payload
);

export const setLastUpdateInfo = createAction(
  "SET_LAST_UPDATE_INFO",
  (payload: SetLastUpdateInfoPayload) => payload
);

// SIDEBAR
export const openSidebar = createAction("OPEN_SIDEBAR");
export const closeSidebar = createAction("CLOSE_SIDEBAR");

// HISTORY
export const openHistory = createAction("OPEN_HISTORY");
export const closeHistory = createAction("CLOSE_HISTORY");

// PAGES
export const fetchPage = createAction(
  `FETCH_PAGE${REQUEST_ACTION_SUFFIX}`,
  (pageId: string) => pageId
);

export const fetchPageSucceded = createAction(
  `FETCH_PAGE${SUCCESS_ACTION_SUFFIX}`,
  (page: IPage) => page
);
export const fetchPageFailed = createAction(
  `FETCH_PAGE${FAILED_ACTION_SUFFIX}`
);

export const removePage = createAction(
  "REMOVE_PAGE",
  (pageId: string) => pageId
);

export const removeDirectory = createAction(
  "REMOVE_DIRECTORY",
  (directoryId: string) => directoryId
);

export const removeMenu = createAction(
  "REMOVE_MENU",
  (menuId: string) => menuId
);

// MODULES
export const setSelectedPageElement = createAction(
  "SET_SELECTED_PAGE_ELEMENT",
  (pageElement: IModule | ILayout | string) => pageElement
);
export const unsetSelectedPageElement = createAction(
  "UNSET_SELECTED_PAGE_ELEMENT"
);

export const setSelectedCulture = createAction(
  "SET_SELECTED_CULTURE",
  (selectedCulture: string) => selectedCulture
);

// SELECTED NODE
// TO BE REMOVED when fetchDirectoryDetail will be implemented.
// Details in components/siteStructure/directoryView/directoryDetail
export const unsetSelectedNode = createAction("UNSET_SELECTED_NODE");

export const removeModule = createAction(
  "REMOVE_MODULE",
  (payload: RemoveModulePayload) => payload
);

export const setModuleProperty = createAction(
  "SET_MODULE_PROPERTY",
  (payload: SetModulePropertyPayload) => payload
);

export const setLayoutProperty = createAction(
  "SET_LAYOUT_PROPERTY",
  (payload: SetLayoutPropertyPayload) => payload
);

export const removeLayout = createAction(
  "REMOVE_LAYOUT",
  (payload: RemoveLayoutPayload) => payload
);

export const moveModule = createAction(
  "MOVE_MODULE",
  (payload: MoveModulePayload) => payload
);

export const moveLayout = createAction(
  "MOVE_LAYOUT",
  (payload: MoveModulePayload) => payload
);

export const addModule = createAction(
  "ADD_MODULE",
  (payload: AddModulePayload) => payload
);

export const addLayout = createAction(
  "ADD_LAYOUT",
  (payload: AddLayoutPayload) => payload
);

export const setModuleLabel = createAction(
  "SET_MODULE_LABEL",
  (payload: SetModuleLabelPayload) => payload
);

// DIRECTORIES
export const addDirectory = createAction(
  "ADD_DIRECTORY",
  (path: CreateDirectoryPayload) => path
);

export const publishDirectory = createAction(
  "PUBLISH_DIRECTORY",
  (directoryId: string) => directoryId
);

export const unpublishDirectory = createAction(
  "UNPUBLISH_DIRECTORY",
  (directoryId: string) => directoryId
);

// PAGES
export const addPage = createAction(
  "ADD_PAGE",
  (payload: CreatePagePayload) => payload
);

export const publishPage = createAction(
  "PUBLISH_PAGE",
  (pageId: string) => pageId
);

export const unpublishPage = createAction(
  "UNPUBLISH_PAGE",
  (pageId: string) => pageId
);

export const rollback = createAction(
  "ROLLBACK_PAGE",
  (payload: RollbackPayload) => payload
);

// MENUS
export const addMenu = createAction(
  "ADD_MENU",
  (payload: CreateMenuPayload) => payload
);

export const fetchMenu = createAction(
  `FETCH_MENU${REQUEST_ACTION_SUFFIX}`,
  (nodeId: string) => nodeId
);

export const fetchMenuSucceded = createAction(
  `FETCH_MENU${SUCCESS_ACTION_SUFFIX}`,
  (page: IMenu) => page
);

export const fetchMenuFailed = createAction(
  `FETCH_MENU${FAILED_ACTION_SUFFIX}`
);

export const setSelectedMenuItem = createAction(
  "SET_SELECTED_MENU_ITEM",
  (item: IMenuItem) => item
);

export const unsetSelectedMenuItem = createAction("UNSET_SELECTED_MENU_ITEM");

export const publishMenu = createAction(
  "PUBLISH_MENU",
  (menuId: string) => menuId
);

export const unpublishMenu = createAction(
  "UNPUBLISH_MENU",
  (menuId: string) => menuId
);

// BATCH
export const publishSiteItems = createAction(
  "PUBLISH_SITE_ITEMS",
  (payload: PublishSiteItemsPayload) => payload
);

export const unpublishSiteItems = createAction(
  "UNPUBLISH_SITE_ITEMS",
  (payload: UnpublishSiteItemsPayload) => payload
);

// PREVIEW
export const viewPreview = createAction(
  "VIEW_PREVIEW",
  (payload: ViewPreviewPayload) => payload
);

export const changePageTemplate = createAction(
  "CHANGE_PAGE_TEMPLATE",
  (payload: ChangePageTemplateActionPayload) => payload
);

export const changePageTemplateSucceeded = createAction(
  `CHANGE_PAGE_TEMPLATE${SUCCESS_ACTION_SUFFIX}`
);

export const setModuleImportance = createAction(
  "SET_MODULE_IMPORTANCE",
  (payload: SetModuleImportancePayload) => payload
);

export const copyModule = createAction(
  "COPY_MODULE",
  (payload: CopySiteAssetPayload) => payload
);

export const copyLayout = createAction(
  "COPY_LAYOUT",
  (payload: CopySiteAssetPayload) => payload
);

export const setRenameSiteItem = createAction(
  "SET_RENAME_SITE_ITEM",
  (payload: RenameSiteItemPayload) => payload
);

export const reloadPage = createAction("RELEAD_PAGE");

export const changeTabView = createAction(
  "CHANGE_VIEW",
  (payload: ChangeTabPayload) => payload
);

export const setMetadataSiteItem = createAction(
  "SET_METADATA_SITE_ITEM",
  (payload: SetMetadataSiteItemPayload) => payload
);

export const addMetadataToSiteItem = createAction(
  "ADD_METADATA_SITE_ITEM",
  (payload: AddMetadataSiteItemPayload) => payload
);

export const removeMetadataFromSiteItem = createAction(
  "REMOVE_METADATA_SITE_ITEM",
  (payload: RemoveMetadataSiteItemPayload) => payload
);

export const updateUriSegmentTranslation = createAction(
  "UPDATE_URI_SEGMENT_TRANSLATION",
  (payload: UpdateUriSegmentTranslation) => payload
);

export const checkUrlTranslationDuplicates = createAction(
  "CHECK_SITE_ITEM_URL_TRANSLATIONS",
  (payload: CheckUrlTranslationPayload) => payload
);

export const setPageRequiredModuleWarning = createAction(
  "SET_PAGE_REQUIRED_MODULE_WARNING",
  (payload: Record<string, boolean>) => payload
);

export const resetPageRequiredModuleWarning = createAction(
  "RESET_PAGE_REQUIRED_MODULE_WARNING"
);

export const addSiteItemAuthGroups = createAction(
  "ADD_SITE_ITEM_AUTHORIZATION_GROUPS",
  (payload: AddSiteItemAuthGroupsPayload) => payload
);

export const removeSiteItemAuthGroups = createAction(
  "REMOVE_SITE_ITEM_AUTHORIZATION_GROUPS",
  (payload: RemoveSiteItemAuthGroupsPayload) => payload
);

export const setLastTriggeredCommandId = createAction(
  "SET_LAST_TRIGGERED_COMMAND_ID",
  (payload: string) => payload
);

export const unsetLastTriggeredCommandId = createAction(
  "UNSET_LAST_TRIGGERED_COMMAND_ID"
);

export const setSelectedSiteAssetType = createAction(
  "SET_SELECTED_SITE_ASSET_TYPE",
  (payload: SiteAssetType | undefined) => payload
);

export const setHighlightedSiteAssetId = createAction(
  "SET_HIGHLIGHTED_SITE_ASSET",
  (payload: string) => payload
);

export const unsetHighlightedSiteAssetId = createAction(
  "UNSET_HIGHLIGHTED_SITE_ASSET",
  (payload: string) => payload
);

export const setIsSiteStructureSearchViewOpen = createAction(
  "SET_IS_SITE_STRUCTURE_SEARCH_VIEW_OPEN",
  (payload: boolean) => payload
);

export const setSiteStructureOperationExecuted = createAction(
  "SET_SITE_STRUCTURE_OPERATION_EXECUTED",
  (payload: SiteStructureOperationExecutedPayload) => payload
);
