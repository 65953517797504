import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import classNames from "classnames";
import WordCount from "components/richText/components/wordCount";

export interface ITranslationInfoProps extends IBaseProps {
  hasTranslation: boolean;
  wordCount?: number;
}

export const TranslationInfo = (props: Readonly<ITranslationInfoProps>) => {
  const { hasTranslation, wordCount, className } = props;
  const classes = useStyles();

  const showCount = hasTranslation && wordCount !== undefined;

  if (showCount) {
    return <WordCount wordCount={wordCount} className={className} />;
  }

  return (
    <Typography
      component="label"
      className={classNames(className, classes.missing)}
    >
      {translate("sitestructure.richtext_sidebar.missing_translation")}
    </Typography>
  );
};

export default TranslationInfo;
